import React, { FC, memo } from 'react';

import s from './TableResetFilters.module.scss';

interface TableResetFiltersProps {
  resetFilters: () => void;
  noActiveFilters: boolean;
}

const TableResetFilters: FC<TableResetFiltersProps> = (props) => {
  const { resetFilters, noActiveFilters } = props;

  return (
    <button onClick={resetFilters} className={s.wrapper} disabled={noActiveFilters}>
      Reset filters
    </button>
  );
};

export default memo(TableResetFilters);
