import React, { FC, ReactElement, memo } from 'react';

import { Tabs as AntdTabs } from 'antd';
import cx from 'classnames';

import { SizeType } from 'antd/lib/config-provider/SizeContext';
import 'antd/lib/tabs/style/css';

import s from './Tabs.module.scss';

export type TabItemType = {
  label: string;
  key: string;
  children: ReactElement;
  disabled?: boolean;
};

interface TabsProps {
  activeKey: string;
  defaultActiveKey?: string;
  animated: boolean | { inkBar: boolean; tabPane: boolean };
  size?: SizeType;
  onTabClick?: (key: string, event: unknown) => void;
  items: TabItemType[];
  className?: string;
}

const Tabs: FC<TabsProps> = (props): ReactElement => {
  const { activeKey, animated, defaultActiveKey, size, onTabClick, items, className = '' } = props;

  return (
    <AntdTabs
      destroyInactiveTabPane
      defaultActiveKey={defaultActiveKey}
      className={cx({ [s.wrapper]: true, [className]: className })}
      activeKey={activeKey}
      animated={animated}
      size={size}
      onTabClick={onTabClick}
      items={items}
    />
  );
};

export default memo(Tabs);
