import React, { FC, useCallback, useState } from 'react';

import cx from 'classnames';

import RangePicker from 'components/date-picker';
import Dropdown from 'components/dropdown';

import { ComponentProps } from '../filterComponentConstructor';
import s from './FilterDatepicker.module.scss';

const FilterDatepicker: FC<ComponentProps> = (props) => {
  const { handleChange, value, isSelected } = props;

  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);

  const handleOverlayOpening = useCallback(() => {
    setIsOverlayOpened((prevState) => !prevState);
  }, []);

  const handleRangePickerChange = (lower_border: string, upper_border: string) => {
    handleChange({ lower_border, upper_border });
  };

  const items = [
    {
      label: (
        <div className={s.inputsWrapper}>
          <RangePicker
            onChange={handleRangePickerChange}
            value={{ from: value?.lower_border || null, to: value?.upper_border || null }}
          />
        </div>
      ),
      key: '',
    },
  ];

  return (
    <Dropdown
      open={isOverlayOpened}
      items={items}
      onOpenChange={handleOverlayOpening}
      placement="bottomLeft">
      <button className={cx({ [s.wrapper]: true, [s.isSelected]: isSelected })}>
        Custom period
      </button>
    </Dropdown>
  );
};

export default FilterDatepicker;
