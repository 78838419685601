import React, { ReactElement } from 'react';

import { useLazyV2GetCompaniesQuery } from 'services/v2-overview-service/overviewService';

import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';

import s from './CompaniesTableView.module.scss';
import useTableColumns from './useTableColumns.hook';

const CompaniesTableView = (): ReactElement => {
  const [
    getCompanies,
    { isLoading: companiesIsLoading, isError: companiesIsError, data: companiesData },
  ] = useLazyV2GetCompaniesQuery();

  const columns = useTableColumns();

  return (
    <TableWithHandlers
      tab={TabTypesEnum.COMPANIES}
      className={s.tableWrapper}
      columns={columns}
      items={companiesData?.companies}
      totalCount={companiesData?.pagination.count}
      getData={getCompanies}
      isLoading={companiesIsLoading}
      isError={companiesIsError}
    />
  );
};

export default CompaniesTableView;
