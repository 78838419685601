import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper/copyWrapper';
import CryptoLogos from 'containers/crypto-logos';

import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';
import { TransactionType } from 'types/transactionTypes';

import s from './Currency.module.scss';

const Currency = <R,>(
  currency: ValueRowType,
  otherProps: RendererProps<GetTableDataArgsType, R, PossibleTableItemsType>,
): ReactElement => {
  if (!currency) {
    return <></>;
  }

  const blockchain = (otherProps.elementData as TransactionType).blockchain;
  const preparedCurrency = currency?.toString();

  const currencyIsDifferentFromBlockchain =
    preparedCurrency.toLowerCase() !== blockchain.toLowerCase();

  return (
    <CopyWrapper valueToCopy={currency as string}>
      <div className={s.wrapper}>
        <CryptoLogos type={preparedCurrency} className={s.currency} />
        {currencyIsDifferentFromBlockchain && (
          <CryptoLogos
            type={(otherProps.elementData as TransactionType).blockchain}
            className={s.blockchain}
          />
        )}
        <span>{preparedCurrency.toUpperCase()}</span>
      </div>
    </CopyWrapper>
  );
};

export default Currency;
