import React from 'react';

import { userTimezoneOffsetSelector } from 'selectors/userSlice.selectors';
import { useAppSelector } from 'store';

import CurrentTime from 'containers/current-time';

import { formatTimezone } from 'utils/timezone';

import s from './Clock.module.scss';

const Clock = () => {
  const currentUserTimezone = useAppSelector(userTimezoneOffsetSelector);
  const formattedCurrentTimezone = currentUserTimezone && formatTimezone(currentUserTimezone);

  return (
    <div className={s.clock}>
      <CurrentTime />
      {`, UTC${formattedCurrentTimezone}`}
    </div>
  );
};

export default Clock;
