import React, { FC, MouseEventHandler, memo } from 'react';

import cx from 'classnames';

import { ComponentProps } from '../filterComponentConstructor';
import s from './FilterOption.module.scss';

const FilterOption: FC<ComponentProps> = (props) => {
  const { label, handleChange, isSelected } = props;

  return (
    <button
      className={cx({ [s.wrapper]: true, [s.isSelected]: isSelected })}
      onClick={handleChange as MouseEventHandler<HTMLButtonElement>}>
      {label}
    </button>
  );
};

export default memo(FilterOption);
