import React, { ReactElement, useState } from 'react';

import Sidebar from 'pages/v1/sidebar';

import Layout from 'components/layout';
import Typography from 'components/typography';

import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left-icon.svg';
import { ReactComponent as List } from 'assets/icons/list-icon.svg';

import s from './AppSidebar.module.scss';

const SIDEBAR_OPENED_WIDTH = 244;

const AppSidebar = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const openCollapseSidebar = () => setCollapsed((prevState) => !prevState);

  const triggerElementContent: ReactElement = collapsed ? (
    <List className={s.listIcon} />
  ) : (
    <>
      <div>
        <List />
        <Typography weight="600" size="15">
          Filter
        </Typography>
      </div>
      <ArrowLeft />
    </>
  );

  const triggerElement: ReactElement = (
    <button className={s.sidebarTriggerWrapper} onClick={openCollapseSidebar}>
      {triggerElementContent}
    </button>
  );

  return (
    <Layout.Sider
      width={SIDEBAR_OPENED_WIDTH}
      collapsedWidth="4rem"
      collapsible
      collapsed={collapsed}
      trigger={triggerElement}
      className={s.wrapper}>
      <Sidebar collapsed={collapsed} />
    </Layout.Sider>
  );
};

export default AppSidebar;
