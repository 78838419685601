import React from 'react';

import Layout from 'components/layout';

import s from './AppHeader.module.scss';
import Clock from './clock';
import CurrencyDropdown from './currency-dropdown';
import Email from './email';
import LinksGroup from './links-group';

const AppHeader = () => {
  return (
    <Layout.Header className={s.wrapper}>
      <LinksGroup />
      <div className={s.rightGroup}>
        <CurrencyDropdown />
        <Clock />
        <Email />
      </div>
    </Layout.Header>
  );
};

export default AppHeader;
