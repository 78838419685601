import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper/copyWrapper';

import { ValueRowType } from 'types/tableTypes';

import { ReactComponent as TransactionIncome } from 'assets/icons/transaction-income-icon.svg';
import { ReactComponent as TransactionInside } from 'assets/icons/transaction-inside-icon.svg';
import { ReactComponent as TransactionOutcome } from 'assets/icons/transaction-outcome-icon.svg';

import s from './Direction.module.scss';

const getIcon = (directionType?: string) => {
  switch (directionType) {
    case 'outcome': {
      return <TransactionOutcome className={s.iconOutcome} />;
    }
    case 'income': {
      return <TransactionIncome className={s.iconIncome} />;
    }
    case 'inside': {
      return <TransactionInside className={s.iconInside} />;
    }
    default:
      return <></>;
  }
};

const Direction = (directionType: ValueRowType): ReactElement => {
  if (!directionType) {
    return <></>;
  }

  const icon = getIcon(directionType.toString());

  const preparedDirectionType = directionType?.toString() || '';
  const capitalizedValue = `${preparedDirectionType
    .charAt(0)
    .toUpperCase()}${preparedDirectionType.slice(1)}`;
  return (
    <CopyWrapper valueToCopy={capitalizedValue}>
      <div className={s.wrapper}>
        {icon} {capitalizedValue}
      </div>
    </CopyWrapper>
  );
};
export default Direction;
