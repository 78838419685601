import React, { FC, ReactElement, memo } from 'react';

import { Button as ButtonAntd, ButtonProps as ButtonAntdProps } from 'antd';
import cx from 'classnames';

import { Children } from 'types/childrenTypes';

import 'antd/lib/button/style/css';

import s from './Button.module.scss';

export type ButtonStyleType = 'filled' | 'outlined' | 'transparent';
export type ButtonSizeType = 'small' | 'medium' | 'large';

interface ButtonProps {
  children: Children;
  onClick?: () => void;
  className?: string;
  fullwidth?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  htmlType?: ButtonAntdProps['htmlType'];
  styleType?: ButtonStyleType;
  block?: boolean;
  size?: ButtonSizeType;
}

const Button: FC<ButtonProps> = (props): ReactElement => {
  const {
    children,
    onClick,
    className = '',
    fullwidth = '',
    htmlType = 'button',
    styleType = 'filled',
    isLoading = false,
    isDisabled = false,
    block = false,
    size = 'medium',
  } = props;

  return (
    <ButtonAntd
      onClick={onClick}
      className={cx(s[size], {
        [s.wrapper]: true,
        [s[styleType]]: true,
        [s.fullwidth]: fullwidth,
        [className]: className,
      })}
      loading={isLoading}
      disabled={isDisabled}
      block={block}
      htmlType={htmlType}>
      {children}
    </ButtonAntd>
  );
};

export default memo(Button);
