import { FC, PropsWithChildren } from 'react';

import { Form as FormAntd, FormProps as FormPropsAntd } from 'antd';

export type FormProps = PropsWithChildren<FormPropsAntd>;

const Form: FC<FormProps> = (props) => {
  return <FormAntd {...props} />;
};

export default Form;
