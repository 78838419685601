import React, { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { useSignOutMutation } from 'services/user-service/userService';
import { clearUser, setUserIsLoading } from 'slices/userSlice';
import { useAppDispatch } from 'store';

import Typography from 'components/typography';

import { REAL_TIME_OVERVIEW_ROUTE } from 'constants/routes';
import { WATCHBLOCK_REFRESH_TOKEN, WATCHBLOCK_TOKEN } from 'constants/token';
import {
  WATCH_BLOCK_VERSION_1,
  WATCH_BLOCK_VERSION_2,
  WATCH_BLOCK_VERSION_LOCAL_KEY,
} from 'constants/version';
import { SetStateTypes } from 'types/setStateTypes';

import { ReactComponent as Colored1 } from 'assets/icons/colored-1-icon.svg';
import { ReactComponent as Colored2 } from 'assets/icons/colored-2-icon.svg';
import { ReactComponent as Logout } from 'assets/icons/logout-icon.svg';
import { ReactComponent as Settings } from 'assets/icons/settings-icon.svg';

import useLocalStorage from 'utils/useLocalStorage.hook';

import s from './Email.module.scss';

const SETTINGS_KEY = 'SETTINGS_KEY';
const VERSION_KEY = 'VERSION_KEY';
const SIGN_OUT_KEY = 'SIGN_OUT_KEY';

interface useEmailDropdownItemsProps {
  setIsOverlayOpened: SetStateTypes<boolean>;
  setIsModalOpened: SetStateTypes<boolean>;
}

const useEmailDropdownItems = (props: useEmailDropdownItemsProps) => {
  const { setIsOverlayOpened, setIsModalOpened } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [currentVersion, setCurrentVersion] = useLocalStorage(
    WATCH_BLOCK_VERSION_LOCAL_KEY,
    WATCH_BLOCK_VERSION_1,
  );
  const isV1 = currentVersion === WATCH_BLOCK_VERSION_1;

  const [signOut] = useSignOutMutation();

  const handleVersionChange = useCallback(() => {
    setIsOverlayOpened(false);
    setCurrentVersion(isV1 ? WATCH_BLOCK_VERSION_2 : WATCH_BLOCK_VERSION_1);
    navigate(REAL_TIME_OVERVIEW_ROUTE);
  }, [isV1, navigate, setCurrentVersion, setIsOverlayOpened]);

  const signOutHandler = useCallback(() => {
    dispatch(setUserIsLoading(true));
    signOut()
      .then(() => {
        localStorage.removeItem(WATCHBLOCK_REFRESH_TOKEN);
        localStorage.removeItem(WATCHBLOCK_TOKEN);
        dispatch(clearUser());
      })
      .finally(() => {
        dispatch(setUserIsLoading(false));
      });
  }, [dispatch, signOut]);

  const openModalHandler = useCallback(() => {
    setIsOverlayOpened(false);
    setIsModalOpened(true);
  }, [setIsModalOpened, setIsOverlayOpened]);

  const items = useMemo(() => {
    return [
      {
        label: (
          <div className={s.option}>
            {isV1 ? <Colored2 /> : <Colored1 />}
            <Typography>{`Switch to ver.${isV1 ? 2 : 1}`}</Typography>
          </div>
        ),
        key: VERSION_KEY,
      },
      {
        label: (
          <div className={s.option}>
            <Settings />
            <Typography>Settings</Typography>
          </div>
        ),
        key: SETTINGS_KEY,
      },
      {
        label: (
          <div className={s.option}>
            <Logout />
            <Typography>Log out</Typography>
          </div>
        ),
        key: SIGN_OUT_KEY,
      },
    ];
  }, [isV1]);

  const handleItemClick = useCallback(
    ({ key }: { key: string }) => {
      switch (key) {
        case SETTINGS_KEY:
          return openModalHandler();
        case VERSION_KEY:
          return handleVersionChange();
        case SIGN_OUT_KEY:
          return signOutHandler();
        default:
          return;
      }
    },
    [handleVersionChange, openModalHandler, signOutHandler],
  );

  return {
    items,
    handleItemClick,
  };
};

export default useEmailDropdownItems;
