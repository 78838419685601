import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  useExportToCsvMutation,
  useGetCompanyInfoQuery,
  useSetStatusMutation,
} from 'services/overview-service/overviewService';

import Breadcrumb from 'components/breadcrumb';
import LayoutHeader from 'components/layout-header';
import Tabs from 'components/tabs';
import ContentDefaultWrapper from 'containers/content-default-wrapper';
import SelectedTransactionsActionGroup from 'containers/selected-transactions-action-group';

import { DEFAULT_PAGINATION } from 'constants/pagination';
import { REAL_TIME_OVERVIEW_ROUTE } from 'constants/routes';

import { getFiltersFromParamsObject } from 'utils/filter';
import goBackFunction from 'utils/goBackFunction';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import s from './Company.module.scss';
import CompanyTransactionsTableView from './company-transactions-table-view';
import CompanyWalletsTableView from './company-wallets-table-view';

const WALLETS_TAB = 'Wallets';
const TRANSACTIONS_TAB = 'Transactions';

const TABS = [
  { label: WALLETS_TAB, key: WALLETS_TAB, children: <CompanyWalletsTableView /> },
  { label: TRANSACTIONS_TAB, key: TRANSACTIONS_TAB, children: <CompanyTransactionsTableView /> },
];

const Company = () => {
  const navigate = useNavigate();
  const params = useParams<{ companyId: string }>();

  const [activeTab, setActiveTab] = useState<string>('');
  const { paramsObject, setQueryParams } = useQueryParamsHook();

  const {
    data: companyInfo,
    isFetching: companyInfoIsFetching,
    refetch: companyInfoRefetch,
  } = useGetCompanyInfoQuery(params.companyId!);

  useEffect(() => {
    window.addEventListener('popstate', () => goBackFunction(navigate, REAL_TIME_OVERVIEW_ROUTE));

    return () => {
      window.removeEventListener('popstate', () =>
        goBackFunction(navigate, REAL_TIME_OVERVIEW_ROUTE),
      );
    };
  }, [navigate]);

  useEffect(() => {
    companyInfoRefetch();
  }, [companyInfoRefetch, params.companyId]);

  const BREADCRUMB_ROUTES = [
    { name: 'Overview', path: REAL_TIME_OVERVIEW_ROUTE },
    { name: companyInfo?.name || '' },
  ];

  useEffect(() => {
    const tab = paramsObject?.tab || WALLETS_TAB;

    if (paramsObject && !activeTab) {
      setQueryParams({ tab });
      setActiveTab(tab);
    }
  }, [activeTab, paramsObject, setQueryParams]);

  const onTabClick = useCallback(
    (activeKey: string) => {
      setActiveTab(activeKey);
      setQueryParams(
        { tab: activeKey, ...DEFAULT_PAGINATION, ...getFiltersFromParamsObject(paramsObject) },
        true,
      );
    },
    [paramsObject, setQueryParams],
  );

  const showActions = activeTab === TRANSACTIONS_TAB;

  return (
    <ContentDefaultWrapper>
      <ContentDefaultWrapper.Header>
        <div className={s.breadcrumbsWrapper}>
          <Breadcrumb routes={BREADCRUMB_ROUTES} isLoading={companyInfoIsFetching} />
        </div>
        <LayoutHeader
          className={s.header}
          goBackLink={REAL_TIME_OVERVIEW_ROUTE}
          isLoading={companyInfoIsFetching}>
          {companyInfo?.name || ''}
        </LayoutHeader>
        {showActions && (
          <ContentDefaultWrapper.Actions className={s.actions}>
            <SelectedTransactionsActionGroup
              setStatusMutation={useSetStatusMutation}
              exportToCsvMutation={useExportToCsvMutation}
            />
          </ContentDefaultWrapper.Actions>
        )}
      </ContentDefaultWrapper.Header>
      <ContentDefaultWrapper.Body>
        <Tabs activeKey={activeTab} animated={true} onTabClick={onTabClick} items={TABS} />
      </ContentDefaultWrapper.Body>
    </ContentDefaultWrapper>
  );
};

export default Company;
