import React, { FC, memo } from 'react';

import PageSize from 'components/page-size';
import Pagination from 'components/pagination';

import { DEFAULT_PAGE_SIZE } from 'constants/pagination';

import { getCurrentPage } from 'utils/pagination';

import s from '../Table.module.scss';

interface TablePaginationProps {
  showPagination?: boolean;
  totalCount?: number | string;
  offset?: number | string;
  limit?: number | string;
  handlePaginationChange?: (page: number) => void;
  handlePageSizeChange?: (page: number) => void;
}

const TablePagination: FC<TablePaginationProps> = (props) => {
  const {
    showPagination,
    totalCount,
    offset,
    limit,
    handlePaginationChange,
    handlePageSizeChange,
  } = props;

  if (!showPagination || typeof totalCount !== 'number') {
    return <></>;
  }

  return (
    <div className={s.paginationWrapper}>
      <Pagination
        current={getCurrentPage(offset, limit)}
        total={Number(totalCount)}
        onChange={handlePaginationChange}
        pageSize={Number(limit)}
      />
      <PageSize
        label="View"
        current={Number(limit) ?? DEFAULT_PAGE_SIZE}
        onChange={handlePageSizeChange}
      />
    </div>
  );
};

export default memo(TablePagination);
