import React, { ReactElement, useMemo } from 'react';

import { userCurrencySelector } from 'selectors/userSlice.selectors';
import { useLazyGetCompaniesQuery } from 'services/overview-service/overviewService';
import { useAppSelector } from 'store';

import { Company, ConvertedFrom, Date, IntegerNumber, RowNumber } from 'containers/row-renderers';
import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';
import { CompanyColumnType } from 'types/tableTypes';

import s from './CompaniesTableView.module.scss';

const CompaniesTableView = (): ReactElement => {
  const userCurrency = useAppSelector(userCurrencySelector);

  const [
    getCompanies,
    { isLoading: companiesIsLoading, isError: companiesIsError, data: companiesData },
  ] = useLazyGetCompaniesQuery();

  const COLUMNS: CompanyColumnType[] = useMemo(
    () => [
      { title: ' ', key: 'number', renderer: RowNumber },
      {
        title: 'Company',
        key: 'name',
        renderer: Company,
        sortable: true,
      },
      {
        title: 'Wallets',
        key: 'wallets_count',
        renderer: IntegerNumber,
        sortable: true,
      },
      {
        title: 'Last activity',
        key: 'last_transaction',
        renderer: Date,
        sortable: true,
      },
      {
        title: 'Income Value',
        key: 'income',
        renderer: ConvertedFrom,
        userCurrency,
        sortable: true,
      },
      {
        title: 'Outcome Value',
        key: 'outcome',
        renderer: ConvertedFrom,
        userCurrency,
        sortable: true,
      },
    ],
    [userCurrency],
  );

  return (
    <TableWithHandlers
      tab={TabTypesEnum.COMPANIES}
      className={s.tableWrapper}
      columns={COLUMNS}
      items={companiesData?.companies}
      totalCount={companiesData?.pagination.count}
      getData={getCompanies}
      isLoading={companiesIsLoading}
      isError={companiesIsError}
    />
  );
};

export default CompaniesTableView;
