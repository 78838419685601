import React, { useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { userCurrencySelector } from 'selectors/userSlice.selectors';
import { useLazyGetWalletsQuery } from 'services/overview-service/overviewService';
import { GetTransactionsPropsType } from 'services/overview-service/overviewService.types';
import { useAppSelector } from 'store';

import {
  Blockchain,
  ConvertedFrom,
  Date,
  RowNumber,
  WalletAddress,
} from 'containers/row-renderers';
import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';
import { WalletsColumnType } from 'types/tableTypes';

import s from './CompanyWalletsTableView.module.scss';

const CompanyWalletsTableView = () => {
  const userCurrency = useAppSelector(userCurrencySelector);
  const params = useParams<{ companyId: string }>();

  const [getWallets, { isLoading: walletsIsLoading, isError: walletsIsError, data: walletsData }] =
    useLazyGetWalletsQuery();

  const getData = useCallback(
    (args: Omit<GetTransactionsPropsType, 'companyId'>) => {
      const updatedProps = { ...args, companyId: params.companyId } as GetTransactionsPropsType;

      return getWallets(updatedProps);
    },
    [getWallets, params.companyId],
  );

  const COLUMNS: WalletsColumnType[] = useMemo(
    () => [
      { title: ' ', key: 'number', renderer: RowNumber },
      {
        title: 'Address',
        key: 'address',
        renderer: WalletAddress,
        sortable: true,
        companyId: params.companyId,
      },
      {
        title: 'Address Balance',
        key: 'balance',
        renderer: ConvertedFrom,
        sortable: true,
        userCurrency,
      },
      {
        title: 'Last Transaction',
        key: 'last_transaction',
        renderer: Date,
        sortable: true,
      },
      { title: 'Blockchain', key: 'blockchain', renderer: Blockchain, sortable: true },
      {
        title: `Income Value`,
        key: 'income',
        renderer: ConvertedFrom,
        sortable: true,
        userCurrency,
      },
      {
        title: `Outcome Value`,
        key: 'outcome',
        renderer: ConvertedFrom,
        sortable: true,
        userCurrency,
      },
    ],
    [params.companyId, userCurrency],
  );

  return (
    <TableWithHandlers
      tab={TabTypesEnum.WALLETS}
      className={s.tableWrapper}
      columns={COLUMNS}
      items={walletsData?.wallets}
      totalCount={walletsData?.pagination.count}
      getData={getData}
      isLoading={walletsIsLoading}
      isError={walletsIsError}
    />
  );
};

export default CompanyWalletsTableView;
