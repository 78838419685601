import React, { FC, ReactElement, memo } from 'react';

import { Modal as ModalAntd, ModalProps as ModalAntdProps } from 'antd';
import cn from 'classnames';

import { Children } from 'types/childrenTypes';

import 'antd/lib/modal/style/index.css';

import s from './Modal.module.scss';

type getContainerFunc = () => HTMLElement;

interface ModalProps extends ModalAntdProps {
  width?: number;
  children: Children;
  open: boolean;
  closable?: boolean;
  handleClose: () => void;
  getContainer?: string | HTMLElement | getContainerFunc | false;
}

const ROOT = document.getElementById('root') as HTMLElement;

const Modal: FC<ModalProps> = (props): ReactElement => {
  const {
    width = 360,
    children,
    open,
    handleClose,
    closable = false,
    className,
    ...otherProps
  } = props;

  if (!open) {
    return <></>;
  }

  return (
    <ModalAntd
      {...otherProps}
      style={{ borderRadius: 6 }}
      className={cn(s.modal, className)}
      width={width}
      open={open}
      closable={closable}
      onCancel={handleClose}
      getContainer={ROOT}
      maskClosable
      centered>
      {children}
    </ModalAntd>
  );
};

export default memo(Modal);
