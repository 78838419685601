import React, { FC, Fragment, ReactElement, memo, useCallback, useMemo, useRef } from 'react';

import { filtersIsLoadingSelector, filtersSelector } from 'selectors/filterSlice.selectors';
import { useAppSelector } from 'store';

import Tag from 'components/tag';

import { FILTER_COMPLEX_VALUE_SEPARATOR, FILTER_VALUE_START_SEPARATOR } from 'constants/query';
import { FilterComponent, FilterComponentTypes } from 'types/filtersTypes';

import { getFilterKey, getFilterType } from 'utils/filter';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import s from './TableActions.module.scss';
import TableFilterTags from './table-filter-tags';
import TableResetFilters from './table-reset-filters';
import TableViewAllFilterTags from './table-view-all-filter-tags';
import {
  FiltersVisibilityType,
  SPACINGS_IN_PX,
  getComplexLabel,
  getFiltersFromQuery,
} from './tableActions.utils';

interface TableActionsProps {
  resetFilters: () => void;
}
let totalWidth = 0;

const TableActions: FC<TableActionsProps> = (props) => {
  const { resetFilters } = props;

  const tableTagsWrapperRef = useRef<HTMLDivElement>(null);

  const { paramsObject, setQueryParams } = useQueryParamsHook();

  const filters = useAppSelector(filtersSelector);
  const filtersIsLoading = useAppSelector(filtersIsLoadingSelector);

  const stringifiedParamsObject = JSON.stringify(paramsObject) || '{}';

  const noActiveFilters = !stringifiedParamsObject.includes(FILTER_VALUE_START_SEPARATOR);

  const handleTagClose = useCallback(
    (e: unknown) => {
      if (paramsObject && typeof e === 'string') {
        const filterKey = e.split(FILTER_VALUE_START_SEPARATOR).shift() || '';
        const currentFilterComponent = filters?.[filterKey];
        const defaultFilter = (currentFilterComponent?.components || []).find(
          (component) => component.default_selected,
        );
        const copiedParams = { ...paramsObject };

        if (defaultFilter) {
          paramsObject[`${currentFilterComponent?.key}[${defaultFilter.key}]`] = '';
        }
        delete copiedParams[e];
        setQueryParams(copiedParams, true);
      }
    },
    [filters, paramsObject, setQueryParams],
  );

  const filterModifier = useCallback(
    (
      prevValue: FiltersVisibilityType,
      [key, val]: [string, string],
      index: number,
    ): FiltersVisibilityType => {
      if (index === 0) {
        totalWidth = 0;
      }
      if (!tableTagsWrapperRef.current?.clientWidth) {
        return prevValue;
      }

      const filterKey = getFilterKey(key);
      const filterType = getFilterType(key);
      const emptyComponent = <Fragment key={`filterRenderer__${index}`} />;

      const currentFilterComponent = filters?.[filterType];
      const findFilterComponent = (component: { key: string }) => {
        return component.key === filterKey;
      };

      const filterComponent: FilterComponent | undefined =
        currentFilterComponent && 'components' in currentFilterComponent
          ? currentFilterComponent?.components?.find(findFilterComponent)
          : undefined;

      const filterComponentLabel =
        filterComponent?.type === FilterComponentTypes.COMPLEX
          ? getComplexLabel(val.split(FILTER_COMPLEX_VALUE_SEPARATOR))
          : filterComponent?.label;

      const showTag = filters && filterComponentLabel;

      const content = {
        label: showTag ? (
          <Tag
            closable={!filterComponent?.default_selected}
            onClose={handleTagClose}
            value={key}
            className={s.token}>
            {filterComponentLabel || ''}
          </Tag>
        ) : (
          emptyComponent
        ),
        key: `tag__${val}${key}${index}`,
      };
      totalWidth = totalWidth + (filterComponentLabel?.length ?? 0) * 8 + SPACINGS_IN_PX;

      if (prevValue.unVisible.length) {
        prevValue.unVisible.push(content);
      } else if (totalWidth <= tableTagsWrapperRef.current?.clientWidth) {
        prevValue.visible.push(content);
      } else {
        prevValue.unVisible.push(content);
      }

      return prevValue;
    },
    [filters, handleTagClose],
  );

  const tokenRenderer = (e: { key: string; label: ReactElement }) => (
    <Fragment key={e.key}>{e.label}</Fragment>
  );

  const filterTokens: FiltersVisibilityType = useMemo(() => {
    return Object.entries(paramsObject || {})
      .filter(getFiltersFromQuery)
      .reduce(filterModifier, { visible: [], unVisible: [] } as FiltersVisibilityType);
  }, [filterModifier, paramsObject]);

  return (
    <div className={s.wrapper}>
      <TableFilterTags isLoading={filtersIsLoading} ref={tableTagsWrapperRef}>
        {filterTokens.visible?.map(tokenRenderer)}
      </TableFilterTags>
      <div className={s.actions}>
        {!!filterTokens.unVisible.length && (
          <TableViewAllFilterTags items={filterTokens.unVisible} />
        )}
        <TableResetFilters resetFilters={resetFilters} noActiveFilters={noActiveFilters} />
      </div>
    </div>
  );
};

export default memo(TableActions);
