import React from 'react';

import LayoutHeader from 'components/layout-header';
import ContentDefaultWrapper from 'containers/content-default-wrapper';

import s from './RiskScore.module.scss';
import ScoringRequestTableView from './scoring-request-table-view';

const RiskScore = () => {
  return (
    <ContentDefaultWrapper>
      <ContentDefaultWrapper.Header>
        <LayoutHeader>Withdrawal requests</LayoutHeader>
      </ContentDefaultWrapper.Header>
      <ContentDefaultWrapper.Body className={s.body}>
        <ScoringRequestTableView />
      </ContentDefaultWrapper.Body>
    </ContentDefaultWrapper>
  );
};

export default RiskScore;
