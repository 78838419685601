import React, { useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { selectedTransactionsSelector } from 'selectors/selectedTransactionsSlice.selectors';
import { userCurrencySelector } from 'selectors/userSlice.selectors';
import { useLazyGetTransactionsQuery } from 'services/overview-service/overviewService';
import { GetTransactionsPropsType } from 'services/overview-service/overviewService.types';
import {
  removeSelectedTransactions,
  setSelectedTransactions,
} from 'slices/selectedTransactionsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import Checkbox from 'components/checkbox';
import { CheckboxChangeEvent } from 'components/checkbox/checkbox';
import Typography from 'components/typography';
import {
  AddressFrom,
  AddressTo,
  CheckedStatus,
  ConvertedFrom,
  Currency,
  Date,
  Direction,
  ExchangeRate,
  FromAmount,
  Hash,
  RowNumber,
  TransactionStatus,
} from 'containers/row-renderers';
import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';
import { TransactionColumnType, ValueRowType } from 'types/tableTypes';
import { TransactionType } from 'types/transactionTypes';

import useQueryParamsHook from 'utils/useQueryParams.hook';

import s from './Transactions.module.scss';

const getId = (trans: { id: string | number }) => trans.id;

const Transactions = () => {
  const params = useParams<{ walletId: string }>();

  const dispatch = useAppDispatch();
  const userCurrency = useAppSelector(userCurrencySelector);
  const selectedTransactions = useAppSelector(selectedTransactionsSelector);

  const [
    getTransactions,
    { isFetching: transactionsIsLoading, isError: transactionsIsError, data: transactionsData },
  ] = useLazyGetTransactionsQuery();

  const { paramsObject } = useQueryParamsHook();

  const transactions = transactionsData?.transactions;

  const handleSelectAll = useCallback(
    (event: CheckboxChangeEvent) => {
      const ids = transactions?.map(getId);

      if (event.target.checked) {
        dispatch(setSelectedTransactions(ids));
      } else {
        dispatch(removeSelectedTransactions(ids));
      }
    },
    [dispatch, transactions],
  );

  const handleSingleCheck = useCallback(
    (id: number) => (event: CheckboxChangeEvent) => {
      if (event.target.checked) {
        dispatch(setSelectedTransactions(id));
      } else {
        dispatch(removeSelectedTransactions(id));
      }
    },
    [dispatch],
  );

  const checkSelected = (trans: TransactionType) => selectedTransactions.includes(trans.id);

  const areAllTransactionsSelected = !!transactions?.length && !!transactions?.every(checkSelected);

  const checkboxRenderer = useCallback(
    (id: ValueRowType) => {
      const isChecked = selectedTransactions.includes(id as number);

      return <CheckedStatus isChecked={isChecked} onChange={handleSingleCheck(id as number)} />;
    },
    [handleSingleCheck, selectedTransactions],
  );

  const COLUMNS: TransactionColumnType[] = useMemo(
    () => [
      { title: ' ', key: 'number', renderer: RowNumber },
      {
        className: s.checkedStatus,
        title: (
          <div>
            <Checkbox isChecked={areAllTransactionsSelected} onChange={handleSelectAll} />
          </div>
        ),
        key: 'id',
        renderer: checkboxRenderer,
        paramsObject,
      },
      {
        title: <Typography size="12">St</Typography>,
        key: 'status',
        renderer: TransactionStatus,
        paramsObject,
      },
      {
        title: 'Merchant Address',
        key: 'merchant_address',
        renderer: AddressFrom,
        sortable: true,
        paramsObject,
      },
      {
        title: 'Interaction With',
        key: 'address',
        renderer: AddressTo,
        sortable: true,
      },
      { title: 'Directions', key: 'direction', renderer: Direction, sortable: true },
      { title: <Typography size="12">Hash</Typography>, key: 'hash', renderer: Hash },
      { title: 'Transaction Date', key: 'date', renderer: Date, sortable: true },
      {
        title: 'Exchange Rate',
        key: 'exchange_rate',
        renderer: ExchangeRate,
        sortable: true,
      },
      { title: 'Currency', key: 'currency', renderer: Currency, sortable: true },
      { title: 'Token Amount', key: 'from_amount', renderer: FromAmount, sortable: true },
      {
        title: 'Fiat Value',
        key: 'converted_from_amount',
        renderer: ConvertedFrom,
        sortable: true,
        userCurrency,
      },
    ],
    [areAllTransactionsSelected, checkboxRenderer, handleSelectAll, paramsObject, userCurrency],
  );

  const getData = useCallback(
    (args: Omit<GetTransactionsPropsType, 'companyId'>) => {
      const updatedProps = {
        ...args,
        address: { id: Number(params.walletId) },
      } as GetTransactionsPropsType;

      return getTransactions(updatedProps);
    },
    [getTransactions, params.walletId],
  );

  return (
    <TableWithHandlers
      tab={TabTypesEnum.COMPANY_TRANSACTIONS}
      className={s.tableWrapper}
      columns={COLUMNS}
      items={transactionsData?.transactions}
      totalCount={transactionsData?.pagination.count}
      getData={getData}
      isLoading={transactionsIsLoading}
      isError={transactionsIsError}
    />
  );
};

export default Transactions;
