import React, { ReactElement } from 'react';

import s from './Utils.module.scss';

export const transformHash = (value: unknown): ReactElement => {
  if (typeof value === 'string') {
    const firstThreeSymbols = value.substring(0, 4);
    const lastThreeSymbols = value.substring(value.length - 4);

    return (
      <span className={s.transformHash}>
        <span>{firstThreeSymbols}</span>
        <span className={s.delimiter}>•</span>
        <span>{lastThreeSymbols}</span>
      </span>
    );
  } else {
    return <>{value}</>;
  }
};
