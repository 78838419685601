import { useEffect } from 'react';

import cn from 'classnames';

import {
  getUserInfoEndpoint,
  useConnectTelegramMutation,
  useDisconnectTelegramMutation,
} from 'services/user-service/userService';
import { useAppSelector } from 'store';

import Button from 'components/button';

import { TgUserType } from 'types/userTypes';

import s from './TgAuth.module.scss';

const TgAuth = () => {
  const tgEnabled = useAppSelector(getUserInfoEndpoint)?.data?.tg_enabled;

  const [connectTg] = useConnectTelegramMutation();
  const [disconnectTg, { isLoading }] = useDisconnectTelegramMutation();

  useEffect(() => {
    const button = document.createElement('script');
    button.async = true;
    button.src = 'https://telegram.org/js/telegram-widget.js?22';
    button.setAttribute('data-telegram-login', process.env.REACT_APP_TG_BOT!);
    button.setAttribute('data-size', 'large');
    button.setAttribute('data-radius', '20');
    button.setAttribute('data-onauth', 'onTelegramAuth(user)');

    const folder = document.getElementById('telegram-widget-container');
    folder?.appendChild(button);

    window.onTelegramAuth = (user: TgUserType) => {
      connectTg({ tg_id: user.id.toString() });
    };
  }, [connectTg]);

  return (
    <div className={cn(s.wrapper, tgEnabled ? s.tgEnabled : s.tgDisabled)}>
      <Button onClick={disconnectTg} isLoading={isLoading}>
        Disconnect TG
      </Button>
      <div id="telegram-widget-container"></div>
    </div>
  );
};

declare global {
  interface Window {
    onTelegramAuth: (user: TgUserType) => void;
  }
}

export default TgAuth;
