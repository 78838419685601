import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper/copyWrapper';

import { ValueRowType } from 'types/tableTypes';

import s from './FromAmount.module.scss';

const FromAmount = (amountInFiat: ValueRowType): ReactElement => {
  if (!amountInFiat) {
    return <></>;
  }

  return (
    <CopyWrapper valueToCopy={amountInFiat as string}>
      <div className={s.wrapper}>
        {amountInFiat.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 6,
        })}
      </div>
    </CopyWrapper>
  );
};
export default FromAmount;
