import React, { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { HOT_WALLETS_TAB } from 'pages/v2/real-time-overview/realTimeOverview';

import { userCurrencySelector } from 'selectors/userSlice.selectors';
import {
  useV2GetCompanyInfoQuery,
  useV2GetHotWalletInfoQuery,
  useV2InvoicesExportToCsvMutation,
  useV2SetInvoiceStatusMutation,
} from 'services/v2-overview-service/overviewService';
import { useAppSelector } from 'store';

import Breadcrumb from 'components/breadcrumb';
import LayoutHeader from 'components/layout-header';
import Typography from 'components/typography';
import ContentDefaultWrapper from 'containers/content-default-wrapper';
import CopyTooltip from 'containers/copy-tooltip';
import CryptoLogos from 'containers/crypto-logos';
import { transformHash } from 'containers/row-renderers/utils';
import SelectedTransactionsActionGroup from 'containers/selected-transactions-action-group';

import { DEFAULT_OFFSET, DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { REAL_TIME_OVERVIEW_ROUTE, getCompanyWithIdRoute } from 'constants/routes';

import { ReactComponent as CopyIconOutlined } from 'assets/icons/copy-icon-outlined.svg';

import s from './CompanyHotWalletDetailsTableView.module.scss';
import HotWalletDetailsTableView from './hot-wallet-detail-table-view';

const HOT_WALLETS_LINK = `${REAL_TIME_OVERVIEW_ROUTE}?tab=${HOT_WALLETS_TAB}&offset=${DEFAULT_OFFSET}&limit=${DEFAULT_PAGE_SIZE}`;
const CompanyHotWalletDetailsTableView: FC = () => {
  const params = useParams<{ companyId: string; hotWalletId: string }>();

  const currentUserCurrency = useAppSelector(userCurrencySelector);

  const { data: companyInfo, isFetching: companyInfoIsFetching } = useV2GetCompanyInfoQuery(
    params.companyId!,
  );
  const { data: hotWalletInfoData, isFetching: hotWalletInfoIsFetching } =
    useV2GetHotWalletInfoQuery(params.hotWalletId || '');

  const isFetching = hotWalletInfoIsFetching || companyInfoIsFetching;

  const BREADCRUMB_ROUTES = [
    { name: 'Hot wallets', path: HOT_WALLETS_LINK },
    { name: companyInfo?.name || '', path: getCompanyWithIdRoute(params.companyId!) },
    { name: transformHash(hotWalletInfoData?.address) },
  ];
  const hotWalletAddressWithWrapper: ReactElement = (
    <CopyTooltip
      className={s.copyButton}
      valueToCopy={hotWalletInfoData?.address}
      placement="right">
      <Typography color="main">{hotWalletInfoData?.address}</Typography>
      <CopyIconOutlined />
    </CopyTooltip>
  );

  const blockchain = hotWalletInfoData?.blockchain?.toUpperCase();

  return (
    <ContentDefaultWrapper>
      <ContentDefaultWrapper.Header>
        <div className={s.breadcrumbsWrapper}>
          <Breadcrumb routes={BREADCRUMB_ROUTES} isLoading={isFetching} />
        </div>
        <LayoutHeader
          className={s.header}
          goBackLink={getCompanyWithIdRoute(params.companyId!)}
          isLoading={isFetching}>
          <Typography size="20" weight="600">
            Hot wallet details
          </Typography>
          <Typography color="main" size="20" weight="600">
            {`${currentUserCurrency.symbol}${hotWalletInfoData?.balance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
          </Typography>
        </LayoutHeader>
        <ContentDefaultWrapper.Actions className={s.actions}>
          <SelectedTransactionsActionGroup
            setStatusMutation={useV2SetInvoiceStatusMutation}
            exportToCsvMutation={useV2InvoicesExportToCsvMutation}
          />
        </ContentDefaultWrapper.Actions>
        <div className={s.invoiceInfo}>
          <CryptoLogos type={blockchain} />
          <Typography>{`${blockchain}:`}</Typography>
          {hotWalletAddressWithWrapper}
        </div>
      </ContentDefaultWrapper.Header>
      <ContentDefaultWrapper.Body className={s.body}>
        <HotWalletDetailsTableView />
      </ContentDefaultWrapper.Body>
    </ContentDefaultWrapper>
  );
};

export default CompanyHotWalletDetailsTableView;
