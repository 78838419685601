import React, { PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './ContentDefaultWrapper.module.scss';

interface ContentDefaultWrapperProps extends PropsWithChildren {
  className?: string;
}

const ContentDefaultWrapper = ({ children, className }: ContentDefaultWrapperProps) => {
  return <section className={cn(s.wrapper, className)}>{children}</section>;
};

ContentDefaultWrapper.Header = ({ children, className }: ContentDefaultWrapperProps) => {
  return <header className={cn(s.header, className)}>{children}</header>;
};

ContentDefaultWrapper.Actions = ({ children, className }: ContentDefaultWrapperProps) => {
  return <div className={cn(s.actions, className)}>{children}</div>;
};

ContentDefaultWrapper.Body = ({ children, className }: ContentDefaultWrapperProps) => {
  return <section className={cn(className)}>{children}</section>;
};

export default ContentDefaultWrapper;
