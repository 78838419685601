import { createApi } from '@reduxjs/toolkit/query/react';

import { BASE_QUERY_WITH_AUTH } from 'services/utils';

export const API_NAME = 'initialApi';

export const TAGS = {
  USER_SETTINGS: 'USER_SETTINGS',
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  GET_HOT_WALLETS: 'GET_HOT_WALLETS',
  GET_WALLETS: 'GET_WALLETS',
  GET_COMPANIES: 'GET_COMPANIES',
  GET_COMPANY_INFO: 'GET_COMPANY_INFO',
  GET_WALLET_INFO: 'GET_WALLET_INFO',
  GET_INVOICES: 'GET_INVOICES',
  SCORING_REQUEST: 'SCORING_REQUEST',
};

const initialApiService = createApi({
  reducerPath: API_NAME,
  baseQuery: BASE_QUERY_WITH_AUTH,
  tagTypes: [...Object.values(TAGS)],
  endpoints: () => ({}),
});

export default initialApiService;
