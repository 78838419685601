import React, { ReactElement, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import useHotWalletTableColumns from 'pages/v2/utils/useHotWalletTableColumns.hook';

import { useLazyV2GetHotWalletsQuery } from 'services/v2-overview-service/overviewService';
import {
  GetHotWalletsPropsType,
  GetInvoicesPropsType,
} from 'services/v2-overview-service/overviewService.types';

import TableWithHandlers from 'containers/table-with-handlers';

import { getCompanyHotWalletWithIdRoute } from 'constants/routes';
import { TabTypesEnum } from 'types/tabTypes';

import s from './CompanyHotWalletsTableView.module.scss';

const CompanyHotWalletsTableView = (): ReactElement => {
  const params = useParams<{ companyId: string }>();

  const [
    getHotWallets,
    { isFetching: transactionsIsLoading, isError: transactionsIsError, data: hotWalletsData },
  ] = useLazyV2GetHotWalletsQuery();

  const getData = useCallback(
    (args: Omit<GetHotWalletsPropsType, 'company'>) => {
      const updatedProps = { ...args, company: { id: params.companyId } } as GetInvoicesPropsType;

      return getHotWallets(updatedProps);
    },
    [getHotWallets, params.companyId],
  );

  const columns = useHotWalletTableColumns({
    removedColumns: ['company_name'],
    hotWallets: hotWalletsData?.transactions,
    merchantAddressRedirectRoute: (hotWalletId: string | number) =>
      getCompanyHotWalletWithIdRoute(params.companyId!, hotWalletId),
  });

  return (
    <TableWithHandlers
      tab={TabTypesEnum.COMPANY_HOT_WALLETS}
      className={s.tableWrapper}
      columns={columns}
      items={hotWalletsData?.transactions}
      totalCount={hotWalletsData?.pagination.count}
      getData={getData}
      isLoading={transactionsIsLoading}
      isError={transactionsIsError}
    />
  );
};

export default CompanyHotWalletsTableView;
