import React, { FC, HTMLInputTypeAttribute, ReactElement } from 'react';

import { Input as InputAntd } from 'antd';
import cx from 'classnames';

import 'antd/lib/input/style/css';

import s from './Input.module.scss';

export type InputStatus = 'error' | 'warning';
export type InputStyleType = 'outlinedGray' | 'outlinedViolet';
export type InputSizeType = 'small' | 'medium' | 'large';

interface InputProps {
  placeholder?: string;
  className?: string;
  name?: string;
  id?: string;
  value?: string;
  handleChange?: (text: string) => void;
  onPressEnter?: (text: string) => void;
  status?: InputStatus;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  // styleType?: InputStyleType;
  inputType?: 'TextArea' | 'Password' | 'Search' | 'Group';
  type?: HTMLInputTypeAttribute;
  fullWidth?: boolean;
  suffix?: ReactElement;
  autoComplete?: string;
  min?: string | number;
  size?: InputSizeType;
}

const Input: FC<InputProps> = (props): ReactElement => {
  const {
    placeholder,
    className = '',
    name,
    id,
    value,
    inputType,
    handleChange,
    // styleType,
    disabled,
    maxLength,
    readOnly,
    status,
    onPressEnter,
    fullWidth = false,
    size = 'medium',
    ...otherProps
  } = props;

  const Component = inputType ? InputAntd[inputType] : InputAntd;
  return (
    <Component
      className={cx(s[size], {
        [s.input]: true,
        [s.fullWidth]: fullWidth,
        [className]: className,
      })}
      onPressEnter={(e): void => {
        if (onPressEnter) {
          onPressEnter(e.target.value);
        }
      }}
      onChange={(e): void => {
        if (handleChange) {
          handleChange(e.target.value);
        }
      }}
      status={status}
      readOnly={readOnly}
      maxLength={maxLength}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      id={id}
      value={value}
      {...otherProps}
    />
  );
};

export default Input;
