import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import {
  useExportToCsvMutation,
  useSetStatusMutation,
} from 'services/overview-service/overviewService';

import LayoutHeader from 'components/layout-header';
import Tabs from 'components/tabs';
import ContentDefaultWrapper from 'containers/content-default-wrapper';
import SelectedTransactionsActionGroup from 'containers/selected-transactions-action-group';

import { DEFAULT_PAGINATION } from 'constants/pagination';

import { getFiltersFromParamsObject } from 'utils/filter';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import CompaniesTableView from './companies-table-view';
import TransactionsTableView from './transactions-table-view';

const COMPANIES_TAB = 'Companies';
const TRANSACTIONS_TAB = 'Transactions';

const TABS = [
  { label: COMPANIES_TAB, key: COMPANIES_TAB, children: <CompaniesTableView /> },
  { label: TRANSACTIONS_TAB, key: TRANSACTIONS_TAB, children: <TransactionsTableView /> },
];

const RealTimeOverview = (): ReactElement => {
  const [activeTab, setActiveTab] = useState<string>('');
  const { paramsObject, setQueryParams, isInitialized } = useQueryParamsHook();

  const ref = useRef<boolean>(false);

  useEffect(() => {
    const tab = isInitialized && (paramsObject?.tab || COMPANIES_TAB);

    if (tab && !ref.current) {
      setQueryParams({ tab });
      setActiveTab(tab);
      ref.current = true;
    }

    if (isInitialized && !paramsObject?.tab) {
      setQueryParams({ tab });
      setActiveTab(tab as string);
    }
  }, [activeTab, isInitialized, paramsObject?.tab, setQueryParams]);

  const onTabClick = useCallback(
    (activeKey: string) => {
      setActiveTab(activeKey);
      setQueryParams(
        { tab: activeKey, ...DEFAULT_PAGINATION, ...getFiltersFromParamsObject(paramsObject) },
        true,
      );
    },
    [paramsObject, setQueryParams],
  );

  const showActions = activeTab === TRANSACTIONS_TAB;

  return (
    <ContentDefaultWrapper>
      <ContentDefaultWrapper.Header>
        <LayoutHeader>Real time overview</LayoutHeader>
        {showActions && (
          <ContentDefaultWrapper.Actions>
            <SelectedTransactionsActionGroup
              setStatusMutation={useSetStatusMutation}
              exportToCsvMutation={useExportToCsvMutation}
            />
          </ContentDefaultWrapper.Actions>
        )}
      </ContentDefaultWrapper.Header>
      <ContentDefaultWrapper.Body>
        <Tabs activeKey={activeTab} animated={true} onTabClick={onTabClick} items={TABS} />
      </ContentDefaultWrapper.Body>
    </ContentDefaultWrapper>
  );
};

export default RealTimeOverview;
