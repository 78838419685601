import reduce from 'lodash.reduce';

import { FILTER_VALUE_END_SEPARATOR, FILTER_VALUE_START_SEPARATOR } from 'constants/query';
import { ParamsType } from 'types/paramsTypes';

const VALUE_BETWEEN_SQUARE_BRACKETS_MATCHER = /\[(.*?)]/;

export const isFilterKey = (objKey?: string) => {
  if (!objKey) {
    return false;
  }

  return (
    objKey.includes(FILTER_VALUE_START_SEPARATOR) && objKey.includes(FILTER_VALUE_END_SEPARATOR)
  );
};

export const getFilterKey = (key: string): string => {
  return key.match(VALUE_BETWEEN_SQUARE_BRACKETS_MATCHER)?.[1] || '';
};

export const getFilterType = (key: string): string => {
  return key.split(FILTER_VALUE_START_SEPARATOR).shift() || '';
};

export const getFiltersFromParamsObject = (paramsObject?: { [key: string]: string }) => {
  if (!paramsObject) {
    return {};
  }

  return reduce(
    paramsObject,
    (prevVal: { [key: string]: ParamsType }, curVal, key: string) => {
      if (isFilterKey(key)) {
        prevVal[key] = curVal;
      }
      return prevVal;
    },
    {},
  );
};
