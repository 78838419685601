import React, { ForwardedRef, ReactElement, forwardRef } from 'react';

import { Tag as AntdTag } from 'antd';
import cx from 'classnames';

import { NonEmptyChildren } from 'types/childrenTypes';

import { ReactComponent as CloseIcon } from 'assets/icons/cross-icon.svg';

import s from './Tag.module.scss';

interface TagProps<R> {
  value: R;
  closable?: boolean;
  className?: string;
  onClose?: (e: R) => void;
  icon?: ReactElement;
  closeIcon?: ReactElement;
  children: NonEmptyChildren;
}

const Tag = <R,>(props: TagProps<R>, ref: ForwardedRef<HTMLSpanElement>): ReactElement => {
  const { closable = false, className = '', onClose, icon, children, value } = props;

  const handleClose = () => {
    if (onClose) {
      onClose(value);
    }
  };

  return (
    <AntdTag
      ref={ref}
      className={cx({
        [s.wrapper]: true,
        [s.wrapperWithIcon]: closable,
        [className]: className,
      })}
      closable={closable}
      onClose={handleClose}
      icon={icon}
      closeIcon={<CloseIcon />}>
      <span>{children}</span>
    </AntdTag>
  );
};

export default forwardRef(Tag);
