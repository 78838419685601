import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import { userDataSelector } from 'selectors/userSlice.selectors';
import { useAppSelector } from 'store';

import Dropdown from 'components/dropdown';
import Typography from 'components/typography';
import ModalSettings from 'containers/modal-settings';

import { ReactComponent as Settings } from 'assets/icons/settings-icon.svg';

import s from './Email.module.scss';
import useEmailDropdownItems from './useEmailDropdownItems.hook';

const Email = () => {
  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const { items, handleItemClick } = useEmailDropdownItems({
    setIsOverlayOpened,
    setIsModalOpened,
  });

  const user = useAppSelector(userDataSelector);

  const openCloseModalHandler = useCallback(() => {
    setIsModalOpened(false);
  }, []);

  return (
    <>
      <ModalSettings isModalOpened={isModalOpened} openCloseModalHandler={openCloseModalHandler} />
      <Dropdown
        handleItemClick={handleItemClick}
        open={isOverlayOpened}
        items={items}
        onOpenChange={setIsOverlayOpened}
        placement="bottomLeft">
        <button className={cn(s.dropdownButton, { [s.active]: isOverlayOpened })}>
          <Typography size="15" weight="500">
            <Settings />
            {user?.email || ''}
          </Typography>
        </button>
      </Dropdown>
    </>
  );
};

export default Email;
