import { SLICE_NAME, UserSliceState } from 'slices/userSlice';
import { RootState } from 'store';

import { UserType } from 'types/userTypes';

export const userStateSelector = (state: RootState): UserSliceState => state[SLICE_NAME];

export const userDataSelector = (state: RootState): UserType | null =>
  userStateSelector(state)?.user;
export const userIsLoadingSelector = (state: RootState): boolean =>
  userStateSelector(state)?.userIsLoading;

export const userCurrencySelector = (state: RootState) =>
  userDataSelector(state)?.default_settings?.default_currency || { symbol: '', currency: '' };

export const userTimezoneOffsetSelector = (state: RootState): number | null =>
  userStateSelector(state)?.user?.default_settings?.default_timezone_offset ?? null;

export const userTgEnabledSelector = (state: RootState): boolean =>
  userStateSelector(state)?.user?.default_settings?.tg_enabled || false;
