import React, { FC, useEffect } from 'react';

import cx from 'classnames';

import {
  filtersIsLoadingSelector,
  filtersSelector,
  tabFiltersIsLoadingSelector,
} from 'selectors/filterSlice.selectors';
import { useLazyGetFiltersQuery } from 'services/overview-service/overviewService';
import { useLazyV2GetFiltersQuery } from 'services/v2-overview-service/overviewService';
import { useAppSelector } from 'store';

import WithSkeletonLoader from 'components/with-skeleton-loader';

import { WATCH_BLOCK_VERSION_1, WATCH_BLOCK_VERSION_LOCAL_KEY } from 'constants/version';

import useLocalStorage from 'utils/useLocalStorage.hook';

import s from './Sidebar.module.scss';
import CopyFilters from './copy-filters';
import Filters from './filters';
import SidebarSkeleton from './sidebar.skeleton';

interface SidebarProps {
  collapsed: boolean;
}
let version: string | undefined = '';

const Sidebar: FC<SidebarProps> = (props) => {
  const { collapsed } = props;
  const [currentVersion] = useLocalStorage(WATCH_BLOCK_VERSION_LOCAL_KEY, WATCH_BLOCK_VERSION_1);

  const filtersIsLoading = useAppSelector(filtersIsLoadingSelector);
  const tabFiltersIsLoading = useAppSelector(tabFiltersIsLoadingSelector);
  const filters = useAppSelector(filtersSelector);

  const mutation =
    currentVersion === WATCH_BLOCK_VERSION_1 ? useLazyGetFiltersQuery : useLazyV2GetFiltersQuery;
  const [getFilters] = mutation();

  const stringifiedFilters = JSON.stringify(filters);
  useEffect(() => {
    if (stringifiedFilters === 'null' && currentVersion !== version) {
      getFilters();
      version = currentVersion;
    }
  }, [stringifiedFilters, getFilters, currentVersion]);

  return (
    <div className={cx(s.wrapper, { [s.collapsed]: collapsed })}>
      <div className={s.filtersWrapper}>
        <WithSkeletonLoader
          isLoading={filtersIsLoading || tabFiltersIsLoading}
          skeletonLoader={<SidebarSkeleton />}>
          <Filters />
        </WithSkeletonLoader>
      </div>
      <CopyFilters />
    </div>
  );
};

export default Sidebar;
