export const formatTimezone = (offset: number, doubleDigit?: boolean): string => {
  if (doubleDigit) {
    if (offset === 0) {
      return ` 0${offset}`;
    }

    if (offset > 0 && offset < 10) {
      return `+0${offset}`;
    }

    if (offset < 0 && offset > -10) {
      return `-0${offset.toString().substring(1)}`;
    }

    return offset > 0 ? `+${offset}` : offset.toString();
  }

  if (offset === 0) {
    return ` ${offset}`;
  }

  return offset > 0 ? `+${offset}` : offset.toString();
};
