import React, { ReactElement } from 'react';

import { ValueRowType } from 'types/tableTypes';

const RowNumber = (
  _: ValueRowType,
  { index, offset }: { index: number; offset: number },
): ReactElement => {
  let transactionNumber;

  if (offset === 0) {
    transactionNumber = 1 + index;
  } else {
    transactionNumber = Number(offset) + index + 1;
  }

  return <div>{transactionNumber}</div>;
};

export default RowNumber;
