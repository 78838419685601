import React, { FC, memo } from 'react';

import TableNotification from 'containers/table-with-handlers/table-notification';

import s from './TableErrorComponent.module.scss';

interface ErrorComponentProps {
  columnsLength: number;
  resetFilters: () => void;
  rerunRequest: () => void;
}

const TableErrorComponent: FC<ErrorComponentProps> = (props) => {
  const { columnsLength, resetFilters, rerunRequest } = props;

  return (
    <TableNotification colSpan={columnsLength}>
      <TableNotification.Header>Something went wrong</TableNotification.Header>
      <TableNotification.Content>
        If the issue is still there try to
        <button className={s.errorResetFilters} onClick={resetFilters}>
          Reset filters
        </button>
      </TableNotification.Content>
      <TableNotification.Action onClick={rerunRequest}>Try again</TableNotification.Action>
    </TableNotification>
  );
};

export default memo(TableErrorComponent);
