import React, { ReactElement } from 'react';

import { useSetStatusMutation } from 'services/overview-service/overviewService';

import TransactionsStatusDropdown from 'containers/transactions-status-dropdown';

import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';

import { ReactComponent as ClipboardNotesIcon } from 'assets/icons/clipboard-notes-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye-icon.svg';
import { ReactComponent as LightbulbIcon } from 'assets/icons/lightbulb-icon.svg';

import s from './TransactionStatus.module.scss';

const ICONS: Record<string | number, ReactElement> = {
  0: <LightbulbIcon />,
  1: <EyeIcon className={s.eye} />,
  2: <ClipboardNotesIcon className={s.clipboardNotes} />,
};
const TransactionStatus = <R,>(
  value: ValueRowType,
  otherProps: RendererProps<GetTableDataArgsType, R, PossibleTableItemsType>,
) => {
  const typedValue = typeof value === 'string' || typeof value === 'number' ? value : 0;
  const setStatusMutation = otherProps?.setStatusMutation || useSetStatusMutation;

  return (
    <TransactionsStatusDropdown
      setStatusMutation={setStatusMutation}
      placement="topLeft"
      align={{ offset: [30, 130] }}
      currentElementId={otherProps?.elementData?.id}>
      <button className={s.wrapper}>{ICONS[typedValue]}</button>
    </TransactionsStatusDropdown>
  );
};

export default TransactionStatus;
