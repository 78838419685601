import { createSlice } from '@reduxjs/toolkit';

import { UserType } from 'types/userTypes';

export const SLICE_NAME = 'userSlice';

export type UserSliceState = {
  user: UserType | null;
  userIsLoading: boolean;
};

export const DEFAULT_USER_DATA = {
  email: null,
  default_settings: null,
  tg_enabled: false,
};

const initialState: UserSliceState = {
  user: DEFAULT_USER_DATA,
  userIsLoading: true,
};

export const userSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    clearUser(state) {
      state.user = DEFAULT_USER_DATA;
    },
    setUserIsLoading(state, action) {
      state.userIsLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Issue that prevent us to use "addMatcher" https://github.com/reduxjs/redux-toolkit/issues/2817
    // TODO: fix later
    builder.addCase('initialApi/executeQuery/fulfilled', (state, action) => {
      const retypedAction = action as unknown as {
        payload: UserType;
        meta: { arg: { endpointName: string } };
      };
      if (retypedAction?.meta.arg.endpointName === 'getUserInfo' && action) {
        state.user = retypedAction.payload;
      }
    });
  },
});

export const { setUser, setUserIsLoading, clearUser } = userSlice.actions;

export default userSlice.reducer;
