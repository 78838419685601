import React, { FC, PropsWithChildren, useState } from 'react';

import { selectedTransactionsSelector } from 'selectors/selectedTransactionsSlice.selectors';
import { useSetStatusMutation } from 'services/overview-service/overviewService';
import { useAppSelector } from 'store';

import Dropdown from 'components/dropdown';
import { DropdownProps } from 'components/dropdown/dropdown';
import Typography from 'components/typography';

import { STATUS_ENUM } from 'types/transactionTypes';

import { ReactComponent as ClipboardNotesIcon } from 'assets/icons/clipboard-notes-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye-icon.svg';
import { ReactComponent as LightbulbIcon } from 'assets/icons/lightbulb-icon.svg';

import s from './TransactionsStatusDropdown.module.scss';

const dropdownItems = [
  {
    label: (
      <Typography size="13" className={s.option}>
        <EyeIcon className={s.eye} /> Viewed
      </Typography>
    ),
    key: STATUS_ENUM.VIEWED,
  },
  {
    label: (
      <Typography size="13" className={s.option}>
        <ClipboardNotesIcon className={s.clipboardNotes} />
        Reported
      </Typography>
    ),
    key: STATUS_ENUM.REPORTED,
  },
  {
    label: (
      <Typography size="13" className={s.option}>
        <LightbulbIcon /> New
      </Typography>
    ),
    key: STATUS_ENUM.NEW,
  },
];

interface TransactionsStatusDropdownProps extends PropsWithChildren {
  disabled?: boolean;
  currentElementId?: number | string;
  align?: DropdownProps['align'];
  placement?: DropdownProps['placement'];
  setStatusMutation: typeof useSetStatusMutation;
}
const TransactionsStatusDropdown: FC<TransactionsStatusDropdownProps> = (props) => {
  const {
    setStatusMutation,
    disabled,
    children,
    currentElementId,
    align = { offset: [0, 4] },
    placement = 'bottom',
  } = props;
  const selectedTransactions = useAppSelector(selectedTransactionsSelector);

  const [isOverlayOpened, setIsOverlayOpened] = useState(false);

  const [setStatus, { isLoading: setStatusIsLoading }] = setStatusMutation();

  const handleItemClick = (key: string) => {
    setStatus({
      status: Number(key),
      ids: currentElementId ? [currentElementId] : selectedTransactions,
    });
  };

  return (
    <Dropdown
      disabled={disabled || setStatusIsLoading}
      align={align}
      open={isOverlayOpened}
      items={dropdownItems}
      handleItemClick={(event: { key: string }) => handleItemClick(event.key)}
      onOpenChange={setIsOverlayOpened}
      placement={placement}>
      {children}
    </Dropdown>
  );
};

export default TransactionsStatusDropdown;
