import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  useV2GetCompanyInfoQuery,
  useV2HotWalletsExportToCsvMutation,
  useV2InvoicesExportToCsvMutation,
  useV2SetHotWalletStatusMutation,
  useV2SetInvoiceStatusMutation,
} from 'services/v2-overview-service/overviewService';

import Breadcrumb from 'components/breadcrumb';
import LayoutHeader from 'components/layout-header';
import Tabs from 'components/tabs';
import ContentDefaultWrapper from 'containers/content-default-wrapper';
import SelectedTransactionsActionGroup from 'containers/selected-transactions-action-group';

import { DEFAULT_PAGINATION } from 'constants/pagination';
import { REAL_TIME_OVERVIEW_ROUTE } from 'constants/routes';

import { getFiltersFromParamsObject } from 'utils/filter';
import goBackFunction from 'utils/goBackFunction';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import s from './Company.module.scss';
import CompanyHotWalletsTableView from './company-hot-wallets-table-view';
import CompanyInvoicesTableView from './company-invoice-table-view';

const INVOICES_TAB = 'Invoices';
const HOT_WALLETS_TAB = 'Hot wallets';

const TABS = [
  { label: INVOICES_TAB, key: INVOICES_TAB, children: <CompanyInvoicesTableView /> },
  { label: HOT_WALLETS_TAB, key: HOT_WALLETS_TAB, children: <CompanyHotWalletsTableView /> },
];

const Company = () => {
  const navigate = useNavigate();
  const params = useParams<{ companyId: string }>();

  const [activeTab, setActiveTab] = useState<string>('');
  const { paramsObject, setQueryParams } = useQueryParamsHook();

  const {
    data: companyInfo,
    isFetching: companyInfoIsFetching,
    refetch: companyInfoRefetch,
  } = useV2GetCompanyInfoQuery(params.companyId!);

  useEffect(() => {
    window.addEventListener('popstate', () => goBackFunction(navigate, REAL_TIME_OVERVIEW_ROUTE));

    return () => {
      window.removeEventListener('popstate', () =>
        goBackFunction(navigate, REAL_TIME_OVERVIEW_ROUTE),
      );
    };
  }, [navigate]);

  useEffect(() => {
    companyInfoRefetch();
  }, [companyInfoRefetch, params.companyId]);

  const BREADCRUMB_ROUTES = [
    { name: 'Overview', path: REAL_TIME_OVERVIEW_ROUTE },
    { name: companyInfo?.name || '' },
  ];

  useEffect(() => {
    const tab = paramsObject?.tab || INVOICES_TAB;

    if (paramsObject && !activeTab) {
      setQueryParams({ tab });
      setActiveTab(tab);
    }
  }, [activeTab, paramsObject, setQueryParams]);

  const onTabClick = useCallback(
    (activeKey: string) => {
      setActiveTab(activeKey);
      setQueryParams(
        { tab: activeKey, ...DEFAULT_PAGINATION, ...getFiltersFromParamsObject(paramsObject) },
        true,
      );
    },
    [paramsObject, setQueryParams],
  );

  const setStatusMutation =
    activeTab === INVOICES_TAB ? useV2SetInvoiceStatusMutation : useV2SetHotWalletStatusMutation;
  const exportToCsvMutation =
    activeTab === INVOICES_TAB
      ? useV2InvoicesExportToCsvMutation
      : useV2HotWalletsExportToCsvMutation;

  return (
    <ContentDefaultWrapper>
      <ContentDefaultWrapper.Header>
        <div className={s.breadcrumbsWrapper}>
          <Breadcrumb routes={BREADCRUMB_ROUTES} isLoading={companyInfoIsFetching} />
        </div>
        <LayoutHeader
          className={s.header}
          goBackLink={REAL_TIME_OVERVIEW_ROUTE}
          isLoading={companyInfoIsFetching}>
          {companyInfo?.name || ''}
        </LayoutHeader>
        <ContentDefaultWrapper.Actions className={s.actions}>
          <SelectedTransactionsActionGroup
            setStatusMutation={setStatusMutation}
            exportToCsvMutation={exportToCsvMutation}
          />
        </ContentDefaultWrapper.Actions>
      </ContentDefaultWrapper.Header>
      <ContentDefaultWrapper.Body>
        <Tabs activeKey={activeTab} animated={true} onTabClick={onTabClick} items={TABS} />
      </ContentDefaultWrapper.Body>
    </ContentDefaultWrapper>
  );
};

export default Company;
