import React, { FC, Fragment } from 'react';

import cn from 'classnames';

import Typography from 'components/typography';

import { SortType } from 'types/sortingTypes';
import { BaseColumnType } from 'types/tableTypes';

import { ReactComponent as SortAsc } from 'assets/icons/sort-asc-icon.svg';
import { ReactComponent as SortDesc } from 'assets/icons/sort-desc-icon.svg';

import s from '../Table.module.scss';

interface TableHeadProps {
  columns: BaseColumnType[];
  activeSort?: { [key: string]: string }[];
  handleSortChange?: (type: SortType, key: string) => void;
}

const getSortOrder = (activeSort: SortType): SortType => {
  if (!activeSort) {
    return 'asc';
  }

  return activeSort === 'asc' ? 'desc' : undefined;
};

const TableHead: FC<TableHeadProps> = (props) => {
  const { columns, activeSort, handleSortChange } = props;
  const handleSortClick = (sortable?: boolean, type?: 'asc' | 'desc', key?: string) => {
    if (!sortable || !key) {
      return;
    }

    if (handleSortChange) {
      handleSortChange(type, key);
    }
  };

  const renderColumns = (column: BaseColumnType, index: number) => {
    if (!column.title) {
      return <Fragment key={`renderColumns__${index}`} />;
    }

    const isSortable = !!column.sortable;
    const sortOrder =
      activeSort &&
      Array.isArray(activeSort) &&
      activeSort.find((sort) => !!sort[column.key])?.[column.key];
    const activeAsc = sortOrder === 'asc';
    const activeDesc = sortOrder === 'desc';

    return (
      <th
        key={`${column.title}/${column.key}/${index}`}
        colSpan={column.colSpan || 1}
        className={cn(column.className)}>
        {column.sortable ? (
          <button
            onClick={() =>
              handleSortClick(isSortable, getSortOrder(sortOrder as SortType), column.key)
            }>
            <Typography size="12" weight="600">
              {column.title}
            </Typography>
            <div className={s.sort}>
              <SortAsc className={cn({ [s.sortActive]: activeAsc })} />
              <SortDesc className={cn({ [s.sortActive]: activeDesc })} />
            </div>
          </button>
        ) : (
          <Typography size="12">{column.title}</Typography>
        )}
      </th>
    );
  };

  return (
    <thead>
      <tr>{columns.map(renderColumns)}</tr>
    </thead>
  );
};

export default TableHead;
