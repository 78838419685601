import React, { ReactElement, useCallback, useEffect } from 'react';

import { Routes } from 'react-router-dom';

import { userDataSelector, userIsLoadingSelector } from 'selectors/userSlice.selectors';
import { useLazyGetUserInfoQuery, useSignInMutation } from 'services/user-service/userService';
import { setUser, setUserIsLoading } from 'slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store';

import AppLayout from 'containers/app-layout';

import { NON_AUTH_ROUTES } from 'constants/routes';
import { WATCHBLOCK_REFRESH_TOKEN, WATCHBLOCK_TOKEN } from 'constants/token';

import { ReactComponent as Spinner } from 'assets/icons/spinner-animated-icon.svg';

import { renderRoutes } from 'utils/routes';
import useLocalStorage from 'utils/useLocalStorage.hook';
import useQueryParamsHook from 'utils/useQueryParams.hook';
import useTheme from 'utils/useTheme.hook';

import s from './App.module.scss';

const App = (): ReactElement => {
  const [currentTheme] = useTheme();
  const dispatch = useAppDispatch();

  const [token, setToken] = useLocalStorage(WATCHBLOCK_TOKEN);
  const [refreshToken, setRefreshToken] = useLocalStorage(WATCHBLOCK_REFRESH_TOKEN);
  const { paramsObject } = useQueryParamsHook();

  const user = useAppSelector(userDataSelector);
  const userIsLoading = useAppSelector(userIsLoadingSelector);

  const [getUserInfo] = useLazyGetUserInfoQuery();
  const [signIn, { isLoading: signInIsFetching }] = useSignInMutation();

  const isAuth = token && refreshToken && user?.email;

  const getUserDataAndToken = useCallback(async () => {
    if (paramsObject?.token) {
      signIn(paramsObject?.token)
        .then((res) => {
          if ('error' in res) {
            console.error('token expired');
          } else {
            setToken(res.data.access_token.toString());
            setRefreshToken(res.data.refresh_token.toString());
          }
        })
        .then(() => {
          getUserInfo().then((res) => {
            dispatch(setUser(res?.data));
          });
        });
    } else if (localStorage.getItem(WATCHBLOCK_TOKEN)) {
      await getUserInfo().then((res) => {
        dispatch(setUser(res?.data));
      });
    }
  }, [dispatch, getUserInfo, paramsObject?.token, setRefreshToken, setToken, signIn]);

  const authProcess = useCallback(async () => {
    try {
      await getUserDataAndToken();
    } catch (error) {
      throw new Error('Oauth login failed');
    } finally {
      dispatch(setUserIsLoading(false));
    }
  }, [dispatch, getUserDataAndToken]);

  useEffect(() => {
    document.getElementById('body')!.className = currentTheme as string;
  }, [currentTheme]);

  useEffect(() => {
    authProcess();
  }, [authProcess, dispatch]);

  if (userIsLoading || signInIsFetching) {
    return (
      <div className={s.loader}>
        <Spinner />
      </div>
    );
  }

  if (isAuth) {
    return <AppLayout />;
  }

  return <Routes>{NON_AUTH_ROUTES.map(renderRoutes)}</Routes>;
};

export default App;
