import { FormItemProps } from 'components/form';

export const LOGIN_FORM_NAME = 'LOGIN_FORM_NAME';
export const EMAIL_INPUT_PLACEHOLDER = 'Type e-mail here';
const INVALID_ERROR = 'Please enter a valid email address';
const EMAIL_IS_REQUIRED_ERROR = 'Email is required';

export const RULES = [
  { required: true, message: EMAIL_IS_REQUIRED_ERROR },
  { message: INVALID_ERROR, type: 'email' },
] as FormItemProps['rules'];
export const INCORRECT_EMAIL = {
  help: 'Email is not allowed',
  validateStatus: 'error' as 'error',
};
export const FIND_EMAIL_LINK = `https://mail.google.com/mail/u/0/#search/from%3A%40${process.env.REACT_APP_CONFIRMATION_EMAIL}+in%3Aanywhere`;
