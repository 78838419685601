import React, { ReactElement, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { userCurrencySelector } from 'selectors/userSlice.selectors';
import {
  useExportToCsvMutation,
  useGetCompanyInfoQuery,
  useGetWalletInfoQuery,
  useSetStatusMutation,
} from 'services/overview-service/overviewService';
import { useAppSelector } from 'store';

import Breadcrumb from 'components/breadcrumb';
import LayoutHeader from 'components/layout-header';
import Tabs from 'components/tabs';
import Typography from 'components/typography';
import ContentDefaultWrapper from 'containers/content-default-wrapper';
import CopyTooltip from 'containers/copy-tooltip';
import CryptoLogos from 'containers/crypto-logos';
import { transformHash } from 'containers/row-renderers/utils';
import SelectedTransactionsActionGroup from 'containers/selected-transactions-action-group';

import { REAL_TIME_OVERVIEW_ROUTE, getCompanyWithIdRoute } from 'constants/routes';

import { ReactComponent as CopyIconOutlined } from 'assets/icons/copy-icon-outlined.svg';

import goBackFunction from 'utils/goBackFunction';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import s from './CompanyWalletTransactionsView.module.scss';
import Transactions from './transactions';

const TRANSACTIONS_TAB = 'Transactions';

const TABS = [{ label: TRANSACTIONS_TAB, key: TRANSACTIONS_TAB, children: <Transactions /> }];

const CompanyWalletTransactionsView = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string>('');
  const { paramsObject, setQueryParams } = useQueryParamsHook();

  const params = useParams<{ companyId: string; walletId: string }>();

  const currentUserCurrency = useAppSelector(userCurrencySelector);

  const BACK_ROUTE = getCompanyWithIdRoute(params.companyId!);

  const { data: walletData, isFetching: walletInfoIsFetching } = useGetWalletInfoQuery(
    params.walletId!,
  );
  const { data: companyInfo, isFetching: companyInfoIsFetching } = useGetCompanyInfoQuery(
    params.companyId!,
  );

  useEffect(() => {
    window.addEventListener('popstate', () => goBackFunction(navigate, BACK_ROUTE));

    return () => {
      window.removeEventListener('popstate', () => goBackFunction(navigate, BACK_ROUTE));
    };
  }, [BACK_ROUTE, navigate]);

  useEffect(() => {
    const tab = paramsObject?.tab || TRANSACTIONS_TAB;

    if (paramsObject && !activeTab) {
      setQueryParams({ tab });
      setActiveTab(tab);
    }
  }, [activeTab, paramsObject, setQueryParams]);

  const walletAddress = walletData?.address || '';
  const isLoading = walletInfoIsFetching || companyInfoIsFetching;

  const BREADCRUMB_ROUTES = [
    { name: 'Overview', path: REAL_TIME_OVERVIEW_ROUTE },
    { name: companyInfo?.name || '', path: BACK_ROUTE },
    { name: transformHash(walletAddress) },
  ];

  const walletAddressWithWrapper: ReactElement = !isLoading ? (
    <CopyTooltip className={s.copyButton} valueToCopy={walletAddress} placement="right">
      {walletAddress}
      <CopyIconOutlined />
    </CopyTooltip>
  ) : (
    <></>
  );

  const showActions = activeTab === TRANSACTIONS_TAB;

  return (
    <ContentDefaultWrapper>
      <ContentDefaultWrapper.Header>
        <div className={s.breadcrumbsWrapper}>
          <Breadcrumb routes={BREADCRUMB_ROUTES} isLoading={isLoading} />
        </div>
        <LayoutHeader
          className={s.header}
          goBackLink={getCompanyWithIdRoute(params.companyId!)}
          isLoading={isLoading}>
          {`Wallet details ${currentUserCurrency.symbol}${walletData?.balance.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          )}`}
        </LayoutHeader>
        {showActions && (
          <ContentDefaultWrapper.Actions className={s.actions}>
            <SelectedTransactionsActionGroup
              setStatusMutation={useSetStatusMutation}
              exportToCsvMutation={useExportToCsvMutation}
            />
          </ContentDefaultWrapper.Actions>
        )}
        <div className={s.walletInfo}>
          <CryptoLogos type={walletData?.blockchain} />
          <Typography>{`${walletData?.blockchain}:`}</Typography>
          {walletAddressWithWrapper}
        </div>
      </ContentDefaultWrapper.Header>
      <ContentDefaultWrapper.Body>
        <Tabs activeKey={TRANSACTIONS_TAB} animated={true} items={TABS} />
      </ContentDefaultWrapper.Body>
    </ContentDefaultWrapper>
  );
};

export default CompanyWalletTransactionsView;
