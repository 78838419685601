import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper';

import { ValueRowType } from 'types/tableTypes';

import s from './IntegerNumber.module.scss';

const IntegerNumber = (value: ValueRowType): ReactElement => {
  const preparedValue = value ?? '';

  return (
    <CopyWrapper valueToCopy={preparedValue as string}>
      <div className={s.wrapper}>{preparedValue.toLocaleString()}</div>
    </CopyWrapper>
  );
};

export default IntegerNumber;
