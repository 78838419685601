import React, { FC } from 'react';

import { Tooltip as TooltipAntd, TooltipProps } from 'antd';
import cx from 'classnames';

import 'antd/lib/tooltip/style/css';

import s from './Tooltip.module.scss';

const Tooltip: FC<TooltipProps> = (props) => {
  const { className = '', ...otherProps } = props;

  return (
    <TooltipAntd
      {...otherProps}
      overlayClassName={cx({ [className]: className, [s.wrapper]: true })}
    />
  );
};

export default Tooltip;
