import React, { FC, ReactElement, useCallback } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface.d';

import { DatePickerPopupPlacement } from 'components/date-picker/datePickerTypes';

import DatePicker from './datePickerInit';

interface RangePickerProps {
  className?: string;
  disabled?: boolean;
  nextIcon?: ReactElement;
  prevIcon?: ReactElement;
  open?: boolean;
  placeholder?: [string, string];
  placement?: DatePickerPopupPlacement;
  onChange: (
    from: string,
    to: string,
    dateJsValues: { dayJsFrom: unknown; dayJsTo: unknown },
  ) => void;
  value?: { from: string | null; to: string | null };
}

const dateFormat = 'YYYY-MM-DD';
const RangePicker: FC<RangePickerProps> = (props) => {
  const {
    className = '',
    disabled,
    nextIcon,
    prevIcon,
    open,
    placeholder,
    onChange,
    value,
  } = props;

  const handleChange = useCallback(
    (value: RangeValue<Dayjs>, formatString: [string, string]) => {
      if (onChange && value) {
        onChange(formatString[0], formatString[1], {
          dayJsFrom: value[0],
          dayJsTo: value[1],
        });
      }
    },
    [onChange],
  );

  const transformedValue = [
    value?.from ? dayjs(value?.from, dateFormat) : null,
    value?.to ? dayjs(value?.to, dateFormat) : null,
  ] as RangeValue<Dayjs>;

  return (
    <DatePicker.RangePicker
      format={dateFormat}
      value={transformedValue}
      onChange={handleChange}
      placeholder={placeholder}
      open={open}
      bordered={false}
      className={className}
      disabled={disabled}
      nextIcon={nextIcon}
      prevIcon={prevIcon}
    />
  );
};

export default RangePicker;
