import { createSlice } from '@reduxjs/toolkit';

export const SLICE_NAME = 'selectedTransactions';

export type SelectedTransactionsSliceState = {
  selectedTransactions: number[];
};

const initialState: SelectedTransactionsSliceState = {
  selectedTransactions: [],
};

export const selectedTransactionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearSelectedTransactions(state) {
      state.selectedTransactions = [];
    },
    setSelectedTransactions(state, action) {
      if (action.payload.clear) {
        state.selectedTransactions = action.payload;
      } else {
        const arrayToConcat = Array.isArray(action.payload) ? action.payload : [action.payload];
        state.selectedTransactions = Array.from(
          new Set(state.selectedTransactions.concat(arrayToConcat)),
        );
      }
    },
    removeSelectedTransactions(state, action) {
      const arrayToConcat = Array.isArray(action.payload) ? action.payload : [action.payload];
      const removeIds = (id: number) => !arrayToConcat.includes(id);

      state.selectedTransactions = state.selectedTransactions.filter(removeIds);
    },
  },
});

export const { clearSelectedTransactions, setSelectedTransactions, removeSelectedTransactions } =
  selectedTransactionsSlice.actions;

export default selectedTransactionsSlice.reducer;
