import React, { FC, ReactElement } from 'react';

import { Children } from 'types/childrenTypes';

interface WithSkeletonLoaderProps {
  children: Children;
  isLoading: boolean;
  skeletonLoader: ReactElement;
}

const WithSkeletonLoader: FC<WithSkeletonLoaderProps> = (props): ReactElement => {
  const { children, isLoading, skeletonLoader } = props;

  if (isLoading) {
    return skeletonLoader;
  }

  return <>{children}</>;
};

export default WithSkeletonLoader;
