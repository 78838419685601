import React, { ReactElement, memo } from 'react';

import { useV2GetCurrenciesLogoQuery } from 'services/v2-overview-service/overviewService';

const CryptoLogos = ({ type, className = '' }: { type?: string; className?: string }) => {
  const { data } = useV2GetCurrenciesLogoQuery();

  const IMAGES = data?.reduce((prevValue, currValue) => {
    return {
      ...prevValue,
      [currValue.name.toUpperCase()]: (
        <img src={currValue.logo_url} alt={`${currValue.name} logo`} className={className} />
      ),
    };
  }, {}) as { [key: string]: ReactElement } | undefined;

  if (!type || !IMAGES) {
    return <></>;
  }

  return IMAGES[type.toUpperCase()] || <></>;
};

export default memo(CryptoLogos);
