import React, { FC, ReactElement, memo } from 'react';

import Modal from 'components/modal';
import Typography from 'components/typography';
import TgAuth from 'containers/modal-settings/tg-auth';

import { ReactComponent as CrossIcon } from 'assets/icons/cross-icon.svg';

import s from './ModalSettings.module.scss';
import Currency from './currency';
import Theme from './theme';
import Timezone from './time-zone';

interface ModalSettingsProps {
  isModalOpened: boolean;
  openCloseModalHandler: () => void;
}

type ComponentType = { label: string; component: ReactElement };

const COMPONENTS: ComponentType[] = [
  {
    label: 'Fiat currency',
    component: <Currency />,
  },
  { label: 'Time zone', component: <Timezone /> },
  { label: 'Theme', component: <Theme /> },
];

const ModalSettings: FC<ModalSettingsProps> = (props) => {
  const { isModalOpened, openCloseModalHandler } = props;

  const renderComponents = ({ label, component }: ComponentType) => (
    <div key={`component__${label}`} className={s.singleSettingsWrapper}>
      <Typography>{label}</Typography>
      {component}
    </div>
  );

  return (
    <Modal open={isModalOpened} handleClose={openCloseModalHandler} width={400}>
      <div className={s.modalWrapper}>
        <button className={s.closeIcon} onClick={openCloseModalHandler}>
          <CrossIcon />
        </button>
        <Typography size="20" weight="600" className={s.header}>
          Settings
        </Typography>
        <div className={s.settings}>
          {COMPONENTS.map(renderComponents)}
          <TgAuth />
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalSettings);
