import React, { ReactElement } from 'react';

import useHotWalletTableColumns from 'pages/v2/utils/useHotWalletTableColumns.hook';

import { useLazyV2GetHotWalletsQuery } from 'services/v2-overview-service/overviewService';

import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';

import s from './HotWalletsTableView.module.scss';

const HotWalletsTableView = (): ReactElement => {
  const [
    getHotWallets,
    { isFetching: transactionsIsLoading, isError: transactionsIsError, data: hotWalletsData },
  ] = useLazyV2GetHotWalletsQuery();

  const columns = useHotWalletTableColumns({
    hotWallets: hotWalletsData?.transactions,
  });

  return (
    <TableWithHandlers
      tab={TabTypesEnum.GLOBAL_HOT_WALLETS}
      className={s.tableWrapper}
      columns={columns}
      items={hotWalletsData?.transactions}
      totalCount={hotWalletsData?.pagination.count}
      getData={getHotWallets}
      isLoading={transactionsIsLoading}
      isError={transactionsIsError}
    />
  );
};

export default HotWalletsTableView;
