import React, { FC, ReactElement } from 'react';

import cx from 'classnames';

import Tooltip from 'components/tooltip';
import Typography from 'components/typography';
import FilterComponentConstructor from 'containers/filter-component-constructor';
import { GroupedByFirstLetterList } from 'containers/filter-wrappers';

import { FilterComponent, FilterSelectTypes } from 'types/filtersTypes';

import { ReactComponent as ArrowRight } from 'assets/icons/arrow-rigth-icon.svg';

import s from './FiltersWrapper.module.scss';

interface FiltersWrapperProps {
  children: ReactElement[];
  filterType: FilterSelectTypes;
  filterKey: string;
  isOpened: boolean;
}

interface ElementToRender {
  filterKey: string;
  filterType: string;
  mainKey: string;
  component: FilterComponent;
}

const CHILDREN_LIMIT = 5;
const INNER_STYLE = { padding: 0, borderRadius: 6 };

const FiltersWrapper: FC<FiltersWrapperProps> = (props) => {
  const { children, filterKey, filterType, isOpened } = props;

  const overlay = (
    <GroupedByFirstLetterList filterKey={filterKey} filterType={filterType} className={s.overlay}>
      {children}
    </GroupedByFirstLetterList>
  );

  const firstItemsToRender = children.slice(0, 4);
  const renderFiltersContent = (element: ReactElement<ElementToRender>) => {
    const component: FilterComponent = element?.props?.component || {};

    return (
      <FilterComponentConstructor
        filterType={filterType}
        key={component.key}
        mainKey={component.key}
        component={component}
        filterKey={filterKey}
      />
    );
  };

  if (Array.isArray(children) && children.length > CHILDREN_LIMIT) {
    return (
      <div className={cx({ [s.visible]: isOpened, [s.hidden]: !isOpened })}>
        {firstItemsToRender.map(renderFiltersContent)}
        <Tooltip
          showArrow={false}
          overlayInnerStyle={INNER_STYLE}
          trigger={['click']}
          overlayClassName={s.overlay}
          align={{ offset: [-210, 40] }}
          overlay={overlay}
          placement="topRight">
          <button className={s.viewAllButton}>
            <Typography>View all</Typography> <ArrowRight />
          </button>
        </Tooltip>
      </div>
    );
  }

  return <div className={cx({ [s.visible]: isOpened, [s.hidden]: !isOpened })}>{children}</div>;
};

export default FiltersWrapper;
