import React, { useState } from 'react';

import cn from 'classnames';

import { userCurrencySelector } from 'selectors/userSlice.selectors';
import { useAppSelector } from 'store';

import Dropdown from 'components/dropdown';
import Typography from 'components/typography';

import { ReactComponent as ArrowDown } from 'assets/icons/dropdown-arrow-down-icon.svg';

import useCurrencyDropdownItems from 'utils/useCurrencyDropdownItems.hook';

import s from './Currency.module.scss';

const Currency = () => {
  const currentUserCurrency = useAppSelector(userCurrencySelector);

  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);

  const currencyToShow = currentUserCurrency
    ? `${currentUserCurrency?.symbol},${currentUserCurrency?.currency}`
    : '';

  const { data: currencyDropdownItems, handleItemClick } = useCurrencyDropdownItems({
    setIsOverlayOpened,
  });

  return (
    <Dropdown
      align={{ offset: [120, 0] }}
      overlayClassName={s.overlay}
      handleItemClick={handleItemClick}
      open={isOverlayOpened}
      items={currencyDropdownItems}
      onOpenChange={setIsOverlayOpened}
      placement="bottom">
      <button className={cn(s.dropdownButton, { [s.active]: isOverlayOpened })}>
        <Typography>
          {currencyToShow}
          <ArrowDown />
        </Typography>
      </button>
    </Dropdown>
  );
};

export default Currency;
