import React, { ReactElement } from 'react';

import { useLazyGetTransactionsQuery } from 'services/overview-service/overviewService';

import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';

import s from './TransactionsTableView.module.scss';
import useTableColumns from './useTableColumns.hook';

const TransactionsTableView = (): ReactElement => {
  const [
    getTransactions,
    { isFetching: transactionsIsLoading, isError: transactionsIsError, data: transactionsData },
  ] = useLazyGetTransactionsQuery({});

  const columns = useTableColumns({
    transactions: transactionsData?.transactions,
  });

  return (
    <TableWithHandlers
      tab={TabTypesEnum.GLOBAL_TRANSACTIONS}
      className={s.tableWrapper}
      columns={columns}
      items={transactionsData?.transactions}
      totalCount={transactionsData?.pagination.count}
      getData={getTransactions}
      isLoading={transactionsIsLoading}
      isError={transactionsIsError}
    />
  );
};

export default TransactionsTableView;
