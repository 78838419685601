import React, { useCallback } from 'react';

import Switch from 'components/switch';
import Typography from 'components/typography';

import { ThemeTypes } from 'types/theme';

import useTheme from 'utils/useTheme.hook';

import s from './Theme.module.scss';

const Theme = () => {
  const [theme, setTheme] = useTheme();

  const handleSwitchChange = useCallback(() => {
    const newTheme =
      theme === ThemeTypes.DARK_THEME ? ThemeTypes.LIGHT_THEME : ThemeTypes.DARK_THEME;
    setTheme(newTheme);
  }, [setTheme, theme]);

  return (
    <div className={s.wrapper}>
      <Typography>Dark mode</Typography>
      <Switch checked={theme === ThemeTypes.DARK_THEME} onChange={handleSwitchChange} />
    </div>
  );
};

export default Theme;
