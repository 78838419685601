import React, { useCallback, useMemo } from 'react';

import cx from 'classnames';

import { selectedTransactionsSelector } from 'selectors/selectedTransactionsSlice.selectors';
import { userCurrencySelector } from 'selectors/userSlice.selectors';
import { useV2SetHotWalletStatusMutation } from 'services/v2-overview-service/overviewService';
import { GetHotWalletsResponseType } from 'services/v2-overview-service/overviewService.types';
import {
  removeSelectedTransactions,
  setSelectedTransactions,
} from 'slices/selectedTransactionsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import Checkbox from 'components/checkbox';
import { CheckboxChangeEvent } from 'components/checkbox/checkbox';
import CopyWrapper from 'containers/copy-wrapper';
import {
  CheckedStatus,
  Company,
  ConvertedFrom,
  Currency,
  Date,
  Direction,
  ExchangeRate,
  FromAmount,
  Hash,
  RowNumber,
  TransactionStatus,
} from 'containers/row-renderers';
import AddressHashWithCopy from 'containers/row-renderers/address-hash-with-copy';
import { transformHash } from 'containers/row-renderers/utils';

import { getHotWalletWithIdRoute } from 'constants/routes';
import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  TransactionColumnType,
  ValueRowType,
} from 'types/tableTypes';
import { HotWalletTransactionType, TransactionAddressType } from 'types/transactionTypes';

import useQueryParamsHook from 'utils/useQueryParams.hook';

import s from './UseHotWalletTableColumns.module.scss';

const getId = (trans: { id: string | number }) => trans.id;

interface UseHotWalletTableColumnsProps {
  hotWallets?: GetHotWalletsResponseType['transactions'];
  removedColumns?: string[];
  merchantAddressRedirectRoute?: (id: string | number) => string;
}

const useHotWalletTableColumns = (
  props: UseHotWalletTableColumnsProps,
): TransactionColumnType[] => {
  const { hotWallets, removedColumns, merchantAddressRedirectRoute } = props;

  const dispatch = useAppDispatch();
  const userCurrency = useAppSelector(userCurrencySelector);
  const selectedTransactions = useAppSelector(selectedTransactionsSelector);

  const { paramsObject } = useQueryParamsHook();

  const handleSelectAll = useCallback(
    (event: CheckboxChangeEvent) => {
      const ids = hotWallets?.map(getId);

      if (event.target.checked) {
        dispatch(setSelectedTransactions(ids));
      } else {
        dispatch(removeSelectedTransactions(ids));
      }
    },
    [dispatch, hotWallets],
  );

  const handleSingleCheck = useCallback(
    (id: number) => (event: CheckboxChangeEvent) => {
      if (event.target.checked) {
        dispatch(setSelectedTransactions(id));
      } else {
        dispatch(removeSelectedTransactions(id));
      }
    },
    [dispatch],
  );

  const checkSelected = (trans: HotWalletTransactionType) =>
    selectedTransactions.includes(trans.id);

  const areAllTransactionsSelected = !!hotWallets?.length && !!hotWallets?.every(checkSelected);

  const checkboxRenderer = useCallback(
    (id: ValueRowType) => {
      const isChecked = selectedTransactions.includes(id as number);

      return <CheckedStatus isChecked={isChecked} onChange={handleSingleCheck(id as number)} />;
    },
    [handleSingleCheck, selectedTransactions],
  );

  const hotWalletAddressRenderer = useCallback(
    (
      value: ValueRowType,
      otherProps: RendererProps<GetTableDataArgsType, unknown, PossibleTableItemsType>,
    ) => {
      const typedValue: TransactionAddressType | undefined = value as unknown as
        | TransactionAddressType
        | undefined;
      const handleInvoiceClick = () => {
        if (typedValue?.details_available) {
          const routeGenerator = merchantAddressRedirectRoute
            ? merchantAddressRedirectRoute
            : getHotWalletWithIdRoute;

          otherProps.navigateWithParams(routeGenerator(typedValue?.id));
        }
      };

      return (
        <CopyWrapper
          valueToCopy={typedValue?.address || ''}
          className={cx({
            [s.link]: !!typedValue?.details_available,
          })}
          handleClick={handleInvoiceClick}>
          {transformHash(typedValue?.address)}
        </CopyWrapper>
      );
    },
    [merchantAddressRedirectRoute],
  );

  const columns = useMemo(() => {
    const defaultArray = [
      { title: '#', key: 'number', renderer: RowNumber, className: s.number },
      {
        className: s.checkedStatus,
        title: (
          <div>
            <Checkbox isChecked={areAllTransactionsSelected} onChange={handleSelectAll} />
          </div>
        ),
        key: 'id',
        renderer: checkboxRenderer,
        paramsObject,
      },
      {
        title: 'St',
        key: 'status',
        renderer: TransactionStatus,
        setStatusMutation: useV2SetHotWalletStatusMutation,
        paramsObject,
      },
      {
        title: 'Hot wallet address',
        key: 'merchant_address',
        renderer: hotWalletAddressRenderer,
        sortable: true,
        paramsObject,
      },
      {
        title: 'Interaction with',
        key: 'address',
        renderer: AddressHashWithCopy,
        sortable: true,
      },
      { title: 'Directions', key: 'direction', renderer: Direction, sortable: true },
      { title: 'Company', key: 'company_name', sortable: true, renderer: Company },
      { title: 'Hash', key: 'hash', renderer: Hash },
      { title: 'Transaction date', key: 'date', renderer: Date, sortable: true },
      {
        title: 'Exch rate',
        key: 'exchange_rate',
        renderer: ExchangeRate,
        sortable: true,
      },
      { title: 'Currency', key: 'currency', renderer: Currency, sortable: true },
      { title: 'Token amount', key: 'amount', renderer: FromAmount, sortable: true },
      {
        title: 'Fiat value',
        key: 'converted_amount',
        renderer: ConvertedFrom,
        sortable: true,
        userCurrency,
      },
    ];

    return removedColumns?.length
      ? defaultArray.filter((el) => !removedColumns.includes(el.key))
      : defaultArray;
  }, [
    areAllTransactionsSelected,
    checkboxRenderer,
    handleSelectAll,
    hotWalletAddressRenderer,
    paramsObject,
    removedColumns,
    userCurrency,
  ]);

  return columns;
};

export default useHotWalletTableColumns;
