export enum TabTypesEnum {
  ALL = 'all',
  GLOBAL_TRANSACTIONS = 'global_transactions',
  COMPANY_TRANSACTIONS = 'company_transactions',
  COMPANIES = 'companies',
  WALLETS = 'wallets',
  GLOBAL_INVOICES = 'global_invoices',
  GLOBAL_HOT_WALLETS = 'global_hot_wallets',
  COMPANY_INVOICES = 'company_invoices',
  COMPANY_HOT_WALLETS = 'company_hot_wallets',
  SCORING = 'scoring',
}

export type TabTypes = Partial<Record<TabTypesEnum, string>>;
