import React, {
  ReactElement,
  ReactEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import ScoringRequestModal from 'pages/v2/risk-score/scoring-request-modal';
import { MODAL_TYPE } from 'pages/v2/risk-score/scoring-request-modal/scoringRequestModal';

import {
  useLazyV2GetScoringRequestsQuery,
  useV2SetScoringRequestStatusMutation,
} from 'services/v2-overview-service/overviewService';

import TableWithHandlers from 'containers/table-with-handlers';

import { ParamsObjectType } from 'types/paramsTypes';
import { TabTypesEnum } from 'types/tabTypes';

import useQueryParamsHook from 'utils/useQueryParams.hook';

import s from './ScoringRequestTableView.module.scss';
import useTableColumns from './useTableColumns.hook';

const ScoringRequestTableView = (): ReactElement => {
  const count = useRef<number>(0);

  const [modalData, setModalData] = useState<{ type: MODAL_TYPE; id: string } | null>(null);

  const { paramsObject, setQueryParams } = useQueryParamsHook();

  useEffect(() => {
    if (count.current === 0) {
      const initialParams = {
        ...paramsObject,
      } as ParamsObjectType;

      if (initialParams.order && Array.isArray(initialParams.order)) {
        initialParams.order.push({ created_date: 'desc' });
      } else {
        initialParams.order = [{ created_date: 'desc' }];
      }

      setQueryParams(initialParams);
      count.current = 1;
    }
  }, [paramsObject, setQueryParams]);

  const [
    getScoringRequests,
    {
      isLoading: scoringRequestsIsLoading,
      isError: scoringRequestsIsError,
      data: scoringRequestsData,
    },
  ] = useLazyV2GetScoringRequestsQuery();

  const [setScoringStatus, { isLoading: setScoringRequestsIsLoading }] =
    useV2SetScoringRequestStatusMutation();

  const openCloseModalHandler = useCallback(
    (modalType?: string | ReactEventHandler, id?: string): void => {
      const modalDataToSet = id ? { type: modalType as MODAL_TYPE, id } : null;
      setModalData(modalDataToSet);
    },
    [],
  );

  const columns = useTableColumns({ openCloseModalHandler });

  return (
    <>
      <TableWithHandlers
        colorizeRow={false}
        tab={TabTypesEnum.SCORING}
        className={s.tableWrapper}
        columns={columns}
        items={scoringRequestsData?.scoring_requests}
        totalCount={scoringRequestsData?.pagination.count}
        getData={getScoringRequests}
        isLoading={scoringRequestsIsLoading || setScoringRequestsIsLoading}
        isError={scoringRequestsIsError}
      />
      <ScoringRequestModal
        modalData={modalData}
        openCloseModalHandler={openCloseModalHandler}
        setScoringStatus={setScoringStatus}
      />
    </>
  );
};

export default ScoringRequestTableView;
