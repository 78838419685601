import React, { FC, memo, useState } from 'react';

import cn from 'classnames';

import Dropdown from 'components/dropdown';
import { DropdownProps } from 'components/dropdown/dropdown';
import Typography from 'components/typography';

import { ReactComponent as ArrowDown } from 'assets/icons/dropdown-arrow-down-icon.svg';

import s from './TableViewAllFilterTags.module.scss';

interface TableViewAllFilterTagsProps {
  items: DropdownProps['items'];
}

const TableViewAllFilterTags: FC<TableViewAllFilterTagsProps> = (props) => {
  const { items } = props;

  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);

  return (
    <Dropdown
      overlayClassName={s.overlay}
      open={isOverlayOpened}
      items={items}
      onOpenChange={setIsOverlayOpened}
      placement="bottomLeft">
      <button className={cn(s.dropdownButton, { [s.dropdownButtonActive]: isOverlayOpened })}>
        <Typography size="13">
          View all
          <ArrowDown />
        </Typography>
      </button>
    </Dropdown>
  );
};

export default memo(TableViewAllFilterTags);
