import React from 'react';

import Skeleton from 'components/skeleton';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up-icon.svg';

import s from './Sidebar.module.scss';

const ROWS_COUNT = Array.from(Array(3), (_, id) => ({ id }));

const checkboxRowsRenderer = ({ id }: { id: number }) => (
  <div className={s.skeletonElementWrapper} key={`--${id}`}>
    <Skeleton shape="square" style={{ height: 16, width: 16, borderRadius: 4 }} type="Button" />
    <Skeleton style={{ height: 13, width: 130 }} />
  </div>
);

const simpleRowsRenderer = ({ id }: { id: number }) => (
  <div className={s.skeletonElementWrapper} key={`__${id}`}>
    <Skeleton style={{ height: 13, width: 160 }} />
  </div>
);

const SidebarSkeleton = () => {
  return (
    <>
      <div>
        <div className={s.skeletonLabelWrapper}>
          <Skeleton style={{ height: 13, width: 120 }} />
          <ArrowUp />
        </div>
        {ROWS_COUNT.map(checkboxRowsRenderer)}
      </div>
      <div>
        <div className={s.skeletonLabelWrapper}>
          <Skeleton style={{ height: 13, width: 120 }} />
          <ArrowUp />
        </div>
        {ROWS_COUNT.map(simpleRowsRenderer)}
      </div>
      <div>
        <div className={s.skeletonLabelWrapper}>
          <Skeleton style={{ height: 13, width: 120 }} />
          <ArrowUp />
        </div>
        <div className={s.skeletonElementWrapper}>
          <Skeleton shape="square" style={{ height: 37.4, width: 80, borderRadius: 4 }} />
          -
          <Skeleton style={{ height: 37.4, width: 80, borderRadius: 4 }} />
        </div>
        {ROWS_COUNT.map(simpleRowsRenderer)}
      </div>
    </>
  );
};

export default SidebarSkeleton;
