import React, { FC, ReactElement, memo, useCallback } from 'react';

import { Pagination as PaginationAntd } from 'antd';
import cx from 'classnames';

import 'antd/lib/pagination/style/css';

import s from './Pagination.module.scss';

interface PaginationProps {
  total?: number;
  current?: number;
  className?: string;
  defaultPageSize?: number;
  pageSize?: number;
  onChange?: (page: number, pageSize: number) => void;
}

const Pagination: FC<PaginationProps> = (props): ReactElement => {
  const {
    total = 0,
    defaultPageSize = 10,
    pageSize = 10,
    className = '',
    onChange,
    current,
  } = props;

  const handleChange = useCallback(
    (newPage: number, newPageSize: number) => {
      if (onChange) {
        onChange(newPage, newPageSize);
      }
    },
    [onChange],
  );

  if (!pageSize) {
    return <></>;
  }

  return (
    <PaginationAntd
      current={current}
      className={cx({ [s.wrapper]: true, [className]: className })}
      total={total}
      defaultPageSize={defaultPageSize}
      pageSize={pageSize}
      onChange={handleChange}
      showSizeChanger={false}
    />
  );
};

export default memo(Pagination);
