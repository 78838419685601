import React, { FC, MouseEventHandler } from 'react';

import cx from 'classnames';

import Checkbox from 'components/checkbox';

import { ComponentProps } from '../filterComponentConstructor';
import s from './FilterCheckbox.module.scss';

const FilterCheckbox: FC<ComponentProps> = (props) => {
  const { label, isSelected, handleChange, className } = props;

  return (
    <button
      className={cx({ [s.wrapper]: true, [s.isSelected]: isSelected }, className)}
      onClick={handleChange as MouseEventHandler<HTMLButtonElement>}>
      <Checkbox isChecked={isSelected}>{label}</Checkbox>
    </button>
  );
};

export default FilterCheckbox;
