import React, { ReactElement, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import useInvoiceTableColumns from 'pages/v2/utils/useInvoiceTableColumns.hook';

import { useLazyV2GetInvoicesQuery } from 'services/v2-overview-service/overviewService';
import { GetInvoicesPropsType } from 'services/v2-overview-service/overviewService.types';

import TableWithHandlers from 'containers/table-with-handlers';

import { getCompanyInvoiceWithIdRoute } from 'constants/routes';
import { TabTypesEnum } from 'types/tabTypes';

import s from './CompanyInvoicesTableView.module.scss';

const CompanyInvoicesTableView = (): ReactElement => {
  const params = useParams<{ companyId: string }>();
  console.log(params);
  const [
    getInvoices,
    { isFetching: invoicesIsLoading, isError: invoicesIsError, data: invoicesData },
  ] = useLazyV2GetInvoicesQuery();

  const getData = useCallback(
    (args: Omit<GetInvoicesPropsType, 'company'>) => {
      const updatedProps = { ...args, company: { id: params.companyId } } as GetInvoicesPropsType;

      return getInvoices(updatedProps);
    },
    [getInvoices, params.companyId],
  );

  const columns = useInvoiceTableColumns({
    removedColumns: ['company_name'],
    invoices: invoicesData?.transactions,
    merchantAddressRedirectRoute: (invoiceId: string | number) =>
      getCompanyInvoiceWithIdRoute(params.companyId!, invoiceId),
  });

  return (
    <TableWithHandlers
      tab={TabTypesEnum.COMPANY_INVOICES}
      className={s.tableWrapper}
      columns={columns}
      items={invoicesData?.transactions}
      totalCount={invoicesData?.pagination.count}
      getData={getData}
      isLoading={invoicesIsLoading}
      isError={invoicesIsError}
    />
  );
};

export default CompanyInvoicesTableView;
