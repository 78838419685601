import React, { FC, ReactElement, memo, useCallback, useMemo, useState } from 'react';

import cx from 'classnames';

import Dropdown from 'components/dropdown';
import Typography from 'components/typography';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'constants/pagination';

import { ReactComponent as ArrowDown } from 'assets/icons/dropdown-arrow-down-icon.svg';

import s from './PageSize.module.scss';

interface PageSizeProps {
  label?: string;
  current?: number | string;
  pageSizes?: (number | string)[];
  onChange?: (pageSize: number) => void;
}

const PageSize: FC<PageSizeProps> = (props): ReactElement => {
  const {
    current = DEFAULT_PAGE_SIZE,
    pageSizes = DEFAULT_PAGE_SIZE_OPTIONS,
    onChange,
    label,
  } = props;

  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);
  const handleOverlayOpening = useCallback(() => {
    setIsOverlayOpened((prevState) => !prevState);
  }, []);

  const renderPageSizes = useCallback(
    (pageSize: string | number) => ({
      label: (
        <Typography
          size="13"
          className={cx({ [s.activeOption]: Number(pageSize) === Number(current) })}>
          {pageSize}
        </Typography>
      ),
      key: pageSize,
    }),
    [current],
  );

  const dropdownItems = useMemo(() => {
    return pageSizes.map(renderPageSizes);
  }, [pageSizes, renderPageSizes]);

  const handleItemClick = (event: { key: string }) => {
    if (onChange) {
      onChange(Number(event.key));
      handleOverlayOpening();
    }
  };

  return (
    <div className={s.wrapper}>
      {!!label && <span>{label}</span>}
      {current && (
        <Dropdown
          overlayClassName={s.overlay}
          placement="topRight"
          open={isOverlayOpened}
          onOpenChange={handleOverlayOpening}
          handleItemClick={handleItemClick}
          items={dropdownItems}>
          <div className={cx(s.dropdownContent, { [s.arrowActive]: isOverlayOpened })}>
            <button>{current}</button>
            <ArrowDown />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default memo(PageSize);
