import React from 'react';

import Layout from 'components/layout';
import AppContent from 'containers/app-content';
import AppHeader from 'containers/app-header';
import AppSidebar from 'containers/app-sidebar';

import s from './AppLayout.module.scss';

const AppLayout = () => {
  return (
    <div className={s.wrapper}>
      <AppHeader />
      <Layout className={s.contentWrapper}>
        <AppSidebar />
        <AppContent />
      </Layout>
    </div>
  );
};

export default AppLayout;
