import React from 'react';

import { Navigate } from 'react-router-dom';

import Company from 'pages/v1/company';
import CompanyWalletTransactionsView from 'pages/v1/company/company-wallet-transactions-view';
import Login from 'pages/v1/login';
import RealTimeOverview from 'pages/v1/real-time-overview';
import V2Company from 'pages/v2/company';
import CompanyHotWalletDetailsTableView from 'pages/v2/company/company-hot-wallet-details-table-view';
import CompanyInvoiceDetailsTableView from 'pages/v2/company/company-invoices-details-table-view';
import HotWalletDetails from 'pages/v2/hot-wallet-details';
import InvoicesDetails from 'pages/v2/invoice-details';
import V2RealTimeOverview from 'pages/v2/real-time-overview';
import V2RiskScore from 'pages/v2/risk-score';

import { RouteType } from 'types/routeTypes';

export const COMPANY_ROUTE = '/company';

export const COMPANY_WITH_ID_ROUTE = `${COMPANY_ROUTE}/:companyId`;
export const getCompanyWithIdRoute = (id: string | number): string => `${COMPANY_ROUTE}/${id}`;

export const INVOICE_ROUTE = '/invoice';
export const INVOICE_WITH_ID_ROUTE = `${INVOICE_ROUTE}/:invoiceId`;
export const getInvoiceWithIdRoute = (id: string | number): string => `${INVOICE_ROUTE}/${id}`;
export const COMPANY_INVOICE_ROUTE = `${COMPANY_WITH_ID_ROUTE}/invoice`;
export const COMPANY_INVOICE_WITH_ID_ROUTE = `${COMPANY_INVOICE_ROUTE}/:invoiceId`;
export const getCompanyInvoiceWithIdRoute = (
  companyId: string | number,
  invoiceId: string | number,
): string => `${getCompanyWithIdRoute(companyId)}/invoice/${invoiceId}`;

export const HOT_WALLET_ROUTE = '/hot-wallet';
export const HOT_WALLET_WITH_ID_ROUTE = `${HOT_WALLET_ROUTE}/:hotWalletId`;
export const getHotWalletWithIdRoute = (id: string | number): string => `${HOT_WALLET_ROUTE}/${id}`;
export const COMPANY_HOT_WALLET_ROUTE = `${COMPANY_WITH_ID_ROUTE}/hot-wallet`;
export const COMPANY_HOT_WALLET_WITH_ID_ROUTE = `${COMPANY_HOT_WALLET_ROUTE}/:hotWalletId`;
export const getCompanyHotWalletWithIdRoute = (
  companyId: string | number,
  hotWalletId: string | number,
): string => `${getCompanyWithIdRoute(companyId)}/hot-wallet/${hotWalletId}`;

export const WALLET_ROUTE = `${COMPANY_WITH_ID_ROUTE}/wallet`;

export const WALLET_WITH_ID_ROUTE = `${WALLET_ROUTE}/:walletId`;
export const getCompanyWalletWithIdRoute = (
  companyId: string | number,
  walletId: string | number,
): string => `${getCompanyWithIdRoute(companyId)}/wallet/${walletId}`;

export const REAL_TIME_OVERVIEW_ROUTE = '/real-time-overview';
export const RISK_SCORE_ROUTE = '/risk-score';

export const LOGIN_ROUTE = '/login';

export const UNKNOWN_ROUTE = '*';

export const NON_AUTH_ROUTES: RouteType[] = [
  { path: LOGIN_ROUTE, element: <Login /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={LOGIN_ROUTE} /> },
];

export const AUTH_ROUTES: RouteType[] = [
  { path: COMPANY_WITH_ID_ROUTE, element: <Company /> },
  { path: WALLET_WITH_ID_ROUTE, element: <CompanyWalletTransactionsView /> },
  { path: REAL_TIME_OVERVIEW_ROUTE, element: <RealTimeOverview /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={REAL_TIME_OVERVIEW_ROUTE} /> },
];

export const V2_AUTH_ROUTES: RouteType[] = [
  { path: COMPANY_HOT_WALLET_WITH_ID_ROUTE, element: <CompanyHotWalletDetailsTableView /> },
  { path: COMPANY_INVOICE_WITH_ID_ROUTE, element: <CompanyInvoiceDetailsTableView /> },
  { path: HOT_WALLET_WITH_ID_ROUTE, element: <HotWalletDetails /> },
  { path: INVOICE_WITH_ID_ROUTE, element: <InvoicesDetails /> },
  { path: COMPANY_WITH_ID_ROUTE, element: <V2Company /> },
  { path: REAL_TIME_OVERVIEW_ROUTE, element: <V2RealTimeOverview /> },
  { path: RISK_SCORE_ROUTE, element: <V2RiskScore /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={REAL_TIME_OVERVIEW_ROUTE} /> },
];
