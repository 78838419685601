import React, { FC, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import useHotWalletTableColumns from 'pages/v2/utils/useHotWalletTableColumns.hook';

import { useLazyV2GetHotWalletsQuery } from 'services/v2-overview-service/overviewService';
import { GetInvoicesPropsType } from 'services/v2-overview-service/overviewService.types';

import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';

import s from './HotWalletDetailsTableView.module.scss';

const HotWalletDetailsTableView: FC = () => {
  const params = useParams<{ hotWalletId: string }>();

  const [
    getHotWallets,
    { isFetching: hotWalletsIsLoading, isError: hotWalletsIsError, data: hotWalletsData },
  ] = useLazyV2GetHotWalletsQuery();

  const columns = useHotWalletTableColumns({
    removedColumns: ['merchant_address'],
    hotWallets: hotWalletsData?.transactions,
  });

  const getData = useCallback(
    (args: Omit<GetInvoicesPropsType, 'companyId'>) => {
      const updatedProps = {
        ...args,
        address: { id: Number(params.hotWalletId) },
      } as GetInvoicesPropsType;

      return getHotWallets(updatedProps);
    },
    [getHotWallets, params.hotWalletId],
  );

  return (
    <TableWithHandlers
      tab={TabTypesEnum.GLOBAL_INVOICES}
      className={s.tableWrapper}
      columns={columns}
      items={hotWalletsData?.transactions}
      totalCount={hotWalletsData?.pagination.count}
      getData={getData}
      isLoading={hotWalletsIsLoading}
      isError={hotWalletsIsError}
    />
  );
};

export default HotWalletDetailsTableView;
