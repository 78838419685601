import React, { FC, ReactElement, memo, useState } from 'react';

import { Switch as SwitchAntd } from 'antd';
import cx from 'classnames';

import { Primitive } from 'types/baseTypes';

import 'antd/lib/switch/style/css';

import s from './Switch.module.scss';

interface SwitchProps {
  disabled?: boolean;
  loading?: boolean;
  onChange: (isChecked: boolean) => void;
  checked: boolean;
  className?: string;
  labels?: [Primitive, Primitive];
}

const Switch: FC<SwitchProps> = (props): ReactElement => {
  const { onChange, className = '', labels, checked, loading = false, disabled = false } = props;

  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const [firstLabel, secondLabel] = labels || [];

  return (
    <div className={s.wrapper}>
      {firstLabel && <span className={cx({ [s.isChecked]: !isChecked })}>{firstLabel}</span>}
      <SwitchAntd
        disabled={disabled}
        loading={loading}
        checked={checked}
        onChange={(isChecked) => {
          onChange(isChecked);
          setIsChecked(isChecked);
        }}
        className={cx({
          [className]: className,
          [s.switcherChecked]: isChecked,
        })}
      />
      {secondLabel && <span className={cx({ [s.isChecked]: isChecked })}>{secondLabel}</span>}
    </div>
  );
};

export default memo(Switch);
