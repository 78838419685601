import React, { useState } from 'react';

import cx from 'classnames';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import Dropdown from 'components/dropdown';
import Typography from 'components/typography';

import { REAL_TIME_OVERVIEW_ROUTE, RISK_SCORE_ROUTE } from 'constants/routes';
import {
  WATCH_BLOCK_VERSION_1,
  WATCH_BLOCK_VERSION_2,
  WATCH_BLOCK_VERSION_LOCAL_KEY,
} from 'constants/version';

import { ReactComponent as CheckIconInverted } from 'assets/icons/check-icon-inverted.svg';
import { ReactComponent as Donut } from 'assets/icons/donut-icon.svg';
import { ReactComponent as LogoV1 } from 'assets/icons/logo-v1-icon.svg';
import { ReactComponent as LogoV2 } from 'assets/icons/logo-v2-icon.svg';
import { ReactComponent as Shield } from 'assets/icons/shield-icon.svg';

import useLocalStorage from 'utils/useLocalStorage.hook';

import s from './LinksGroup.module.scss';

const LinksGroup = () => {
  const navigate = useNavigate();

  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);

  const [currentVersion, setCurrentVersion] = useLocalStorage(
    WATCH_BLOCK_VERSION_LOCAL_KEY,
    WATCH_BLOCK_VERSION_1,
  );
  const isV1 = currentVersion === WATCH_BLOCK_VERSION_1;

  const items = [
    isV1
      ? { key: WATCH_BLOCK_VERSION_2, label: <Typography>Switch to ver.2</Typography> }
      : { key: WATCH_BLOCK_VERSION_1, label: <Typography>Switch to ver.1</Typography> },
  ];

  const Logo = isV1 ? LogoV1 : LogoV2;
  const handleItemClick = (event: { key: string }) => {
    setIsOverlayOpened(false);
    setCurrentVersion(event.key);
    navigate(REAL_TIME_OVERVIEW_ROUTE);
  };

  return (
    <div className={s.leftGroup}>
      <div className={s.logoWithActionButton}>
        <Link to={REAL_TIME_OVERVIEW_ROUTE}>
          <Logo className={s.logo} />
        </Link>
        <Dropdown
          align={{ offset: [-120, 4] }}
          open={isOverlayOpened}
          onOpenChange={setIsOverlayOpened}
          handleItemClick={handleItemClick}
          trigger={['click']}
          items={items}>
          <button>
            <CheckIconInverted />
          </button>
        </Dropdown>
      </div>
      <div className={s.leftLinkGroup}>
        <NavLink
          to={REAL_TIME_OVERVIEW_ROUTE}
          className={({ isActive }) => cx(isActive ? s.activeLink : s.nonActiveLink)}>
          <Donut />
          <Typography size="15" weight="600">
            Overview
          </Typography>
        </NavLink>
        {!isV1 && (
          <NavLink
            to={RISK_SCORE_ROUTE}
            className={({ isActive }) => cx(isActive ? s.activeLink : s.nonActiveLink)}>
            <Shield />
            <Typography size="15" weight="600">
              Risk Score
            </Typography>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default LinksGroup;
