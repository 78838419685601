import React, { FC } from 'react';

import cn from 'classnames';

import Button from 'components/button';
import Modal from 'components/modal';
import Typography from 'components/typography';

import { SCORING_STATUS_ENUM } from 'types/transactionTypes';

import s from './ScoringRequestModal.module.scss';

export enum MODAL_TYPE {
  APPROVE_KEY = 'APPROVE_KEY',
  DECLINE_KEY = 'DECLINE_KEY',
}

interface ScoringRequestModalProps {
  modalData: { type: MODAL_TYPE; id: string } | null;
  openCloseModalHandler: () => void;
  setScoringStatus: (props: { request_id: string; status: SCORING_STATUS_ENUM }) => void;
}

const ScoringRequestModal: FC<ScoringRequestModalProps> = (props) => {
  const { modalData, openCloseModalHandler, setScoringStatus } = props;
  const { type, id } = modalData || {};

  const label = type === MODAL_TYPE.APPROVE_KEY ? 'Approve' : 'Decline';
  const status =
    type === MODAL_TYPE.APPROVE_KEY ? SCORING_STATUS_ENUM.APPROVED : SCORING_STATUS_ENUM.DECLINED;

  const handleSetScoring = () => {
    setScoringStatus({ request_id: id!, status });
    openCloseModalHandler();
  };

  return (
    <Modal
      open={!!modalData?.id}
      handleClose={openCloseModalHandler}
      width={400}
      className={s.wrapper}>
      <Typography
        size="18"
        weight="600"
        className={cn(
          s.modalLabel,
          type === MODAL_TYPE.APPROVE_KEY ? s.approveLabel : s.declineLabel,
        )}>
        {label}
      </Typography>
      <Typography size="13">{`Are you sure you want to ${label} this request?`}</Typography>
      <div
        className={cn(
          s.modalActions,
          type === MODAL_TYPE.APPROVE_KEY ? s.approveButton : s.declineButton,
        )}>
        <Button styleType="outlined" fullwidth onClick={openCloseModalHandler}>
          Cancel
        </Button>
        <Button fullwidth onClick={handleSetScoring}>{`Yes, ${label}`}</Button>
      </div>
    </Modal>
  );
};

export default ScoringRequestModal;
