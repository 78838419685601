export const convertTimeToLocale = (
  date: string | Date | null | undefined,
  config?: Intl.DateTimeFormatOptions,
): string => {
  if (!date) {
    return '';
  }

  return new Date(date)?.toLocaleTimeString(window.navigator.language, config);
};

export const convertDateToLocale = (
  date: string | Date | null | undefined,
  config?: Intl.DateTimeFormatOptions,
): string => {
  if (!date) {
    return '';
  }

  return new Date(date)?.toLocaleDateString(window.navigator.language, config);
};
