import React, { FC } from 'react';

import Checkbox, { CheckboxProps } from 'components/checkbox';

import s from './CheckedStatus.module.scss';

interface ViewStatusProps {
  isChecked: boolean;
  onChange: CheckboxProps['onChange'];
}

const CheckedStatus: FC<ViewStatusProps> = (props) => {
  const { isChecked, onChange } = props;

  return (
    <div className={s.wrapper}>
      <Checkbox isChecked={isChecked} onChange={onChange} />
    </div>
  );
};

export default CheckedStatus;
