import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import { userTimezoneOffsetSelector } from 'selectors/userSlice.selectors';
import { useChangeSettingsMutation } from 'services/user-service/userService';
import { useAppSelector } from 'store';

import Dropdown from 'components/dropdown';
import Typography from 'components/typography';
import CurrentTime from 'containers/current-time';

import timezones from 'constants/utcTimezones.json';

import { ReactComponent as CheckOutlinedIcon } from 'assets/icons/check-outlined-icon.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/dropdown-arrow-down-icon.svg';

import { formatTimezone } from 'utils/timezone';

import s from './Timezone.module.scss';

const timezonesSort = (a: number, b: number) => a - b;
const timezonesGetOffset = (e: { offset: number }) => e.offset;

const SORTED_UNIQUE_TIMEZONES = [...new Set(timezones.map(timezonesGetOffset).sort(timezonesSort))];

const Timezone = () => {
  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);
  const currentUserTimezone = useAppSelector(userTimezoneOffsetSelector);
  const formattedCurrentTimezone =
    currentUserTimezone && currentUserTimezone > 0
      ? `+${currentUserTimezone}`
      : currentUserTimezone;

  const [changeSettings] = useChangeSettingsMutation();

  const handleItemClick = useCallback(
    ({ key }: { key: string }) => {
      setIsOverlayOpened((prevState) => !prevState);

      if (currentUserTimezone !== Number(key)) {
        changeSettings({ default_timezone_offset: Number(key) });
      }
    },
    [changeSettings, currentUserTimezone],
  );

  const items = SORTED_UNIQUE_TIMEZONES.map((timezone) => {
    const isActive = timezone === Number(currentUserTimezone);

    return {
      label: (
        <Typography>
          {`UTC${formatTimezone(timezone, true)}:00`}
          {isActive && <CheckOutlinedIcon />}
        </Typography>
      ),
      key: timezone,
    };
  });

  return (
    <Dropdown
      align={{ offset: [96, 0] }}
      overlayClassName={s.overlay}
      items={items}
      handleItemClick={handleItemClick}
      open={isOverlayOpened}
      onOpenChange={setIsOverlayOpened}
      placement="bottom">
      <button className={cn(s.dropdownButton, { [s.active]: isOverlayOpened })}>
        <Typography>
          <CurrentTime />
          {`, UTC${formattedCurrentTimezone}`}
          <ArrowDown />
        </Typography>
      </button>
    </Dropdown>
  );
};

export default Timezone;
