import React, { FC, ReactElement, memo } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import Typography from 'components/typography';
import WithSkeletonLoader from 'components/with-skeleton-loader';

import { Children } from 'types/childrenTypes';

import { ReactComponent as ArrowBack } from 'assets/icons/arrow-left-icon.svg';

import s from './LayoutHeader.module.scss';
import LayoutHeaderSkeleton from './layoutHeader.skeleton';

interface LayoutHeaderProps {
  children: Children;
  className?: string;
  goBackLink?: string;
  isLoading?: boolean;
}

const LayoutHeader: FC<LayoutHeaderProps> = (props): ReactElement => {
  const { children, className = '', isLoading = false, goBackLink = '' } = props;

  const navigation = useNavigate();

  const handleBackClick = () => {
    navigation(goBackLink);
  };

  const goBackButton = !!goBackLink && (
    <button onClick={handleBackClick} className={s.goBackLink}>
      <ArrowBack />
    </button>
  );

  return (
    <div className={cx(s.wrapper, className)}>
      {goBackButton}
      <WithSkeletonLoader isLoading={isLoading} skeletonLoader={<LayoutHeaderSkeleton />}>
        <Typography size="18" weight="600">
          {children}
        </Typography>
      </WithSkeletonLoader>
    </div>
  );
};

export default memo(LayoutHeader);
