import React from 'react';

import Skeleton from 'components/skeleton';

const CurrencyDropdownSkeleton = () => {
  return (
    <Skeleton style={{ width: 76, height: 15, borderRadius: 4 }} shape="square" type="Button" />
  );
};

export default CurrencyDropdownSkeleton;
