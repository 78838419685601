import React, { ReactElement } from 'react';

import { useLazyV2GetInvoicesQuery } from 'services/v2-overview-service/overviewService';

import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';

import useTableColumns from '../../utils/useInvoiceTableColumns.hook';
import s from './InvoicesTableView.module.scss';

const InvoicesTableView = (): ReactElement => {
  const [
    getInvoices,
    { isFetching: invoicesIsLoading, isError: invoicesIsError, data: invoicesData },
  ] = useLazyV2GetInvoicesQuery();

  const columns = useTableColumns({
    invoices: invoicesData?.transactions,
  });

  return (
    <TableWithHandlers
      tab={TabTypesEnum.GLOBAL_INVOICES}
      className={s.tableWrapper}
      columns={columns}
      items={invoicesData?.transactions}
      totalCount={invoicesData?.pagination.count}
      getData={getInvoices}
      isLoading={invoicesIsLoading}
      isError={invoicesIsError}
    />
  );
};

export default InvoicesTableView;
