import React, { useCallback, useMemo } from 'react';

import { userCurrencySelector } from 'selectors/userSlice.selectors';
import {
  useChangeSettingsMutation,
  useGetAvailableCurrenciesQuery,
} from 'services/user-service/userService';
import { useAppSelector } from 'store';

import Typography from 'components/typography';

import { SetStateTypes } from 'types/setStateTypes';

import { ReactComponent as CheckOutlinedIcon } from 'assets/icons/check-outlined-icon.svg';

interface UseCurrencyDropdownItemsProps {
  setIsOverlayOpened: SetStateTypes<boolean>;
}

const useCurrencyDropdownItems = (
  props: UseCurrencyDropdownItemsProps,
): {
  data: { label: React.ReactElement; key: string }[];
  isFetching: boolean;
  handleItemClick: ({ key }: { key: string }) => void;
} => {
  const { setIsOverlayOpened } = props;

  const currentUserCurrency = useAppSelector(userCurrencySelector);

  const { data: availableCurrencies, isFetching: availableCurrenciesIsFetching } =
    useGetAvailableCurrenciesQuery();
  const [changeSettings, { isLoading: changeSettingsIsFetching }] = useChangeSettingsMutation();

  const renderCurrency = useCallback(
    ({ currency, symbol }: { currency: string; symbol: string }) => {
      const showCheck = currentUserCurrency?.currency === currency;

      return {
        label: (
          <Typography>
            {`${symbol},${currency}`} {showCheck && <CheckOutlinedIcon />}
          </Typography>
        ),
        key: currency,
      };
    },
    [currentUserCurrency?.currency],
  );

  const handleItemClick = useCallback(
    ({ key }: { key: string }) => {
      setIsOverlayOpened(false);
      if (currentUserCurrency?.currency !== key) {
        changeSettings({ default_currency: key });
      }
    },
    [changeSettings, currentUserCurrency?.currency, setIsOverlayOpened],
  );

  const data =
    useMemo(() => {
      return availableCurrencies?.map(renderCurrency);
    }, [availableCurrencies, renderCurrency]) || [];

  const isFetching = changeSettingsIsFetching || availableCurrenciesIsFetching;

  return {
    data,
    isFetching,
    handleItemClick,
  };
};

export default useCurrencyDropdownItems;
