import React, { useState } from 'react';

import cx from 'classnames';

import { userCurrencySelector } from 'selectors/userSlice.selectors';
import { useAppSelector } from 'store';

import Dropdown from 'components/dropdown';
import Typography from 'components/typography';
import WithSkeletonLoader from 'components/with-skeleton-loader';

import { ReactComponent as ArrowDown } from 'assets/icons/dropdown-arrow-down-icon.svg';

import useCurrencyDropdownItems from 'utils/useCurrencyDropdownItems.hook';

import s from './CurrencyDropdown.module.scss';
import CurrencyDropdownSkeleton from './currencyDropdown.skeleton';

const CurrencyDropdown = () => {
  const currentUserCurrency = useAppSelector(userCurrencySelector);

  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);

  const {
    data: currencyDropdownItems,
    isFetching,
    handleItemClick,
  } = useCurrencyDropdownItems({ setIsOverlayOpened });

  const currencyToShow = currentUserCurrency
    ? `${currentUserCurrency?.symbol},${currentUserCurrency?.currency}`
    : '';

  return (
    <WithSkeletonLoader isLoading={isFetching} skeletonLoader={<CurrencyDropdownSkeleton />}>
      <Dropdown
        align={{ offset: [20, 4] }}
        overlayClassName={s.overlay}
        handleItemClick={handleItemClick}
        open={isOverlayOpened}
        items={currencyDropdownItems}
        onOpenChange={setIsOverlayOpened}
        placement="bottomRight">
        <button className={cx(s.dropdownButton, { [s.dropdownButtonActive]: isOverlayOpened })}>
          <Typography size="15" weight="500">
            {currencyToShow}
            <ArrowDown />
          </Typography>
        </button>
      </Dropdown>
    </WithSkeletonLoader>
  );
};

export default CurrencyDropdown;
