import React, { FC } from 'react';

import { Skeleton as AntdSkeleton } from 'antd';
import cx from 'classnames';

import 'antd/lib/skeleton/style/css';

import s from './Skeleton.module.scss';

interface SkeletonProps {
  shape?: 'square' | 'round';
  type?: 'Button' | 'Avatar' | 'Input' | 'Image';
  round?: boolean;
  className?: string;
  style?: { [key: string]: string | number };
}

const Skeleton: FC<SkeletonProps> = (props) => {
  const { style = {}, type = 'Button', className = '', shape = 'round' } = props;

  switch (type) {
    case 'Button':
      return (
        <AntdSkeleton.Button
          style={style}
          active
          shape={shape}
          className={cx({ [s.defaults]: true, [className]: className })}
        />
      );
    case 'Avatar':
      return (
        <AntdSkeleton.Avatar
          style={style}
          active
          className={cx({ [s.defaults]: true, [className]: className })}
        />
      );
    case 'Input':
      return (
        <AntdSkeleton.Input
          style={style}
          active
          className={cx({ [s.defaults]: true, [className]: className })}
        />
      );
    default:
      return null;
  }
};

export default Skeleton;
