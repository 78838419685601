import { GetTabFiltersPropsType } from 'services/overview-service/overviewService.types';

import { TabTypesEnum } from 'types/tabTypes';

import initialApiService, { TAGS } from '../initialApiService';
import {
  CompanyInfoPropsType,
  CompanyInfoResponseType,
  ExportToCsvPropsType,
  ExportToCsvResponseType,
  GetCompaniesPropsType,
  GetCompaniesResponseType,
  GetCurrenciesLogoResponseType,
  GetFiltersResponseType,
  GetHotWalletsPropsType,
  GetHotWalletsResponseType,
  GetInvoiceInfoPropsType,
  GetInvoiceInfoResponseType,
  GetInvoicesPropsType,
  GetInvoicesResponseType,
  GetScoringRequestsPropsType,
  GetScoringRequestsResponseType,
  GetTabFiltersResponseType,
  SetScoringRequestsPropsType,
  SetStatusPropsType,
} from './overviewService.types';
import { createV2OverviewUrl } from './utils';

const overviewService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    v2GetInvoiceInfo: builder.query<GetInvoiceInfoResponseType, GetInvoiceInfoPropsType>({
      query: (id) => ({
        url: createV2OverviewUrl(`invoices/address_info?id=${id}`),
      }),
    }),
    v2GetHotWalletInfo: builder.query<GetInvoiceInfoResponseType, GetInvoiceInfoPropsType>({
      query: (id) => ({
        url: createV2OverviewUrl(`invoices/address_info?id=${id}`),
      }),
    }),
    v2GetTabFilters: builder.query<GetTabFiltersResponseType, GetTabFiltersPropsType>({
      query: ({ tab = TabTypesEnum.ALL }) => ({
        url: createV2OverviewUrl(`tab_filters`),
        method: 'POST',
        body: {
          tab,
        },
      }),
    }),
    v2GetFilters: builder.query<GetFiltersResponseType, void>({
      query: () => ({
        url: createV2OverviewUrl(`filters`),
        method: 'POST',
      }),
    }),
    v2GetCompanies: builder.query<GetCompaniesResponseType, GetCompaniesPropsType>({
      query: ({ pagination, sorting = null, filters = null, search = null }) => ({
        url: createV2OverviewUrl(
          `companies?limit=${pagination?.limit}&offset=${pagination?.offset}`,
        ),
        method: 'POST',
        body: {
          filter_params: {
            filters,
            search,
            sorting,
          },
        },
      }),
      providesTags: [TAGS.GET_COMPANIES],
    }),
    v2GetHotWallets: builder.query<GetHotWalletsResponseType, GetHotWalletsPropsType>({
      query: ({
        pagination,
        sorting = null,
        filters = null,
        address = null,
        search = null,
        company = null,
      }) => ({
        url: createV2OverviewUrl(
          `hot_wallets?limit=${pagination?.limit}&offset=${pagination?.offset}`,
        ),
        method: 'POST',
        body: {
          filter_params: {
            filters,
            search,
            sorting,
          },
          address,
          company,
        },
      }),
      providesTags: [TAGS.GET_HOT_WALLETS],
    }),
    v2GetScoringRequests: builder.query<
      GetScoringRequestsResponseType,
      GetScoringRequestsPropsType
    >({
      query: ({ pagination, sorting = null, filters = null }) => ({
        url: createV2OverviewUrl(
          `scoring_requests?limit=${pagination?.limit}&offset=${pagination?.offset}`,
        ),
        method: 'POST',
        body: {
          filter_params: {
            filters,
            sorting,
          },
        },
      }),
      providesTags: [TAGS.SCORING_REQUEST],
    }),
    v2SetScoringRequestStatus: builder.mutation<void, SetScoringRequestsPropsType>({
      query: (body) => ({
        url: createV2OverviewUrl(`scoring_requests/set_status`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAGS.SCORING_REQUEST],
    }),
    v2SetHotWalletStatus: builder.mutation<void, SetStatusPropsType>({
      query: ({ status, ids }) => ({
        url: createV2OverviewUrl(`hot_wallets/set_status`),
        method: 'PUT',
        body: {
          ids,
          status,
        },
      }),
      invalidatesTags: [TAGS.GET_HOT_WALLETS],
    }),
    v2HotWalletsExportToCsv: builder.mutation<ExportToCsvResponseType, ExportToCsvPropsType>({
      query: (ids) => ({
        url: createV2OverviewUrl(`hot_wallets/export`),
        method: 'POST',
        body: {
          ids,
        },
      }),
    }),
    v2GetInvoices: builder.query<GetInvoicesResponseType, GetInvoicesPropsType>({
      query: ({
        pagination,
        sorting = null,
        filters = null,
        address = null,
        search = null,
        company = null,
      }) => ({
        url: createV2OverviewUrl(
          `invoices?limit=${pagination?.limit}&offset=${pagination?.offset}`,
        ),
        method: 'POST',
        body: {
          filter_params: {
            filters,
            search,
            sorting,
          },
          address,
          company,
        },
      }),
      providesTags: [TAGS.GET_INVOICES],
    }),
    v2SetInvoiceStatus: builder.mutation<void, SetStatusPropsType>({
      query: ({ status, ids }) => ({
        url: createV2OverviewUrl(`invoices/set_status`),
        method: 'PUT',
        body: {
          ids,
          status,
        },
      }),
      invalidatesTags: [TAGS.GET_INVOICES],
    }),
    v2InvoicesExportToCsv: builder.mutation<ExportToCsvResponseType, ExportToCsvPropsType>({
      query: (ids) => ({
        url: createV2OverviewUrl(`invoices/export`),
        method: 'POST',
        body: {
          ids,
        },
      }),
    }),
    v2GetCompanyInfo: builder.query<CompanyInfoResponseType, CompanyInfoPropsType>({
      query: (id: string | number) => createV2OverviewUrl(`companies/info?id=${id}`),
      providesTags: [TAGS.USER_SETTINGS],
    }),
    v2GetCurrenciesLogo: builder.query<GetCurrenciesLogoResponseType, void>({
      query: () => createV2OverviewUrl(`currencies_logo`),
    }),
  }),
});

export const v2getFiltersEndpoint = overviewService.endpoints.v2GetFilters;
export const v2getTabFiltersEndpoint = overviewService.endpoints.v2GetTabFilters;

export const {
  useV2GetCurrenciesLogoQuery,
  useV2GetCompanyInfoQuery,
  useLazyV2GetInvoicesQuery,
  useLazyV2GetHotWalletsQuery,
  useLazyV2GetCompaniesQuery,
  useLazyV2GetFiltersQuery,
  useLazyV2GetTabFiltersQuery,
  useLazyV2GetScoringRequestsQuery,
  useV2SetHotWalletStatusMutation,
  useV2SetInvoiceStatusMutation,
  useV2InvoicesExportToCsvMutation,
  useV2HotWalletsExportToCsvMutation,
  useV2GetInvoiceInfoQuery,
  useV2GetHotWalletInfoQuery,
  useV2SetScoringRequestStatusMutation,
} = overviewService;
