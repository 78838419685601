export type DirectionType = 'outcome' | 'inside' | 'income' | 'top-up';

export enum STATUS_ENUM {
  NEW,
  VIEWED,
  REPORTED,
}

export type TransactionAddressType = {
  id: number;
  company_id?: string;
  address: string;
  details_available: boolean;
};

export type TransactionType = {
  id: number;
  company_id: number;
  company_name: string;
  merchant_address: TransactionAddressType;

  address: TransactionAddressType;
  address_id: string;
  blockchain: string;
  converted_from_amount: { amount: number; highlighted: boolean };
  currency: string;
  date: string;
  direction: DirectionType;
  from_amount: number;
  hash: string;
  merchant_address_id: string;
  scan_ref: string;
  status: STATUS_ENUM;
  target_entry_id: number;
};

export type HotWalletTransactionType = {
  id: 0;
  company_id: 0;
  company_name: string;
  merchant_address: {
    id: 0;
    address: string;
    type: string;
    details_available: boolean;
  };
  address: {
    id: 0;
    address: string;
    type: string;
    details_available: boolean;
  };
  direction: DirectionType;
  hash: string;
  blockchain: string;
  scan_ref: string;
  date: string;
  currency: string;
  amount: number;
  converted_amount: {
    amount: number;
    highlighted: boolean;
  };
  exchange_rate: number;
  status: STATUS_ENUM;
  highlighted: boolean;
};

export type InvoiceTransactionType = HotWalletTransactionType;
export enum SCORING_STATUS_ENUM {
  UNREVIEWED,
  DECLINED,
  APPROVED,
}

export type ScoringRequestType = {
  status: SCORING_STATUS_ENUM;
  id: string;
  created_date: string;
  decision_date: string;
  address: {
    id: number;
    address: string;
    type: string;
    details_available: boolean;
  };
  blockchain: string;
  amount: number;
};
