import React, { FC, PropsWithChildren } from 'react';

import cx from 'classnames';

import s from './Typography.module.scss';

interface TypographyProps extends PropsWithChildren {
  weight?: '400' | '500' | '600';
  color?: 'default' | 'secondary' | 'main' | 'orange' | 'error' | 'success';
  size?: '10' | '12' | '13' | '15' | '18' | '20' | '28';
  className?: string;
}

const Typography: FC<TypographyProps> = (props) => {
  const { children, weight = '400', color = 'default', size = '13', className } = props;

  return (
    <div className={cx(s.wrapper, className, s[`fw-${weight}`], s[color], s[`fs-${size}`])}>
      {children}
    </div>
  );
};

export default Typography;
