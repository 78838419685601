import {
  GetFiltersResponseType,
  GetTabFiltersResponseType,
} from 'services/overview-service/overviewService.types';
import { FilterSliceState, SLICE_NAME } from 'slices/filterSlice';
import { RootState } from 'store';

export const filterStateSelector = (state: RootState): FilterSliceState => state[SLICE_NAME];

export const filtersIsLoadingSelector = (state: RootState): boolean =>
  filterStateSelector(state)?.filtersIsLoading;

export const filtersSelector = (state: RootState): GetFiltersResponseType | null =>
  filterStateSelector(state)?.filters;

export const tabFiltersSelector = (state: RootState): GetTabFiltersResponseType | null =>
  filterStateSelector(state)?.tabFilters;

export const tabFiltersIsLoadingSelector = (state: RootState): boolean =>
  filterStateSelector(state)?.tabFiltersIsLoading;
