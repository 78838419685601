import React, { FC, memo } from 'react';

import { selectedTransactionsSelector } from 'selectors/selectedTransactionsSlice.selectors';
import {
  useExportToCsvMutation,
  useSetStatusMutation,
} from 'services/overview-service/overviewService';
import {
  useV2HotWalletsExportToCsvMutation,
  useV2InvoicesExportToCsvMutation,
  useV2SetHotWalletStatusMutation,
  useV2SetInvoiceStatusMutation,
} from 'services/v2-overview-service/overviewService';
import { clearSelectedTransactions } from 'slices/selectedTransactionsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import Button from 'components/button';
import TransactionsStatusDropdown from 'containers/transactions-status-dropdown';

import s from './SelectedTransactionsActionGroup.module.scss';

interface SelectedTransactionsActionGroupProps {
  setStatusMutation:
    | typeof useSetStatusMutation
    | typeof useV2SetHotWalletStatusMutation
    | typeof useV2SetInvoiceStatusMutation;

  exportToCsvMutation:
    | typeof useExportToCsvMutation
    | typeof useV2HotWalletsExportToCsvMutation
    | typeof useV2InvoicesExportToCsvMutation;
}

const SelectedTransactionsActionGroup: FC<SelectedTransactionsActionGroupProps> = (props) => {
  const { setStatusMutation, exportToCsvMutation } = props;

  const dispatch = useAppDispatch();

  const [exportToCsv, { isLoading: setExportToCsvIsLoading }] = exportToCsvMutation();
  const selectedTransactions = useAppSelector(selectedTransactionsSelector);
  const selectedTransactionsLength = selectedTransactions.length
    ? `(${selectedTransactions.length})`
    : '';

  const handleClear = () => {
    dispatch(clearSelectedTransactions());
  };

  const areButtonsDisabled = !selectedTransactions.length;

  const handleExportToCsv = () => {
    exportToCsv(selectedTransactions).then((res) => {
      if ('error' in res && 'data' in res.error) {
        const blob = new Blob([res.error!.data as string], { type: 'text/csv;charset=utf-8,' });
        const objUrl = URL.createObjectURL(blob);
        window.location.assign(objUrl);
      }
    });
  };

  return (
    <div className={s.wrapper}>
      <Button
        size="small"
        styleType="transparent"
        isLoading={setExportToCsvIsLoading}
        isDisabled={areButtonsDisabled}
        onClick={handleClear}>
        Clear selected
      </Button>
      <Button
        onClick={handleExportToCsv}
        size="small"
        isLoading={setExportToCsvIsLoading}
        isDisabled={areButtonsDisabled}>{`Export${selectedTransactionsLength} to .csv`}</Button>

      <TransactionsStatusDropdown
        disabled={setExportToCsvIsLoading || areButtonsDisabled}
        setStatusMutation={setStatusMutation}>
        <Button
          size="small"
          isLoading={setExportToCsvIsLoading}
          isDisabled={areButtonsDisabled}>{`Mark${selectedTransactionsLength} as`}</Button>
      </TransactionsStatusDropdown>
    </div>
  );
};

export default memo(SelectedTransactionsActionGroup);
