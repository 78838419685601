import React, { FC, memo, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import isEqual from 'lodash.isequal';

import Input from 'components/input';

import { FilterSelectedOptionObjectValue } from 'types/filtersTypes';

import useDebounce from 'utils/useDebounce.hook';

import { ComponentProps } from '../filterComponentConstructor';
import s from './FilterComplex.module.scss';

const FilterComplex: FC<ComponentProps> = (props) => {
  const { handleChange, value, isSelected } = props;

  const [period, setPeriod] = useState<FilterSelectedOptionObjectValue>(value || {});
  const ref = useRef<null | FilterSelectedOptionObjectValue>(null);
  const debouncedPeriod = useDebounce(period, 500);
  const debouncedUpperBorder = useDebounce(period.upper_border, 400);

  useEffect(() => {
    if (debouncedUpperBorder && Number(debouncedUpperBorder) < Number(period.lower_border)) {
      setPeriod((prevState) => ({
        ...prevState,
        upper_border: (Number(prevState.lower_border) + 1).toString(),
      }));
    }
  }, [debouncedUpperBorder, period.lower_border]);

  useEffect(() => {
    if (value && !Object.keys(period).length) {
      setPeriod(value);
    }
  }, [period, value]);

  useEffect(() => {
    if (!isEqual(ref.current, debouncedPeriod)) {
      ref.current = debouncedPeriod;
      handleChange(debouncedPeriod);
    }
  }, [debouncedPeriod, handleChange]);

  const handleFromChange = (e: string) => {
    if (e !== period.lower_border) {
      setPeriod((prevState) => ({ ...prevState, lower_border: e }));
    }
  };
  const handleToChange = (e: string) => {
    if (e !== period.upper_border) {
      setPeriod((prevState) => ({ ...prevState, upper_border: e }));
    }
  };

  const handleClick = () => {
    handleChange(period);
  };

  return (
    <button className={cx({ [s.wrapper]: true, [s.isSelected]: isSelected })} onClick={handleClick}>
      <Input
        type="number"
        handleChange={handleFromChange}
        value={period.lower_border || ''}
        min="0"
      />
      -
      <Input
        type="number"
        handleChange={handleToChange}
        value={period.upper_border || ''}
        min="0"
      />
    </button>
  );
};

export default memo(FilterComplex);
