import {
  DEFAULT_FILTER_VALUE,
  FILTER_COMPLEX_VALUE_SEPARATOR,
  FILTER_SEPARATOR,
} from 'constants/query';
import { FilterSelectedOptionObjectValue } from 'types/filtersTypes';
import { QueryBuilderProps } from 'types/paramsTypes';
import { SortingType } from 'types/sortingTypes';
import { GetTableDataArgsType } from 'types/tableTypes';

import { getFilterKey, getFilterType, isFilterKey } from './filter';

export const transformSort = (params?: string | { [key: string]: string }[]) => {
  return typeof params === 'string'
    ? [{ [params?.split(':')[0]]: params?.split(':')[1] }]
    : params || [];
};

const prepareSort = (order: { [key: string]: string | number }) => {
  const [column, sorting_order] = Object.entries(order)[0];

  return {
    column,
    sorting_order,
  };
};

const findFilterSymbol = (objectKey: string): boolean => objectKey.includes('[');

type SelectedOptionV1Type = { selected_key: string; value: string };

type FilterV1Type = {
  [key: string]: {
    selected_options?: (SelectedOptionV1Type | string)[];
  };
};

export const prepareComplexFilterValue = (value?: string): { [key: string]: string | null } => {
  if (!value || value === DEFAULT_FILTER_VALUE) {
    return {};
  }

  const [from, to] = value.split(FILTER_COMPLEX_VALUE_SEPARATOR);

  const request: FilterSelectedOptionObjectValue = {};

  const fromValue = from.split(FILTER_SEPARATOR).pop();
  const toValue = to.split(FILTER_SEPARATOR).pop();

  if (fromValue) {
    request.lower_border = fromValue;
  }
  if (toValue) {
    request.upper_border = toValue;
  }

  return request;
};

export const queryBuilder = (paramsObject?: QueryBuilderProps) => {
  const query: GetTableDataArgsType = {
    filters: null,
  };

  const hasFilters = Object.keys(paramsObject || {}).some(findFilterSymbol);

  if (!paramsObject) {
    return query;
  }

  if (paramsObject.limit) {
    query.pagination = {
      limit: paramsObject.limit,
      offset: paramsObject.offset,
    };
  }

  if (paramsObject.order) {
    query.sorting = transformSort(paramsObject.order).map(prepareSort) as SortingType;
  }

  if (hasFilters) {
    query.filters = Object.entries(paramsObject).reduce((acc, [key, value]) => {
      if (isFilterKey(key)) {
        const selected_key = getFilterKey(key);
        const filterType = getFilterType(key);

        const isComplexValue =
          value.includes(FILTER_COMPLEX_VALUE_SEPARATOR) && value.includes(FILTER_SEPARATOR);
        const preparedValue = isComplexValue ? prepareComplexFilterValue(value) : value;

        const newFilterObject = {
          selected_key,
          value: preparedValue,
        };

        if (!acc?.[filterType]?.selected_options) {
          acc[filterType] = {};
          acc[filterType].selected_options = [];
        }

        acc[filterType].selected_options!.push(newFilterObject as SelectedOptionV1Type);
      }
      return acc;
    }, {} as FilterV1Type);
  }

  return query;
};
