import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import {
  useV2HotWalletsExportToCsvMutation,
  useV2InvoicesExportToCsvMutation,
  useV2SetHotWalletStatusMutation,
  useV2SetInvoiceStatusMutation,
} from 'services/v2-overview-service/overviewService';

import LayoutHeader from 'components/layout-header';
import Tabs from 'components/tabs';
import ContentDefaultWrapper from 'containers/content-default-wrapper';
import SelectedTransactionsActionGroup from 'containers/selected-transactions-action-group';

import { DEFAULT_PAGINATION } from 'constants/pagination';

import { getFiltersFromParamsObject } from 'utils/filter';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import CompaniesTableView from './companies-table-view';
import HotWalletsTableView from './hot-wallets-table-view';
import InvoicesTableView from './invoices-table-view';

export const COMPANIES_TAB = 'companies';
export const INVOICES_TAB = 'invoices';
export const HOT_WALLETS_TAB = 'hot_wallets';

const TABS = [
  { label: 'Companies', key: COMPANIES_TAB, children: <CompaniesTableView /> },
  { label: 'Invoices', key: INVOICES_TAB, children: <InvoicesTableView /> },
  {
    label: 'Hot wallets',
    key: HOT_WALLETS_TAB,
    children: <HotWalletsTableView />,
  },
];

const RealTimeOverview = (): ReactElement => {
  const [activeTab, setActiveTab] = useState<string>('');
  const { paramsObject, setQueryParams, isInitialized } = useQueryParamsHook();

  const ref = useRef<boolean>(false);

  useEffect(() => {
    const tab = isInitialized && (paramsObject?.tab || COMPANIES_TAB);

    if (tab && !ref.current) {
      setQueryParams({ tab });
      setActiveTab(tab);
      ref.current = true;
    }

    if (isInitialized && !paramsObject?.tab) {
      setQueryParams({ tab });
      setActiveTab(tab as string);
    }
  }, [activeTab, isInitialized, paramsObject?.tab, setQueryParams]);

  const onTabClick = useCallback(
    (activeKey: string) => {
      setActiveTab(activeKey);
      setQueryParams(
        { tab: activeKey, ...DEFAULT_PAGINATION, ...getFiltersFromParamsObject(paramsObject) },
        true,
      );
    },
    [paramsObject, setQueryParams],
  );

  const showActions = activeTab !== COMPANIES_TAB;

  const setStatusMutation =
    activeTab === INVOICES_TAB ? useV2SetInvoiceStatusMutation : useV2SetHotWalletStatusMutation;
  const exportToCsvMutation =
    activeTab === INVOICES_TAB
      ? useV2InvoicesExportToCsvMutation
      : useV2HotWalletsExportToCsvMutation;

  return (
    <ContentDefaultWrapper>
      <ContentDefaultWrapper.Header>
        <LayoutHeader>Real time overview</LayoutHeader>
        {showActions && (
          <ContentDefaultWrapper.Actions>
            <SelectedTransactionsActionGroup
              setStatusMutation={setStatusMutation}
              exportToCsvMutation={exportToCsvMutation}
            />
          </ContentDefaultWrapper.Actions>
        )}
      </ContentDefaultWrapper.Header>
      <ContentDefaultWrapper.Body>
        <Tabs activeKey={activeTab} animated={true} onTabClick={onTabClick} items={TABS} />
      </ContentDefaultWrapper.Body>
    </ContentDefaultWrapper>
  );
};

export default RealTimeOverview;
