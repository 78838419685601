import React, { FC, memo } from 'react';

import TableNotification from 'containers/table-with-handlers/table-notification';

interface TableNoDataWithFilterComponentProps {
  columnsLength: number;
  resetFilters: () => void;
}

const TableNoDataWithFiltersComponent: FC<TableNoDataWithFilterComponentProps> = (props) => {
  const { columnsLength, resetFilters } = props;

  return (
    <TableNotification colSpan={columnsLength}>
      <TableNotification.Header>No results</TableNotification.Header>
      <TableNotification.Content>
        Try to change your filters to find new data
      </TableNotification.Content>
      <TableNotification.Action onClick={resetFilters}>Reset filters</TableNotification.Action>
    </TableNotification>
  );
};

export default memo(TableNoDataWithFiltersComponent);
