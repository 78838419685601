import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper';

import { ValueRowType } from 'types/tableTypes';
import { TransactionAddressType } from 'types/transactionTypes';

import { transformHash } from '../utils';
import s from './AddressHashWithCopy.module.scss';

const AddressHashWithCopy = (value: ValueRowType): ReactElement => {
  if (!value) {
    return <></>;
  }
  const typedValue: TransactionAddressType = value as unknown as TransactionAddressType;

  return (
    <CopyWrapper valueToCopy={typedValue.address} className={s.wrapper}>
      {transformHash(typedValue.address)}
    </CopyWrapper>
  );
};

export default AddressHashWithCopy;
