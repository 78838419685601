import React, { FC } from 'react';

import cx from 'classnames';

import Skeleton from 'components/skeleton';

import { ReactComponent as Divider } from 'assets/icons/arrow-rigth-icon.svg';

import s from './Breadcrumb.module.scss';

interface BreadcrumbSkeletonProps {
  length: number;
}
const breadcrumbRenderer = (_: string, index: number) => {
  const divider = index === 0 ? <></> : <Divider className={s.divider} />;
  const key = `${_}__${index}`;

  return (
    <div className={cx(s.currentLocation, s.breadcrumbWrapper)} key={key}>
      {divider}
      <Skeleton style={{ height: 13, width: 100 }} />
    </div>
  );
};
const BreadcrumbSkeleton: FC<BreadcrumbSkeletonProps> = (props) => {
  const { length } = props;

  return <>{Array(length).fill('').map(breadcrumbRenderer)}</>;
};

export default BreadcrumbSkeleton;
