import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import Tooltip, { TooltipProps } from 'components/tooltip';

import { Primitive } from 'types/baseTypes';
import { Children } from 'types/childrenTypes';

type OpenCloseFunction = (flag: boolean) => void;

interface CopyTooltipProps {
  align?: TooltipProps['align'];
  children: Children;
  valueToCopy?: Primitive;
  handleMouseLeave?: (func: OpenCloseFunction) => () => void;
  handleMouseEnter?: (func: OpenCloseFunction) => () => void;
  className?: string;
  placement?: TooltipProps['placement'];
}

const CopyTooltip: FC<CopyTooltipProps> = (props) => {
  const { children, valueToCopy, handleMouseLeave, className = '', placement, align } = props;

  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (handleMouseLeave) {
      handleMouseLeave(setIsTooltipOpened);
    }
  }, [handleMouseLeave]);

  const handleCopy = useCallback(() => {
    if (valueToCopy) {
      try {
        navigator.clipboard.writeText(valueToCopy.toString()).then(() => {
          if (timer.current) {
            clearTimeout(timer.current);
          }
          setIsTooltipOpened(true);
          timer.current = setTimeout(() => {
            setIsTooltipOpened(false);
          }, 1000);
        });
      } catch {
        console.error('copy failed');
      }
    }
  }, [valueToCopy]);

  return (
    <button className={className} onClick={handleCopy}>
      <Tooltip
        placement={placement}
        align={align}
        open={isTooltipOpened}
        trigger={['contextMenu']}
        overlay={<div>Copied</div>}>
        {children}
      </Tooltip>
    </button>
  );
};

export default memo(CopyTooltip);
