import React from 'react';

import { useLocation } from 'react-router-dom';

import CopyTooltip from 'containers/copy-tooltip';

import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon-outlined.svg';

import s from './CopyFilters.module.scss';

const CopyFilters = () => {
  const location = useLocation();
  //TODO: check another methods or try to not memoize window.location.path
  const path = `${window.location.origin}${location.pathname}${location.search}`;
  return (
    <div className={s.wrapper}>
      <CopyTooltip valueToCopy={path}>
        <CopyIcon />
        Copy filters link
      </CopyTooltip>
    </div>
  );
};

export default CopyFilters;
