import React, { FC, memo } from 'react';

import cx from 'classnames';

import { Children } from 'types/childrenTypes';

import CheckboxAntd, { CheckboxChangeEvent as CheckboxChangeEventAntd } from 'antd/lib/checkbox';
import 'antd/lib/checkbox/style/css';

import s from './Checkbox.module.scss';

export type CheckboxChangeEvent = CheckboxChangeEventAntd;
export interface CheckboxProps {
  isChecked: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (event: CheckboxChangeEvent) => void;
  children?: Children;
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const { className = '', isChecked, disabled, onChange, children } = props;

  return (
    <CheckboxAntd
      className={cx({ [className]: className, [s.wrapper]: true })}
      checked={isChecked}
      disabled={disabled}
      onChange={onChange}>
      {children}
    </CheckboxAntd>
  );
};

export default memo(Checkbox);
