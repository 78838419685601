import React, { Fragment, useEffect, useState } from 'react';

import { Form as FormAntd } from 'antd';

import { useVerifyEmailMutation } from 'services/user-service/userService';

import Button from 'components/button';
import Form from 'components/form';
import Input from 'components/input';
import Typography from 'components/typography';

import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as OpenEmail } from 'assets/icons/open-email-icon.svg';

import s from './Login.module.scss';
import {
  EMAIL_INPUT_PLACEHOLDER,
  FIND_EMAIL_LINK,
  INCORRECT_EMAIL,
  LOGIN_FORM_NAME,
  RULES,
} from './login.utils';

const Login = () => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [validation, setValidation] = useState<{} | typeof INCORRECT_EMAIL>({});
  const [userLocation, setUserLocation] = useState<{
    country: string;
    region: string;
  }>({ country: '', region: '' });
  const [email, setEmail] = useState<string>('');

  const [verifyEmail, { isLoading: verifyEmailIsFetching }] = useVerifyEmailMutation();

  const [form] = FormAntd.useForm();

  const emailForm = Form.useWatch('email', form);

  useEffect(() => {
    fetch('http://ip-api.com/json')
      .then((res) => res.json())
      .then(({ country, regionName }) => setUserLocation({ country, region: regionName }));
  }, []);

  useEffect(() => {
    if ('help' in validation && emailForm !== email) {
      setValidation({});
    }
  }, [email, emailForm, validation]);

  const handleShowConfirmation = () => setShowConfirmation((prevState) => !prevState);

  const handleVerifyEmail = ({ email }: { email: string }) => {
    verifyEmail({ email, country: userLocation.country, region: userLocation.region }).then(
      (res) => {
        setEmail(email);

        if ('error' in res) {
          setValidation(INCORRECT_EMAIL);
        } else {
          handleShowConfirmation();
        }
      },
    );
  };

  const emailConfirmation = (
    <div className={s.confirmationWrapper} key="email-confirmation">
      <h3>Check your email</h3>
      <div className={s.info}>
        <div>We emailed a link to</div>
        <button onClick={handleShowConfirmation} className={s.editEmailButton}>
          {email}
          <EditIcon />
        </button>

        <div>Click the link to log in</div>
      </div>
      <Button
        onClick={() => window.open(FIND_EMAIL_LINK, '_blank')}
        size="large"
        className={s.checkInbox}>
        Check your inbox
      </Button>
    </div>
  );

  const welcomeMessage = (
    <Fragment key="welcome-message">
      <Typography size="20" weight="600" className={s.welcome}>
        Welcome to Watchblock!
      </Typography>
      <Form
        onFinish={handleVerifyEmail}
        name={LOGIN_FORM_NAME}
        className={s.formWrapper}
        form={form}>
        <Form.Item
          rules={RULES}
          className={s.inputWrapper}
          name="email"
          validateTrigger={['onChange', 'onBlur']}
          {...validation}>
          <Input size="large" placeholder={EMAIL_INPUT_PLACEHOLDER} autoComplete="on" />
        </Form.Item>
        <Form.Item className={s.buttonWrapper}>
          <Button htmlType="submit" size="large" block={true} isLoading={verifyEmailIsFetching}>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.logo}>{showConfirmation ? <OpenEmail /> : <Logo />}</div>
        {showConfirmation ? emailConfirmation : welcomeMessage}
      </div>
    </div>
  );
};

export default Login;
