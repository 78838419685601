import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper';

import { ValueRowType } from 'types/tableTypes';

import s from './ExchangeRate.module.scss';

const ExchangeRate = (rate: ValueRowType): ReactElement => {
  if (!rate) {
    return <div className={s.wrapper}>-</div>;
  }

  return (
    <CopyWrapper className={s.copier} valueToCopy={rate.toString()}>
      <div className={s.wrapper}>
        {rate.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}
      </div>
    </CopyWrapper>
  );
};

export default ExchangeRate;
