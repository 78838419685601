import { ReactElement } from 'react';

import { FILTER_SEPARATOR } from 'constants/query';

import { isFilterKey } from 'utils/filter';

export const getFiltersFromQuery = ([key]: [string, string]): boolean => isFilterKey(key);
export const valuesModification = (v: string) => {
  const [, val] = v.split(FILTER_SEPARATOR);

  return val;
};
const filterEmptyValues = (v: string) => !!v;

export const getComplexLabel = (value?: string[]): string => {
  const noValuesProvided = !value || !value.length;

  if (noValuesProvided) {
    return '';
  }

  return value.map(valuesModification).filter(filterEmptyValues).join(' - ');
};

export type FilterType = { key: string; label: ReactElement };
export type FiltersVisibilityType = { visible: FilterType[]; unVisible: FilterType[] };

export const SPACINGS_IN_PX = 38;
