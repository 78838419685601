import React, { ReactElement, memo } from 'react';

import cx from 'classnames';

import { SortType } from 'types/sortingTypes';
import { PossibleTableItemsType } from 'types/tableTypes';

import { ReactComponent as Spinner } from 'assets/icons/spinner-animated-icon.svg';

import s from './Table.module.scss';
import TableHead from './table-head';
import TablePagination from './table-pagination';

interface TableProps<R> {
  columns: { title: string | ReactElement; key: string; sortable?: boolean }[];
  items?: (R | { id: number })[];
  renderItem: (item: R | { id: number }, index: number) => ReactElement;
  className?: string;
  tableClassName?: string;
  showPagination?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  noDataComponent?: ReactElement;
  errorComponent?: ReactElement;

  // SORT
  handleSortChange?: (type: SortType, key: string) => void;
  activeSort?: { [key: string]: string }[];

  // PAGINATION
  totalCount?: number | string;
  offset?: number | string;
  limit?: number | string;
  handlePaginationChange?: (page: number) => void;
  handlePageSizeChange?: (page: number) => void;
}

const Table = <R extends PossibleTableItemsType>(props: TableProps<R>): ReactElement => {
  const {
    noDataComponent,
    errorComponent,
    isError,
    totalCount,
    columns,
    items,
    renderItem,
    className = '',
    tableClassName = '',
    showPagination = true,
    offset,
    limit,
    handlePaginationChange,
    handlePageSizeChange,
    handleSortChange,
    activeSort,
    isLoading,
  } = props;

  let tableBodyContent = <>{items?.map(renderItem)}</>;

  const noDataOrError = !items?.length || isError;

  if (!isLoading && noDataOrError) {
    if (isError) {
      tableBodyContent = errorComponent || <></>;
    } else {
      tableBodyContent = noDataComponent || <></>;
    }
  }

  return (
    <div className={cx(s.wrapper, className)}>
      {/*<div>actions will be here</div>*/}
      <div className={s.tableWrapper}>
        {isLoading && <Spinner className={s.loader} />}
        <table
          className={cx(tableClassName, {
            [s.tableDefaultStyles]: true,
            [s.loadingOverlay]: isLoading,
          })}>
          <TableHead
            columns={columns}
            activeSort={activeSort}
            handleSortChange={handleSortChange}
          />
          <tbody>{tableBodyContent}</tbody>
        </table>
      </div>
      <TablePagination
        showPagination={showPagination}
        handlePaginationChange={handlePaginationChange}
        totalCount={noDataOrError ? 0 : totalCount}
        handlePageSizeChange={handlePageSizeChange}
        offset={offset}
        limit={limit}
      />
    </div>
  );
};

export default memo(Table);
