import React, { FC, ReactElement, memo, useMemo } from 'react';

import cx from 'classnames';

import FilterComponentConstructor from 'containers/filter-component-constructor';

import { FilterComponent, FilterSelectTypes } from 'types/filtersTypes';

import s from './GroupedByFirstLetterList.module.scss';

interface OptionsGroupedListProps {
  children?: ReactElement<FilterComponent>[];
  className?: string;
  filterType: FilterSelectTypes;
  filterKey: string;
}

const groupValue = (acc: { [key: string]: ReactElement[] | undefined }, curr: ReactElement) => {
  const firstLetter = curr.props.component.label[0].toUpperCase();
  if (!acc[firstLetter]) {
    acc[firstLetter] = [];
  }

  acc[firstLetter]!.push(curr);

  return acc;
};

const sortByCompanyName = (a: ReactElement, b: ReactElement) => {
  return a.props.component.label.localeCompare(b.props.component.label);
};

const GroupedByFirstLetterList: FC<OptionsGroupedListProps> = (props) => {
  const { children = [], className = '', filterKey, filterType } = props;

  const renderFiltersContent = (component: ReactElement<{ component: FilterComponent }>) => {
    return (
      <FilterComponentConstructor
        className={s.option}
        filterType={filterType}
        filterKey={filterKey}
        key={`filter_content_key__${component.props.component.key}`}
        mainKey={component.props.component.key}
        component={component.props.component}
      />
    );
  };

  const groupedByFirstLetter = useMemo(() => {
    return [...children].sort(sortByCompanyName).reduce(groupValue, {});
  }, [children]);

  const filterGroupRenderer = ([key, value]: [
    string,
    ReactElement<{ component: FilterComponent }>[] | undefined,
  ]) => {
    return (
      <div key={`groupRenderer__${key}`} className={s.optionsGroupWrapper}>
        <div className={s.optionsGroupWrapperLabel}>{key}</div>
        <div className={s.optionsWrapper}>{value?.map(renderFiltersContent)}</div>
      </div>
    );
  };

  const dataToRender = children?.length ? (
    <>{Object.entries(groupedByFirstLetter).map(filterGroupRenderer)}</>
  ) : (
    <div>no data</div>
  );

  return <div className={cx({ [className]: className, [s.wrapper]: true })}>{dataToRender}</div>;
};

export default memo(GroupedByFirstLetterList);
