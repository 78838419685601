import { useMemo } from 'react';

import { userCurrencySelector } from 'selectors/userSlice.selectors';
import { useAppSelector } from 'store';

import { Company, ConvertedFrom, Date, RowNumber } from 'containers/row-renderers';

import { CompanyColumnType } from 'types/tableTypes';

import s from './CompaniesTableView.module.scss';

const useTableColumns = (): CompanyColumnType[] => {
  const userCurrency = useAppSelector(userCurrencySelector);

  return useMemo(
    () => [
      { title: '#', key: 'number', renderer: RowNumber, className: s.number },
      {
        title: 'Company',
        key: 'name',
        renderer: Company,
        sortable: true,
        className: s.company,
      },
      {
        title: 'Last transaction',
        key: 'last_transaction',
        renderer: Date,
        sortable: true,
        className: s.date,
      },
      {
        title: 'Income value',
        key: 'income',
        renderer: ConvertedFrom,
        userCurrency,
        sortable: true,
        className: s.amount,
      },
      {
        title: 'Outcome value',
        key: 'outcome',
        renderer: ConvertedFrom,
        userCurrency,
        sortable: true,
        className: s.amount,
      },
    ],
    [userCurrency],
  );
};

export default useTableColumns;
