import React, { ReactElement, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import useInvoiceTableColumns from 'pages/v2/utils/useInvoiceTableColumns.hook';

import { useLazyV2GetInvoicesQuery } from 'services/v2-overview-service/overviewService';
import { GetInvoicesPropsType } from 'services/v2-overview-service/overviewService.types';

import TableWithHandlers from 'containers/table-with-handlers';

import { TabTypesEnum } from 'types/tabTypes';

import s from './InvoicesDetailsTableView.module.scss';

const InvoicesDetailsTableView = (): ReactElement => {
  const params = useParams<{ invoiceId: string }>();

  const [
    getInvoices,
    { isFetching: invoicesIsLoading, isError: invoicesIsError, data: invoicesData },
  ] = useLazyV2GetInvoicesQuery();

  const columns = useInvoiceTableColumns({
    removedColumns: ['merchant_address'],
    invoices: invoicesData?.transactions,
  });

  const getData = useCallback(
    (args: Omit<GetInvoicesPropsType, 'companyId'>) => {
      const updatedProps = {
        ...args,
        address: { id: Number(params.invoiceId) },
      } as GetInvoicesPropsType;

      return getInvoices(updatedProps);
    },
    [getInvoices, params.invoiceId],
  );

  return (
    <TableWithHandlers
      tab={TabTypesEnum.GLOBAL_INVOICES}
      className={s.tableWrapper}
      columns={columns}
      items={invoicesData?.transactions}
      totalCount={invoicesData?.pagination.count}
      getData={getData}
      isLoading={invoicesIsLoading}
      isError={invoicesIsError}
    />
  );
};

export default InvoicesDetailsTableView;
