import React, { FC, memo, useCallback, useRef, useState } from 'react';

import cx from 'classnames';

import Dropdown from 'components/dropdown';
import CopyTooltip from 'containers/copy-tooltip';

import { Primitive } from 'types/baseTypes';
import { Children } from 'types/childrenTypes';

import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon.svg';

import s from './CopyWrapper.module.scss';

interface CopyWrapperProps {
  children: Children;
  valueToCopy: Primitive;
  className?: string;
  handleClick?: () => void;
}

const COPY_ICON_HALF_HEIGHT = 12;

const CopyWrapper: FC<CopyWrapperProps> = (props) => {
  const { children, valueToCopy, className = '', handleClick } = props;

  const [isOverlayOpened, setIsOverlayOpened] = useState(false);
  const childrenRef = useRef<HTMLButtonElement | null>(null);
  const copyOverlayYOffset = (childrenRef?.current?.offsetHeight || 0) / 2 - COPY_ICON_HALF_HEIGHT;

  const onMouseLeave = useCallback(
    (setIsTooltipOpened: (flag: boolean) => void) => () => {
      setIsOverlayOpened(false);
      setIsTooltipOpened(false);
    },
    [],
  );

  return (
    <Dropdown
      align={{ offset: [14, copyOverlayYOffset] }}
      trigger={['hover']}
      open={isOverlayOpened}
      overlay={
        <CopyTooltip
          handleMouseLeave={onMouseLeave}
          align={{ offset: [0, 5] }}
          valueToCopy={valueToCopy}
          className={cx({
            [s.dropdownOverlayWrapper]: true,
            [s.active]: isOverlayOpened,
          })}>
          <CopyIcon />
        </CopyTooltip>
      }
      onOpenChange={setIsOverlayOpened}
      placement="topLeft">
      <button
        onClick={handleClick}
        ref={childrenRef}
        className={cx(s.contentWrapper, className, {
          [s.active]: isOverlayOpened,
        })}>
        {children}
      </button>
    </Dropdown>
  );
};

export default memo(CopyWrapper);
