import { createSlice } from '@reduxjs/toolkit';

import {
  getFiltersEndpoint,
  getTabFiltersEndpoint,
} from 'services/overview-service/overviewService';
import { GetFiltersResponseType } from 'services/overview-service/overviewService.types';
import {
  v2getFiltersEndpoint,
  v2getTabFiltersEndpoint,
} from 'services/v2-overview-service/overviewService';

export const SLICE_NAME = 'filterSlice';

export type FilterSliceState = {
  filtersIsLoading: boolean;
  tabFiltersIsLoading: boolean;
  filters: GetFiltersResponseType | null;
  tabFilters: string[] | null;
};

const initialState: FilterSliceState = {
  filtersIsLoading: true,
  filters: null,
  tabFiltersIsLoading: true,
  tabFilters: null,
};

export const filterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearFilters(state) {
      state.filters = null;
    },
    setFiltersIsLoading(state, action) {
      state.filtersIsLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getFiltersEndpoint.matchFulfilled, (state, action) => {
      state.filters = action.payload;
      state.filtersIsLoading = false;
    });
    builder.addMatcher(getFiltersEndpoint.matchRejected, (state) => {
      state.filtersIsLoading = false;
    });
    builder.addMatcher(getFiltersEndpoint.matchPending, (state) => {
      state.filters = null;
      state.filtersIsLoading = true;
    });
    builder.addMatcher(getTabFiltersEndpoint.matchFulfilled, (state, action) => {
      state.tabFilters = action.payload;
      state.tabFiltersIsLoading = false;
    });
    builder.addMatcher(getTabFiltersEndpoint.matchRejected, (state) => {
      state.tabFiltersIsLoading = false;
    });
    builder.addMatcher(getTabFiltersEndpoint.matchPending, (state) => {
      state.tabFilters = null;
      state.tabFiltersIsLoading = true;
    });

    builder.addMatcher(v2getTabFiltersEndpoint.matchFulfilled, (state, action) => {
      state.tabFilters = action.payload;
      state.tabFiltersIsLoading = false;
    });
    builder.addMatcher(v2getTabFiltersEndpoint.matchRejected, (state) => {
      state.tabFiltersIsLoading = false;
    });
    builder.addMatcher(v2getTabFiltersEndpoint.matchPending, (state) => {
      state.tabFilters = null;
      state.tabFiltersIsLoading = true;
    });
    builder.addMatcher(v2getFiltersEndpoint.matchFulfilled, (state, action) => {
      state.filters = action.payload;
      state.filtersIsLoading = false;
    });
    builder.addMatcher(v2getFiltersEndpoint.matchRejected, (state) => {
      state.filtersIsLoading = false;
    });
    builder.addMatcher(v2getFiltersEndpoint.matchPending, (state) => {
      state.filters = null;
      state.filtersIsLoading = true;
    });
  },
});

export const { clearFilters, setFiltersIsLoading } = filterSlice.actions;

export default filterSlice.reducer;
