import React from 'react';

import { Routes } from 'react-router-dom';

import Layout from 'components/layout';

import { AUTH_ROUTES, V2_AUTH_ROUTES } from 'constants/routes';
import { WATCH_BLOCK_VERSION_1, WATCH_BLOCK_VERSION_LOCAL_KEY } from 'constants/version';

import { renderRoutes } from 'utils/routes';
import useLocalStorage from 'utils/useLocalStorage.hook';

import s from './AppContent.module.scss';

const AppContent = () => {
  const [currentVersion] = useLocalStorage(WATCH_BLOCK_VERSION_LOCAL_KEY, WATCH_BLOCK_VERSION_1);

  const isV1 = currentVersion === WATCH_BLOCK_VERSION_1;

  const routes = isV1 ? AUTH_ROUTES : V2_AUTH_ROUTES;

  return (
    <Layout className={s.wrapper}>
      <Routes>{routes.map(renderRoutes)}</Routes>
    </Layout>
  );
};

export default AppContent;
