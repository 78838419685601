import React from 'react';

import cx from 'classnames';

import Button, { ButtonSizeType } from 'components/button';

import { Children } from 'types/childrenTypes';

import { ReactComponent as NoDataIcon } from 'assets/icons/no-data-icon.svg';

import s from './TableNotification.module.scss';

interface TableNotificationDefaultProps {
  children: Children;
  className?: string;
}

interface TableNotificationProps extends TableNotificationDefaultProps {
  colSpan: number;
}

interface TableNotificationActionProps extends TableNotificationDefaultProps {
  onClick: () => void;
  size?: ButtonSizeType;
}

const TableNotification = ({ children, className = '', colSpan }: TableNotificationProps) => {
  return (
    <tr className={cx(s.wrapper, className)}>
      <td colSpan={colSpan}>
        <div>
          <NoDataIcon />
          {children}
        </div>
      </td>
    </tr>
  );
};

TableNotification.Header = ({ children, className = '' }: TableNotificationDefaultProps) => {
  return <h3 className={cx({ [s.header]: true, [className]: className })}>{children}</h3>;
};

TableNotification.Content = ({ children, className = '' }: TableNotificationDefaultProps) => {
  return <div className={cx({ [s.content]: true, [className]: className })}>{children}</div>;
};

TableNotification.Action = ({
  children,
  className = '',
  onClick,
}: TableNotificationActionProps) => {
  return (
    <Button
      onClick={onClick}
      styleType="filled"
      size="large"
      className={cx({ [s.actionButton]: true, [className]: className })}>
      {children}
    </Button>
  );
};

export default TableNotification;
