import { LOGIN_ROUTE } from 'constants/routes';

import { browserDetect } from 'utils/browserDetect';

import initialApiService, { TAGS } from '../initialApiService';
import {
  ChangeUserSettingsPropsType,
  ConnectTelegramPropsType,
  ConnectTelegramResponseType,
  DisconnectTelegramPropsType,
  DisconnectTelegramResponseType,
  GetAvailableCurrenciesResponseType,
  GetUserInfoResponseType,
  SignInPropsType,
  SignInResponseType,
  VerifyEmailPropsType,
  VerifyEmailResponseType,
} from './userService.types';
import { createUserUrl } from './utils';

const BROWSER = browserDetect();

const userService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    changeSettings: builder.mutation<string, ChangeUserSettingsPropsType>({
      query: (body) => ({
        url: createUserUrl(`change_settings`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TAGS.USER_SETTINGS],
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: createUserUrl(`sign_out`),
        method: 'POST',
      }),
    }),
    signIn: builder.mutation<SignInResponseType, SignInPropsType>({
      query: (sign_in_secret) => ({
        url: createUserUrl('sign_in'),
        method: 'POST',
        body: {
          sign_in_secret,
        },
      }),
    }),
    verifyEmail: builder.mutation<VerifyEmailResponseType, VerifyEmailPropsType>({
      query: ({ email, country, region }) => ({
        url: createUserUrl('verify_email'),
        method: 'POST',
        body: {
          user_metadata: {
            country,
            region,
            browser: BROWSER,
          },
          email,
          sign_in_url: `${window.location.origin}${LOGIN_ROUTE}?token`,
        },
      }),
    }),
    getUserInfo: builder.query<GetUserInfoResponseType, void>({
      query: () => createUserUrl(`info`),
      providesTags: [
        TAGS.USER_SETTINGS,
        TAGS.GET_TRANSACTIONS,
        TAGS.GET_HOT_WALLETS,
        TAGS.GET_COMPANIES,
        TAGS.GET_COMPANY_INFO,
        TAGS.GET_WALLET_INFO,
        TAGS.GET_WALLETS,
      ],
    }),
    connectTelegram: builder.mutation<ConnectTelegramResponseType, ConnectTelegramPropsType>({
      query: (body) => ({
        url: createUserUrl(`telegram_connect`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAGS.USER_SETTINGS],
    }),
    disconnectTelegram: builder.mutation<
      DisconnectTelegramResponseType,
      DisconnectTelegramPropsType
    >({
      query: () => ({
        url: createUserUrl(`telegram_connect`),
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.USER_SETTINGS],
    }),
    getAvailableCurrencies: builder.query<GetAvailableCurrenciesResponseType, void>({
      query: () => createUserUrl(`available_fiat_currencies`),
    }),
  }),
});

export const getUserInfoEndpoint = userService.endpoints.getUserInfo.select();

export const {
  useGetAvailableCurrenciesQuery,
  useVerifyEmailMutation,
  useLazyGetUserInfoQuery,
  useGetUserInfoQuery,
  useSignOutMutation,
  useSignInMutation,
  useChangeSettingsMutation,
  useConnectTelegramMutation,
  useDisconnectTelegramMutation,
} = userService;
