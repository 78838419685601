import React, { FC, ReactElement, memo } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import BreadcrumbSkeleton from 'components/breadcrumb/breadcrumb.skeleton';
import WithSkeletonLoader from 'components/with-skeleton-loader';

import { ReactComponent as Divider } from 'assets/icons/arrow-rigth-icon.svg';

import s from './Breadcrumb.module.scss';

export interface ItemRenderRoute {
  path?: string;
  name: string | ReactElement;
}

interface BreadcrumbProps {
  isLoading?: boolean;
  routes: ItemRenderRoute[];
}

const breadcrumbRenderer = (breadcrumb: ItemRenderRoute, index: number) => {
  const divider = index === 0 ? <></> : <Divider className={s.divider} />;
  const key = `${breadcrumb.name}-${index}`;

  return !breadcrumb.path ? (
    <div className={cx(s.currentLocation, s.breadcrumbWrapper)} key={key}>
      {divider}
      {breadcrumb.name}
    </div>
  ) : (
    <div className={s.breadcrumbWrapper} key={key}>
      {divider}
      <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
    </div>
  );
};

const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  const { routes, isLoading = false } = props;

  const routesToRender = routes?.length > 1 ? routes : [];

  return (
    <div className={s.wrapper}>
      <WithSkeletonLoader
        isLoading={isLoading}
        skeletonLoader={<BreadcrumbSkeleton length={routesToRender.length} />}>
        {routesToRender.map(breadcrumbRenderer)}
      </WithSkeletonLoader>
    </div>
  );
};

export default memo(Breadcrumb);
