import React, { FC, MouseEventHandler, memo, useCallback } from 'react';

import {
  FilterComponent,
  FilterComponentTypes,
  FilterDatepickerOptionObjectValue,
  FilterSelectTypes,
  FilterSelectedOptionObjectValue,
} from 'types/filtersTypes';

import { prepareComplexFilterValue } from 'utils/table';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import FilterComplex from './filter-complex';
import FilterDatepicker from './filter-datepicker';
import FilterInput from './filter-input';
import FilterOption from './filter-option';
import FilterCheckbox from './fliter-checkbox';

interface FilterComponentConstructorProps {
  className?: string;
  component: FilterComponent;
  filterKey: string;
  mainKey: string;
  filterType: FilterSelectTypes;
}

export interface ComponentProps {
  className?: string;
  label: string;
  type: string;
  componentKey: string;
  filterKey: string;
  currentFilterKey: string;
  isSelected: boolean;
  handleChange: (
    value:
      | FilterSelectedOptionObjectValue
      | FilterDatepickerOptionObjectValue
      | MouseEventHandler<HTMLButtonElement>,
  ) => void;
  value?: FilterSelectedOptionObjectValue;
}

const COMPONENTS: { [key in FilterComponentTypes]: FC<ComponentProps> } = {
  [FilterComponentTypes.CHECKBOX]: FilterCheckbox,
  [FilterComponentTypes.OPTION]: FilterOption,
  [FilterComponentTypes.COMPLEX]: FilterComplex,
  [FilterComponentTypes.DATEPICKER]: FilterDatepicker,
  [FilterComponentTypes.INPUT]: FilterInput,
};

const FilterComponentConstructor: FC<FilterComponentConstructorProps> = (props) => {
  const { component, filterType, ...otherProps } = props;

  const { paramsObject, setQueryParams } = useQueryParamsHook();

  const copiedObject = JSON.parse(JSON.stringify(paramsObject || {}));
  const currentFilterKey = `${otherProps.filterKey}[${component.key}]`;
  const selectedFilterGroupOption = Object.keys(copiedObject).find((objectKey) =>
    objectKey.includes(`${otherProps.filterKey}[`),
  );

  const isSelected = Object.hasOwn(paramsObject || {}, currentFilterKey);

  const setFilters = useCallback(
    (valueToSet: string) => {
      if (copiedObject) {
        if (filterType === FilterSelectTypes.MULTISELECT) {
          if (isSelected) {
            delete copiedObject[currentFilterKey];
          } else {
            copiedObject[currentFilterKey] = valueToSet;
          }
        }
        if (filterType === FilterSelectTypes.SINGLE_SELECT) {
          if (selectedFilterGroupOption) {
            delete copiedObject[selectedFilterGroupOption];
            copiedObject[currentFilterKey] = valueToSet;
          } else {
            copiedObject[currentFilterKey] = valueToSet;
          }
        }

        setQueryParams(copiedObject, true);
      }
    },
    [
      copiedObject,
      currentFilterKey,
      filterType,
      isSelected,
      selectedFilterGroupOption,
      setQueryParams,
    ],
  );

  const handleChange = (params: FilterSelectedOptionObjectValue | Event) => {
    if (paramsObject) {
      const paramsIsSyntheticEvent = Object.hasOwn(params, '_reactName');

      const valueToSet = paramsIsSyntheticEvent
        ? ''
        : `lower_border=${
            (params as FilterSelectedOptionObjectValue).lower_border || ''
          }+upper_border=${(params as FilterSelectedOptionObjectValue).upper_border || ''}`;

      copiedObject.offset = 0;

      setFilters(valueToSet);
    }
  };

  if (!COMPONENTS[component.type]) {
    return <></>;
  }
  const Component = COMPONENTS[component.type] as unknown as typeof React.Component;

  return (
    <Component
      {...otherProps}
      {...component}
      value={prepareComplexFilterValue(paramsObject?.[currentFilterKey])}
      handleChange={handleChange}
      componentKey={component.key}
      isSelected={isSelected}
      currentFilterKey={currentFilterKey}
    />
  );
};

export default memo(FilterComponentConstructor);
