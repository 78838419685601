export type FiltersRequestBodyType = unknown;

export enum FilterSelectTypes {
  MULTISELECT = 'multiselect',
  SINGLE_SELECT = 'singleselect',
}

export enum FilterComponentTypes {
  CHECKBOX = 'checkbox',
  OPTION = 'option',
  INPUT = 'input',
  DATEPICKER = 'datepicker',
  COMPLEX = 'complex',
}

export type FilterComponent = {
  type: FilterComponentTypes;
  label: string;
  key: string;
  default_selected: boolean;
  components: {
    type: string;
    label: string;
    key: string;
  }[];
};

export type FilterResponseType = {
  label: string;
  description: string;
  key: string;
  collapsed: boolean;
  type: FilterSelectTypes;
  components: FilterComponent[];
};

export type FilterLabelResponseType = {
  key: string;
  label: string;
};

export type FilterSelectedOptionObjectValue = {
  lower_border?: string;
  upper_border?: string;
};
export type FilterDatepickerOptionObjectValue = {
  date_from?: string;
  date_to?: string;
};

export type FilterSelectedOption = {
  selected_key?: string;
  value?: string | FilterSelectedOptionObjectValue;
};
