import { TabTypesEnum } from 'types/tabTypes';

import initialApiService, { TAGS } from '../initialApiService';
import {
  CompanyInfoPropsType,
  CompanyInfoResponseType,
  ExportToCsvPropsType,
  ExportToCsvResponseType,
  GetCompaniesPropsType,
  GetCompaniesResponseType,
  GetCurrenciesLogoResponseType,
  GetFiltersResponseType,
  GetTabFiltersPropsType,
  GetTabFiltersResponseType,
  GetTransactionsPropsType,
  GetTransactionsResponseType,
  GetWalletsPropsType,
  GetWalletsResponseType,
  SetStatusPropsType,
  WalletInfoPropsType,
  WalletInfoResponseType,
} from './overviewService.types';
import { createOverviewUrl } from './utils';

const overviewService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query<GetTransactionsResponseType, GetTransactionsPropsType>({
      query: ({ pagination, sorting = null, filters = null, address = null, search = null }) => ({
        url: createOverviewUrl(
          `transactions?limit=${pagination?.limit}&offset=${pagination?.offset}`,
        ),
        method: 'POST',
        body: {
          filter_params: {
            filters,
            search,
            sorting,
          },
          address,
        },
      }),
      providesTags: [TAGS.GET_TRANSACTIONS],
    }),
    getCompanies: builder.query<GetCompaniesResponseType, GetCompaniesPropsType>({
      query: ({ pagination, sorting = null, filters = null, search = null }) => ({
        url: createOverviewUrl(`companies?limit=${pagination?.limit}&offset=${pagination?.offset}`),
        method: 'POST',
        body: {
          filter_params: {
            filters,
            search,
            sorting,
          },
        },
      }),
      providesTags: [TAGS.GET_COMPANIES],
    }),
    getFilters: builder.query<GetFiltersResponseType, void>({
      query: () => ({
        url: createOverviewUrl(`filters`),
        method: 'POST',
      }),
    }),
    getTabFilters: builder.query<GetTabFiltersResponseType, GetTabFiltersPropsType>({
      query: ({ tab = TabTypesEnum.ALL }) => ({
        url: createOverviewUrl(`tab_filters`),
        method: 'POST',
        body: {
          tab,
        },
      }),
    }),
    getWallets: builder.query<GetWalletsResponseType, GetWalletsPropsType>({
      query: ({ pagination, sorting = null, filters = null, companyId, search = null }) => ({
        url: createOverviewUrl(`wallets?limit=${pagination?.limit}&offset=${pagination?.offset}`),
        method: 'POST',
        body: {
          filter_params: {
            filters,
            search,
            sorting,
          },
          company: companyId
            ? {
                id: Number(companyId),
              }
            : null,
        },
      }),
      providesTags: [TAGS.GET_WALLETS],
    }),
    getCompanyInfo: builder.query<CompanyInfoResponseType, CompanyInfoPropsType>({
      query: (id: string | number) => createOverviewUrl(`companies/info?id=${id}`),
      providesTags: [TAGS.GET_COMPANY_INFO],
    }),
    getWalletInfo: builder.query<WalletInfoResponseType, WalletInfoPropsType>({
      query: (id: string | number) => createOverviewUrl(`wallets/info?id=${id}`),
      providesTags: [TAGS.GET_WALLET_INFO],
    }),
    getCurrenciesLogo: builder.query<GetCurrenciesLogoResponseType, void>({
      query: () => createOverviewUrl(`currencies_logo`),
    }),
    setStatus: builder.mutation<void, SetStatusPropsType>({
      query: ({ status, ids }) => ({
        url: createOverviewUrl(`set_status`),
        method: 'PUT',
        body: {
          ids,
          status,
        },
      }),
      invalidatesTags: [TAGS.GET_TRANSACTIONS],
    }),
    exportToCsv: builder.mutation<ExportToCsvResponseType, ExportToCsvPropsType>({
      query: (ids) => ({
        url: createOverviewUrl(`transactions/export`),
        method: 'POST',
        body: {
          ids,
        },
      }),
    }),
  }),
});

export const getFiltersEndpoint = overviewService.endpoints.getFilters;
export const getTabFiltersEndpoint = overviewService.endpoints.getTabFilters;

export const {
  useLazyGetTransactionsQuery,
  useExportToCsvMutation,
  useSetStatusMutation,
  useLazyGetTabFiltersQuery,
  useLazyGetFiltersQuery,
  useLazyGetWalletsQuery,
  useLazyGetCompaniesQuery,
  useGetCurrenciesLogoQuery,
  useGetCompanyInfoQuery,
  useGetWalletInfoQuery,
} = overviewService;
