import React, { ReactElement } from 'react';

import cx from 'classnames';

import CopyWrapper from 'containers/copy-wrapper/copyWrapper';
import CryptoLogos from 'containers/crypto-logos';

import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  PossibleTableItemsWithStatusesType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';

import s from './Blockchain.module.scss';

const Blockchain = <R,>(
  blockchain: ValueRowType,
  otherProps?: RendererProps<GetTableDataArgsType, R, PossibleTableItemsType>,
): ReactElement => {
  if (!blockchain) {
    return <></>;
  }

  let isViewed = false;
  if ('status' in (otherProps?.elementData || {})) {
    isViewed = (otherProps?.elementData as PossibleTableItemsWithStatusesType)?.status === 1;
  }

  return (
    <CopyWrapper valueToCopy={blockchain as string}>
      <div
        className={cx({
          [s.wrapper]: true,
          [s.viewed]: isViewed,
        })}>
        <CryptoLogos type={blockchain?.toString()} />
        <span>{blockchain.toString().toLowerCase()}</span>
      </div>
    </CopyWrapper>
  );
};

export default Blockchain;
