import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash.isequal';
import reduce from 'lodash.reduce';
import { URLSearchParamsInit, createSearchParams, useNavigate } from 'react-router-dom';

import { filtersSelector } from 'selectors/filterSlice.selectors';
import { selectedTransactionsSelector } from 'selectors/selectedTransactionsSlice.selectors';
import { userDataSelector } from 'selectors/userSlice.selectors';
import { useLazyGetTabFiltersQuery } from 'services/overview-service/overviewService';
import { useLazyV2GetTabFiltersQuery } from 'services/v2-overview-service/overviewService';
import { useAppSelector } from 'store';

import Table from 'components/table';
import TableErrorComponent from 'containers/table-with-handlers/table-components/table-error-component';
import TableNoDataComponent from 'containers/table-with-handlers/table-components/table-no-data-component';
import TableNoDataWithFiltersComponent from 'containers/table-with-handlers/table-components/table-no-data-with-filters-component';

import { DEFAULT_OFFSET, DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { DEFAULT_FILTER_VALUE } from 'constants/query';
import { WATCH_BLOCK_VERSION_1, WATCH_BLOCK_VERSION_LOCAL_KEY } from 'constants/version';
import { Children } from 'types/childrenTypes';
import { FilterResponseType } from 'types/filtersTypes';
import { ParamsType } from 'types/paramsTypes';
import { QueryTypes } from 'types/queryTypes';
import { SortType } from 'types/sortingTypes';
import { TabTypesEnum } from 'types/tabTypes';
import {
  GetTableDataArgsType,
  PossibleTableColumnType,
  PossibleTableItemsType,
  TableGetDataResponseType,
} from 'types/tableTypes';

import { getFiltersFromParamsObject, isFilterKey } from 'utils/filter';
import { queryBuilder, transformSort } from 'utils/table';
import useLocalStorage from 'utils/useLocalStorage.hook';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import s from './TableWithHandlers.module.scss';
import TableActions from './table-actions';

interface TableWithHandlersProps<C> {
  className?: string;
  isLoading?: boolean;
  isError?: boolean;
  totalCount?: number | string;
  items?: PossibleTableItemsType[];
  getData?: (args: GetTableDataArgsType) => TableGetDataResponseType;
  columns: C[];
  tab?: TabTypesEnum;
  colorizeRow?: boolean;
}

const INITIAL_RENDER = Array.from(Array(10), (_, id) => ({ id }));

const TableWithHandlers = <C extends PossibleTableColumnType>(props: TableWithHandlersProps<C>) => {
  const {
    colorizeRow = true,
    className,
    isLoading,
    isError,
    totalCount,
    items,
    getData,
    columns,
    tab,
  } = props;

  const navigate = useNavigate();
  const { paramsObject, setQueryParams } = useQueryParamsHook();
  const [localTotalCount, setTotalCount] = useState<number>(0);
  const [previousItems, setPreviousItems] = useState(items || []);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [currentVersion] = useLocalStorage(WATCH_BLOCK_VERSION_LOCAL_KEY, WATCH_BLOCK_VERSION_1);

  const userData = JSON.stringify(useAppSelector(userDataSelector));
  const selectedTransactions = useAppSelector(selectedTransactionsSelector);

  const stringifiedParamsObject = JSON.stringify(paramsObject) || '{}';

  const CURRENT_OFFSET = Number(paramsObject?.offset);
  const CURRENT_LIMIT = Number(paramsObject?.limit);

  const sortingArray = useMemo(() => {
    return transformSort(paramsObject?.order);
  }, [paramsObject?.order]);

  const mutation =
    currentVersion === WATCH_BLOCK_VERSION_1
      ? useLazyGetTabFiltersQuery
      : useLazyV2GetTabFiltersQuery;

  const [requestFilters, { data: filtersData }] = mutation();

  const filters = useAppSelector(filtersSelector);

  const rerunRequest = useCallback(() => {
    const query = queryBuilder(JSON.parse(stringifiedParamsObject));
    if (query.pagination && getData && filters) {
      getData(query);
    }
  }, [filters, getData, stringifiedParamsObject]);

  const queryWithoutFilters = useMemo(
    () =>
      reduce(
        JSON.parse(stringifiedParamsObject),
        (prevVal: { [key: string]: ParamsType }, curVal, key: string) => {
          const isNotFilter = !isFilterKey(key);
          const isDefaultFilterValue = curVal === DEFAULT_FILTER_VALUE;

          if (isNotFilter || isDefaultFilterValue) {
            prevVal[key] = curVal;
          }
          return prevVal;
        },
        {},
      ),
    [stringifiedParamsObject],
  );

  const hasFilters = useMemo(
    () => !isEqual(paramsObject, queryWithoutFilters),
    [paramsObject, queryWithoutFilters],
  );

  const resetFilters = useCallback(() => {
    setQueryParams(queryWithoutFilters, true);
  }, [queryWithoutFilters, setQueryParams]);

  useEffect(() => {
    if (tab) {
      requestFilters({ tab });
    }
  }, [mutation, requestFilters, tab]);

  useEffect(() => {
    if (filters && paramsObject?.tab) {
      const copiedParamsObject = JSON.parse(stringifiedParamsObject);

      const filtersEnumeration = ([, filter]: [unknown, FilterResponseType]) => {
        const findSelectedFilter = (objectKey: string) => objectKey.includes(`${filter.key}[`);

        const selectedFilterGroupOption = Object.keys(copiedParamsObject).find(findSelectedFilter);

        const defaultFilter = filter.components?.find(
          (filterComponent: { default_selected?: boolean }) => filterComponent.default_selected,
        );
        if (!selectedFilterGroupOption && defaultFilter) {
          copiedParamsObject[`${filter.key}[${defaultFilter.key}]`] = DEFAULT_FILTER_VALUE;
        }
      };

      Object.entries(filters).forEach(filtersEnumeration);

      setQueryParams(copiedParamsObject);
    }
  }, [paramsObject?.tab, stringifiedParamsObject, filtersData, setQueryParams, filters]);

  useEffect(() => {
    if (isInitialLoad && items?.length) {
      setIsInitialLoad(false);
    }
    return () => {
      !previousItems.length && setIsInitialLoad(true);
    };
  }, [isInitialLoad, items?.length, previousItems.length]);

  useEffect(() => {
    if (items) {
      setPreviousItems(items);
    }
  }, [items]);

  useEffect(() => {
    const INITIAL_PAGINATION: QueryTypes = {
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_PAGE_SIZE,
    };

    const isPaginationDifferentFromInitial =
      CURRENT_OFFSET !== INITIAL_PAGINATION.offset || CURRENT_LIMIT !== INITIAL_PAGINATION.limit;

    if (paramsObject && isPaginationDifferentFromInitial) {
      if (CURRENT_OFFSET) {
        INITIAL_PAGINATION.offset = CURRENT_OFFSET;
      }
      if (CURRENT_LIMIT) {
        INITIAL_PAGINATION.limit = CURRENT_LIMIT;
      }

      setQueryParams(INITIAL_PAGINATION);
    }
  }, [paramsObject, CURRENT_LIMIT, CURRENT_OFFSET, setQueryParams, stringifiedParamsObject]);

  useEffect(() => {
    // we should rerun requests on user data changes to get actual values in currency and date fields
    if (userData) {
      rerunRequest();
    }
  }, [rerunRequest, userData]);

  useEffect(() => {
    if (items && totalCount) {
      setTotalCount(Number(totalCount) || 0);
    }
  }, [items, totalCount]);

  const navigateWithParams = useCallback(
    (pathname: string): void => {
      const currentParamsFilters = getFiltersFromParamsObject(paramsObject);
      navigate({
        pathname,
        search: `?${createSearchParams(currentParamsFilters as URLSearchParamsInit)}`,
      });
    },
    [navigate, paramsObject],
  );

  const handlePaginationChange = useCallback(
    (page: number) => {
      setQueryParams({
        offset: ((page - 1) * CURRENT_LIMIT).toString(),
        limit: CURRENT_LIMIT,
      });
    },
    [CURRENT_LIMIT, setQueryParams],
  );

  const handlePageSizeChange = useCallback(
    (limit: string | number) => {
      setQueryParams({
        limit: limit.toString(),
        offset: '0',
      });
    },
    [setQueryParams],
  );

  const renderItem = useCallback(
    (elementData: PossibleTableItemsType, companyIndex: number) => {
      const columnRenderer = (column: C, columnIndex: number) => {
        const { key, renderer, ...otherColumnProps } = column;
        let value;

        if (renderer) {
          const initValue = key === 'number' ? 0 : elementData[key as keyof PossibleTableItemsType];

          value = renderer(initValue, {
            ...otherColumnProps,
            // TODO: not today
            // @ts-ignore
            elementData,
            index: companyIndex,
            offset: CURRENT_OFFSET,
            // TODO: not today
            // @ts-ignore
            rerunRequest: getData,
            navigateWithParams,
          });
        } else {
          value = elementData[key as keyof PossibleTableItemsType];
        }

        return <td key={`${columnIndex}*${key}`}>{value as Children}</td>;
      };

      let className = '';

      if (selectedTransactions.includes(elementData.id)) {
        className = s.checkedRow;
      } else if ('status' in elementData && colorizeRow) {
        className = s[`status-${elementData.status}`];
      }

      return (
        <tr key={`${companyIndex}-${elementData.id}`} className={className}>
          {columns.map(columnRenderer)}
        </tr>
      );
    },
    [selectedTransactions, colorizeRow, columns, CURRENT_OFFSET, getData, navigateWithParams],
  );

  const handleSortChange = useCallback(
    (type: SortType, key: string) => {
      if (!key) {
        return;
      }

      const newQuery: QueryTypes = {
        ...paramsObject,
        offset: '0',
      };

      if (sortingArray.length) {
        const newSortIndex: number = sortingArray.findIndex((order) => order[key]);
        const filterSortArray = (_: unknown, i: number) => i !== newSortIndex;

        if (newSortIndex === -1 && type) {
          newQuery.order = [...sortingArray, { [key]: type }];
        } else {
          let updatedSortingArray = [...sortingArray];

          if (!type) {
            updatedSortingArray = updatedSortingArray.filter(filterSortArray);
          } else {
            updatedSortingArray[newSortIndex][key] = type;
          }

          if (updatedSortingArray.length) {
            newQuery.order = updatedSortingArray;
          } else delete newQuery.order;
        }
      } else {
        newQuery.order = [{ [key]: type as 'asc' | 'desc' }];
      }

      setQueryParams(newQuery);
    },
    [paramsObject, setQueryParams, sortingArray],
  );

  const errorComponent = (
    <TableErrorComponent
      columnsLength={columns.length}
      resetFilters={resetFilters}
      rerunRequest={rerunRequest}
    />
  );
  const noDataComponent = hasFilters ? (
    <TableNoDataWithFiltersComponent columnsLength={columns.length} resetFilters={resetFilters} />
  ) : (
    <TableNoDataComponent columnsLength={columns.length} rerunRequest={rerunRequest} />
  );

  let itemsToRender = items;

  if (isLoading) {
    if (isInitialLoad) {
      itemsToRender = INITIAL_RENDER;
    } else {
      itemsToRender = previousItems;
    }
  }

  return (
    <>
      <TableActions resetFilters={resetFilters} />
      <Table
        noDataComponent={noDataComponent}
        errorComponent={errorComponent}
        isError={!!isError}
        isLoading={!!isLoading}
        activeSort={sortingArray}
        className={className}
        handleSortChange={handleSortChange}
        handlePaginationChange={handlePaginationChange}
        handlePageSizeChange={handlePageSizeChange}
        totalCount={localTotalCount}
        offset={CURRENT_OFFSET}
        limit={CURRENT_LIMIT}
        columns={columns}
        items={itemsToRender}
        renderItem={renderItem}
        showPagination
      />
    </>
  );
};

export default memo(TableWithHandlers);
