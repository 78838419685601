import {
  BaseQueryApi,
  BaseQueryExtraOptions,
  BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import { clearUser } from 'slices/userSlice';

import { WATCHBLOCK_REFRESH_TOKEN, WATCHBLOCK_TOKEN } from 'constants/token';

export const mutex = new Mutex();

const INVALID_TOKEN = 401;
const EXPIRED_TOKEN = 426;
const AUTH_ERROR_CODES = [INVALID_TOKEN, EXPIRED_TOKEN];

export const baseQueryWithReauth =
  (config: FetchBaseQueryArgs) =>
  async (
    args: FetchArgs | string,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
  ) => {
    await mutex.waitForUnlock();
    let result = await fetchBaseQuery(config)(args, api, extraOptions);
    const isTokenExpiredOrUnauthorised =
      result.error && AUTH_ERROR_CODES.includes(result.error.status as number);

    if (isTokenExpiredOrUnauthorised) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        const refreshToken = localStorage.getItem(WATCHBLOCK_REFRESH_TOKEN);
        const isTokenExpiredAndRefreshTokenExists =
          result.error!.status === EXPIRED_TOKEN && refreshToken;
        const clearUserData = () => {
          localStorage.removeItem(WATCHBLOCK_REFRESH_TOKEN);
          localStorage.removeItem(WATCHBLOCK_TOKEN);
          window.dispatchEvent(new Event('local-storage'));
          api.dispatch(clearUser());
        };

        try {
          if (isTokenExpiredAndRefreshTokenExists) {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}user/refresh`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${JSON.parse(refreshToken)}`,
              },
            });
            const tokens = await response.json();

            if (tokens.access_token) {
              localStorage.setItem(WATCHBLOCK_TOKEN, JSON.stringify(tokens.access_token));
              localStorage.setItem(WATCHBLOCK_REFRESH_TOKEN, JSON.stringify(tokens.refresh_token));

              result = await fetchBaseQuery(config)(args, api, extraOptions);
            } else {
              clearUserData();
            }
          } else {
            clearUserData();
          }
        } catch {
          clearUserData();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await fetchBaseQuery(config)(args, api, extraOptions);
      }
    }

    return result;
  };

const prepareHeaders = (headers: Headers) => {
  const token = localStorage.getItem(WATCHBLOCK_TOKEN);

  headers.set('Access-Control-Allow-Origin', '*');

  if (token) {
    headers.set('Authorization', `Bearer ${JSON.parse(token)}`);
  }

  return headers;
};

export const BASE_QUERY_WITH_AUTH = baseQueryWithReauth({
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders,
});
