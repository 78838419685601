import React from 'react';

import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from 'store';

import CryptoLogos from 'containers/crypto-logos';

import App from './app';
import './styles/style.scss';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);
dayjs.locale('en');

if (root) {
  root.render(
    <Provider store={store}>
      <ConfigProvider locale={enUS}>
        <Router>
          <CryptoLogos />
          <App />
        </Router>
      </ConfigProvider>
    </Provider>,
  );
}
