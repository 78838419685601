import React, { forwardRef, memo } from 'react';

import { filtersIsLoadingSelector } from 'selectors/filterSlice.selectors';
import { useAppSelector } from 'store';

import WithSkeletonLoader from 'components/with-skeleton-loader';

import { Children } from 'types/childrenTypes';

import s from './TableFilterTags.module.scss';
import TableFilterTagsSkeleton from './tableFilterTags.skeleton';

interface TableFilterTagsProps {
  children: Children;
  isLoading: boolean;
}

const TableFilterTags = forwardRef<HTMLDivElement, TableFilterTagsProps>((props, ref) => {
  const { children, isLoading } = props;

  const filtersIsLoading = useAppSelector(filtersIsLoadingSelector);

  return (
    <div className={s.wrapper} ref={ref}>
      <WithSkeletonLoader
        isLoading={filtersIsLoading || isLoading}
        skeletonLoader={<TableFilterTagsSkeleton />}>
        {children}
      </WithSkeletonLoader>
    </div>
  );
});

export default memo(TableFilterTags);
