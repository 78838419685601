import React, { FC, memo } from 'react';

import TableNotification from 'containers/table-with-handlers/table-notification';

interface TableNoDataComponentProps {
  columnsLength: number;
  rerunRequest: () => void;
}

const TableNoDataComponent: FC<TableNoDataComponentProps> = (props) => {
  const { columnsLength } = props;

  return (
    <TableNotification colSpan={columnsLength}>
      <TableNotification.Header>No results</TableNotification.Header>
      <TableNotification.Content>No data available</TableNotification.Content>
    </TableNotification>
  );
};

export default memo(TableNoDataComponent);
