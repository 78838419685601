import React, { ReactElement } from 'react';

import cx from 'classnames';

import CopyWrapper from 'containers/copy-wrapper';

import { getCompanyWalletWithIdRoute } from 'constants/routes';
import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';
import { TransactionAddressType } from 'types/transactionTypes';

import { transformHash } from '../utils';
import s from './AddressFrom.module.scss';

const AddressFrom = <R,>(
  value: ValueRowType,
  otherProps: RendererProps<GetTableDataArgsType, R, PossibleTableItemsType>,
): ReactElement => {
  if (!value) {
    return <></>;
  }

  const typedValue: TransactionAddressType = value as unknown as TransactionAddressType;

  const handleClick = () => {
    if (typedValue.details_available) {
      otherProps.navigateWithParams(
        getCompanyWalletWithIdRoute(typedValue.company_id || '', typedValue.id),
      );
    }
  };

  return (
    <CopyWrapper
      valueToCopy={typedValue.address}
      className={cx({ [s.wrapper]: true, [s.link]: typedValue.details_available })}
      handleClick={handleClick}>
      {transformHash(typedValue.address)}
    </CopyWrapper>
  );
};

export default AddressFrom;
