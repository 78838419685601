import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper/copyWrapper';

import { ValueRowType } from 'types/tableTypes';

import { convertDateToLocale, convertTimeToLocale } from 'utils/convertToLocale';

import s from './Date.module.scss';

const Date = (date: ValueRowType): ReactElement => {
  if (!date) {
    return <></>;
  }

  return (
    <CopyWrapper valueToCopy={date as string}>
      <div className={s.wrapper}>
        <span>{`${convertDateToLocale(date as string, { month: 'long', day: '2-digit' })},`}</span>
        <span>{convertTimeToLocale(date as string, { hour: '2-digit', minute: '2-digit' })} </span>
      </div>
    </CopyWrapper>
  );
};

export default Date;
