import React, { ReactElement } from 'react';

import cx from 'classnames';

import CopyWrapper from 'containers/copy-wrapper/copyWrapper';

import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';

import s from './ConvertedFrom.module.scss';

const ConvertedFrom = <R,>(
  amountInFiat: ValueRowType,
  otherProps: RendererProps<GetTableDataArgsType, R, PossibleTableItemsType>,
): ReactElement => {
  const userCurrencySymbol = otherProps?.userCurrency?.symbol || '';

  const typedValue: number =
    amountInFiat && typeof amountInFiat === 'object'
      ? Number(amountInFiat.amount)
      : Number(amountInFiat);
  const isHighlighted = !!(
    amountInFiat &&
    typeof amountInFiat === 'object' &&
    amountInFiat.highlighted
  );

  if (isNaN(typedValue)) {
    return <></>;
  }

  return (
    <CopyWrapper valueToCopy={typedValue}>
      <div className={cx({ [s.wrapper]: true, [s.highlighted]: isHighlighted })}>
        {`${userCurrencySymbol}${typedValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`}
      </div>
    </CopyWrapper>
  );
};

export default ConvertedFrom;
