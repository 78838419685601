import React from 'react';

import Skeleton from 'components/skeleton';

const INITIAL_SKELETON_COUNT = Array.from(Array(3), (_, id) => ({ id }));

const skeletonRenderer = (_: unknown, index: number) => (
  <Skeleton style={{ height: 32, width: 120, borderRadius: 6 }} key={`__${index}__`} />
);

const TableFilterTagsSkeleton = () => {
  return <>{INITIAL_SKELETON_COUNT.map(skeletonRenderer)}</>;
};

export default TableFilterTagsSkeleton;
