import { WATCH_BLOCK_THEME_LOCAL_KEY } from 'constants/theme';
import { SetStateTypes } from 'types/setStateTypes';
import { ThemeTypes } from 'types/theme';

import useLocalStorage from './useLocalStorage.hook';

const useTheme = (): [string | undefined, SetStateTypes<string>] => {
  const initialTheme = localStorage.getItem(WATCH_BLOCK_THEME_LOCAL_KEY) || ThemeTypes.LIGHT_THEME;

  const [currentTheme, setCurrentTheme] = useLocalStorage(
    WATCH_BLOCK_THEME_LOCAL_KEY,
    initialTheme,
  );

  return [currentTheme, setCurrentTheme];
};

export default useTheme;
