import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper';
import { transformHash } from 'containers/row-renderers/utils';

import { getCompanyWalletWithIdRoute } from 'constants/routes';
import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';

import s from './WalletAddress.module.scss';

const WalletAddress = <R,>(
  value: ValueRowType,
  otherProps: RendererProps<GetTableDataArgsType, R, PossibleTableItemsType>,
): ReactElement => {
  const companyId = otherProps.companyId;

  if (!value || !companyId) {
    return <></>;
  }

  const handleClick = () => {
    otherProps.navigateWithParams(
      getCompanyWalletWithIdRoute(companyId, otherProps.elementData.id),
    );
  };

  return (
    <CopyWrapper valueToCopy={value as string} handleClick={handleClick} className={s.wrapper}>
      {transformHash(value.toString())}
    </CopyWrapper>
  );
};

export default WalletAddress;
