import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper';

import { getCompanyWithIdRoute } from 'constants/routes';
import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';

import s from './Company.module.scss';

const Company = <R,>(
  companyName: ValueRowType,
  otherProps: RendererProps<GetTableDataArgsType, R, PossibleTableItemsType>,
): ReactElement => {
  if (!companyName || !otherProps?.elementData) {
    return <></>;
  }

  let redirectId: string | number;
  if ('company_id' in otherProps.elementData) {
    redirectId = otherProps.elementData.company_id!;
  } else {
    redirectId = otherProps.elementData.id;
  }

  const handleClick = () => {
    otherProps.navigateWithParams(getCompanyWithIdRoute(redirectId));
  };

  return (
    <CopyWrapper
      valueToCopy={companyName as string}
      className={s.wrapper}
      handleClick={handleClick}>
      {companyName as string}
    </CopyWrapper>
  );
};

export default Company;
