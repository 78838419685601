import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { userTimezoneOffsetSelector } from 'selectors/userSlice.selectors';
import { useAppSelector } from 'store';

import timezones from 'constants/utcTimezones.json';

import { convertTimeToLocale } from 'utils/convertToLocale';

const CurrentTime = () => {
  const [date, setDate] = useState<Date>(new Date());
  const currentUserTimezoneOffset = useAppSelector(userTimezoneOffsetSelector);

  const findCurrentTimezone = useCallback(
    (timezone: { offset: number }) => timezone.offset === currentUserTimezoneOffset,
    [currentUserTimezoneOffset],
  );
  const timeZone = useMemo(
    () => timezones.find(findCurrentTimezone)?.utc[0],
    [findCurrentTimezone],
  );

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {convertTimeToLocale(date, {
        hour: '2-digit',
        minute: '2-digit',
        timeZone,
      })}
    </>
  );
};

export default CurrentTime;
