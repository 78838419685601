import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import cn from 'classnames';

import Dropdown from 'components/dropdown';
import Typography from 'components/typography';
import CopyWrapper from 'containers/copy-wrapper';
import { Blockchain, Date, RowNumber } from 'containers/row-renderers';
import { transformHash } from 'containers/row-renderers/utils';

import { getInvoiceWithIdRoute } from 'constants/routes';
import {
  CompanyColumnType,
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';
import { SCORING_STATUS_ENUM, TransactionAddressType } from 'types/transactionTypes';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-icon.svg';
import { ReactComponent as CheckIconInverted } from 'assets/icons/check-outlined-icon.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross-icon.svg';
import { ReactComponent as Lightbulb } from 'assets/icons/lightbulb-icon.svg';

import useQueryParamsHook from 'utils/useQueryParams.hook';

import { MODAL_TYPE } from '../scoring-request-modal/scoringRequestModal';
import s from './ScoringRequestTableView.module.scss';

const STATUSES_ICONS: Record<string, ReactElement> = {
  0: <Lightbulb className={s.lightbulbIcon} />,
  1: <CrossIcon className={s.crossIcon} />,
  2: <CheckIconInverted className={s.checkIcon} />,
};

const DROPDOWN_ITEMS = [
  {
    label: (
      <Typography className={cn(s.option, s.approvedOption)}>
        <CheckIconInverted className={s.checkIcon} /> Approve
      </Typography>
    ),
    key: MODAL_TYPE.APPROVE_KEY,
  },
  {
    label: (
      <Typography className={cn(s.option, s.declinedOption)}>
        <CrossIcon className={s.crossIcon} /> Decline
      </Typography>
    ),
    key: MODAL_TYPE.DECLINE_KEY,
  },
];

interface UseTableColumnsProps {
  openCloseModalHandler: (key: string, id: string) => void;
}

const useTableColumns = (props: UseTableColumnsProps): CompanyColumnType[] => {
  const { openCloseModalHandler } = props;

  const { paramsObject } = useQueryParamsHook();

  const [isOverlayOpened, setIsOverlayOpened] = useState<Record<string, boolean>>({});
  const handleOpenOverlay = (id: string) => {
    setIsOverlayOpened((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleItemClick = useCallback(
    (key: string, id: string) => {
      openCloseModalHandler(key, id);
      handleOpenOverlay(id);
    },
    [openCloseModalHandler],
  );

  const withdrawalRequest = useCallback(
    (
      status: ValueRowType,
      record: RendererProps<GetTableDataArgsType, unknown, PossibleTableItemsType>,
    ) => {
      const typedStatus: SCORING_STATUS_ENUM = status as unknown as SCORING_STATUS_ENUM;
      const id = record?.elementData?.id as string;
      const isOpened = isOverlayOpened[id];

      if (typeof typedStatus !== 'number') {
        return <></>;
      }

      if (typedStatus === SCORING_STATUS_ENUM.UNREVIEWED) {
        return (
          <Dropdown
            align={{ offset: [0, 4] }}
            open={isOpened}
            onOpenChange={() => handleOpenOverlay(id)}
            handleItemClick={(event: { key: string }) => handleItemClick(event.key, id)}
            trigger={['click']}
            items={DROPDOWN_ITEMS}>
            <button className={cn(s.dropdownButton, { [s.active]: isOpened })}>
              <Typography>New request</Typography> <ArrowDown />
            </button>
          </Dropdown>
        );
      }

      const label = typedStatus === SCORING_STATUS_ENUM.APPROVED ? 'Approved' : 'Declined';

      return (
        <div
          className={cn(
            s.inactiveStatus,
            typedStatus === SCORING_STATUS_ENUM.APPROVED ? s.approvedStatus : s.declinedStatus,
          )}>
          {label}
        </div>
      );
    },
    [handleItemClick, isOverlayOpened],
  );

  const invoiceAddressRenderer = useCallback(
    (
      value: ValueRowType,
      otherProps: RendererProps<GetTableDataArgsType, unknown, PossibleTableItemsType>,
    ) => {
      const typedValue: TransactionAddressType | undefined = value as unknown as
        | TransactionAddressType
        | undefined;
      const handleInvoiceClick = () => {
        if (typedValue?.details_available) {
          otherProps.navigateWithParams(getInvoiceWithIdRoute(typedValue?.id));
        }
      };

      return (
        <CopyWrapper
          valueToCopy={typedValue?.address || ''}
          className={cn({
            [s.link]: !!typedValue?.details_available,
          })}
          handleClick={handleInvoiceClick}>
          {transformHash(typedValue?.address)}
        </CopyWrapper>
      );
    },
    [],
  );

  return useMemo(
    () => [
      { title: '#', key: 'number', renderer: RowNumber, className: s.number },
      {
        title: 'Status',
        className: s.statusHeader,
        key: 'status',
        renderer: (status: ValueRowType) => {
          const typedStatus = status as unknown as SCORING_STATUS_ENUM;

          return <div className={s.status}>{STATUSES_ICONS[typedStatus]}</div>;
        },
      },
      {
        title: 'Requests ID',
        key: 'id',
        renderer: (value: ValueRowType) => {
          return <>{value}</>;
        },
      },
      {
        title: 'Request date',
        key: 'created_date',
        renderer: Date,
        sortable: true,
      },
      {
        title: 'Action date',
        key: 'decision_date',
        renderer: Date,
        sortable: true,
      },
      {
        title: 'Manager',
        key: 'manager',
        // renderer: Date,
        sortable: true,
      },
      {
        title: 'Invoice address',
        key: 'address',
        renderer: invoiceAddressRenderer,
        sortable: true,
        paramsObject,
      },
      {
        title: 'Blockchain',
        key: 'blockchain',
        renderer: (blockchain: ValueRowType) => Blockchain(blockchain),
        sortable: true,
      },
      {
        title: 'Fiat value',
        key: 'amount',
        renderer: (amountInFiat: ValueRowType) => {
          return (
            <>
              {amountInFiat?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </>
          );
        },
        sortable: true,
      },
      {
        title: 'Risk score',
        key: 'risk_score',
        renderer: (amountInFiat: ValueRowType) => {
          if (typeof amountInFiat === 'number') {
            let style = 'red';
            if (amountInFiat <= 20) {
              style = 'green';
            } else if (amountInFiat > 20 && 60 > amountInFiat) {
              style = 'yellow';
            }

            return <span className={s[style]}>{amountInFiat}</span>;
          }

          return null;
        },
        sortable: true,
      },
      {
        title: 'Action',
        key: 'status',
        renderer: withdrawalRequest,
      },
    ],
    [invoiceAddressRenderer, paramsObject, withdrawalRequest],
  );
};

export default useTableColumns;
