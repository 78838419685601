import React, { ReactElement } from 'react';

import CopyWrapper from 'containers/copy-wrapper';

import {
  GetTableDataArgsType,
  PossibleTableItemsType,
  RendererProps,
  ValueRowType,
} from 'types/tableTypes';
import { TransactionType } from 'types/transactionTypes';

import { transformHash } from '../utils';
import s from './Hash.module.scss';

const Hash = <R,>(
  value: ValueRowType,
  otherProps: RendererProps<GetTableDataArgsType, R, PossibleTableItemsType>,
): ReactElement => {
  if (!value) {
    return <></>;
  }

  return (
    <CopyWrapper valueToCopy={value as string}>
      <a
        target="_blank"
        href={(otherProps.elementData as TransactionType).scan_ref}
        className={s.wrapper}>
        {transformHash(value)}
      </a>
    </CopyWrapper>
  );
};

export default Hash;
