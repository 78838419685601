import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import { filtersSelector, tabFiltersSelector } from 'selectors/filterSlice.selectors';
import { useAppSelector } from 'store';

import Typography from 'components/typography';
import FilterComponentConstructor from 'containers/filter-component-constructor';

import { FilterComponent, FilterResponseType } from 'types/filtersTypes';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up-icon.svg';

import s from './Filters.module.scss';
import FiltersWrapper from './filters-wrapper';

const INITIAL_FILTERS_STATUS_VALUE = {};

const Filters = () => {
  const filters = useAppSelector(filtersSelector);
  const tabFilters = useAppSelector(tabFiltersSelector);

  const filtersToRender: FilterResponseType[] = useMemo(
    () =>
      (tabFilters || []).reduce((prevVal: FilterResponseType[], curVal: string) => {
        if (filters && curVal in filters) {
          prevVal.push(filters[curVal]);
        }

        return prevVal;
      }, []),
    [filters, tabFilters],
  );

  const [openedFilters, setOpenedFilters] = useState<{ [key: string]: boolean }>(
    INITIAL_FILTERS_STATUS_VALUE,
  );

  useEffect(() => {
    if (!filters) {
      setOpenedFilters(INITIAL_FILTERS_STATUS_VALUE);
    }
  }, [filters]);

  const handleOpenClose = useCallback((newValue: boolean, key: string) => {
    setOpenedFilters((prevState) => ({ ...prevState, [key]: newValue }));
  }, []);

  const filtersRenderer = useCallback(
    (filter: FilterResponseType) => {
      const isOpened = Object.hasOwn(openedFilters, filter.key)
        ? openedFilters[filter.key]
        : !filter.collapsed;

      const renderFiltersContent = (component: FilterComponent) => {
        return (
          <FilterComponentConstructor
            filterType={filter.type}
            key={component.key}
            mainKey={component.key}
            component={component}
            filterKey={filter.key}
          />
        );
      };

      return (
        <div key={filter.key} className={s.wrapper}>
          <button className={s.filterHeader} onClick={() => handleOpenClose(!isOpened, filter.key)}>
            <Typography size="13" weight="600">
              {filter.label}
            </Typography>
            <ArrowUp className={cx({ [s.rotate]: !isOpened })} />
          </button>
          <FiltersWrapper filterType={filter.type} filterKey={filter.key} isOpened={isOpened}>
            {filter?.components.map(renderFiltersContent)}
          </FiltersWrapper>
        </div>
      );
    },
    [handleOpenClose, openedFilters],
  );

  if (Array.isArray(filtersToRender)) {
    return <>{filtersToRender.map(filtersRenderer)}</>;
  }

  return <></>;
};

export default memo(Filters);
